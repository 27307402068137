import React, { useEffect, useState,useGlobal } from "reactn";
import { Row, Col, Form, Input, Button, Card, message, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import moment from 'moment';
import { RightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';


import api from '../../service/index'
import { Link } from 'react-router-dom';


const PublicHolidays = (props) => {
    let history = useHistory()
    let id = "publicHolidays"

    let [data, setData] = useState({});
    let [errors, ] = useState([]);
    let [loading, setLoading] = useState(true);
    let [languagesCms] = useGlobal("languagesCms"); 
    //onetime run
    useEffect(() => {
        get();
    }, []);

    let get = async () => {
        if (id) {
            await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
                setData(result);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let deleteRow = async (index) => {
        if (index > -1) {
            data.items.splice(index, 1);
        }

        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
                message.success(languagesCms.SUCCESS_DELETE_RECORD, 2);
                history.push('/settings/publicHolidays')
            }
            else
                message.error(languagesCms.ERROR_DELETE_RECORD, 2);
        })
    }

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Public Holidays</h1>
                </div>
                <div className="list-buttons">
                    <Link to={"/settings/publicHolidays/" + id + "/add"}>
                        <Button type="light" icon={<PlusOutlined />} style={{ marginRight: "5px" }} size="large">{languagesCms.ADD_NEW}</Button>
                    </Link>
                    <Link to="/settings">
<Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
                        <Form layout="horizontal" size={"large"}>


                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="id" help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                                        <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Card title={"İtems"}>
                                <div className="table-wrap" style={{ marginTop: 10 }} >
                                    <ul className="dragDrop">
                                        {data.items.map((item, index) => {
                                            return (
                                                <li>
                                                    <div >
                                                        <RightOutlined style={{ fontSize: 20 }} />
                                                        <span className="nameDrag">{moment(item.date).format('DD-MM-YYYY') + "  -  " + item.title}</span>
                                                    </div>
                                                    <div className="rightButtons">
                                                        <Link to={"/settings/publicHolidays/" + id + "/edit/" + index}><Button type="primary" size="medium" > {languagesCms.EDIT}</Button></Link>
                                                        <Popconfirm
                                                            onConfirm={() => deleteRow(index)} title={languagesCms.CHECK_DELETE}
                                                            okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                                                            <Button type="danger" icon={<DeleteOutlined />}>{languagesCms.DELETE}</Button>
                                                        </Popconfirm>
                                                    </div>


                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>

                            </Card>
                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};



export default PublicHolidays;
