import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import Parse from 'parse';

export default (props) => {
    let { record, setRecord, name, parseServerURL, parseAppId } = props;
    let [users, setUsers] = useState([]);
	let languagesCms = props.languagesCms;
    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL

    let User = Parse.Object.extend("User");


    useEffect(() => {
        let query = new Parse.Query(User);
        query.limit(1000000)
        query.find().then(function (results) {
            setUsers(results)
        }).catch(function (error) {
            setUsers([])
        });
    }, [])

    return <Select value={record[name]} placeholder={languagesCms.SELECT_ATTENDEE}
        onChange={v => setRecord({ ...record, [name]: v })}>
        {(users) && users.map(b =>
            <Select.Option key={b.id} value={b.id} >{b.get("lastName")  ? b.get("name") + " " + b.get("lastName")  : b.get("name")}</Select.Option>)}
    </Select>
};