import React, { useEffect, useState, useGlobal } from "reactn";
import moment from 'moment';
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Button, Card, message, Typography, Image, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback } from "react";

const SurveyAnswersDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let surveyId = params.surveyId !== "add" ? params.surveyId : false;
  let newRecord = {}
  let [data, setData] = useState(id ? {} : newRecord);
  let [, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

  const [, setIsLoading] = useState(true);

  const handleMessage = (event) => {
    if (event.data.action === 'receipt-loaded') {
      setIsLoading(false);
    }
  };

  /*const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };*/

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
      api.get("/rest/surveyAnswers/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        api.put("/rest/survey/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/survey')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/survey", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/survey')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  React.useEffect(() => {
  }, [data.questions])

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/survey/answers/" + surveyId}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? "" : ""} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 6 }} >
                  <Image width={200} src={data.attendee_id.avatar.url}> </Image>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 18 }}>
                  <Col xs={{ span: 24 }}>
                    <Typography.Title style={{ padding: 0, margin: 0 }}
                      level={2}>{[data.attendee_id.name, data.attendee_id.lastname].filter(x => x).join(' ')}</Typography.Title>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Email : {data.attendee_id.email}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Telefon : {data.attendee_id.phone}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>TC : {data.attendee_id.tc}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Doğum Tarihi : {data.attendee_id.birthdayString}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Firma : {data.attendee_id.company}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Departman : {data.attendee_id.department}</Typography.Text>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Typography.Text>Pozisyon : {data.attendee_id.position}</Typography.Text>
                  </Col>
                </Col>
              </Row>
              <hr />

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Typography.Title style={{ textAlign: "center" }} level={2} >{languagesCms.ANSWERS}</Typography.Title>
                </Col>
              </Row>
              <hr />

              {data.questions.map((item, index) => {
                if(item.type === 'F'){
                  return (
                    <Row direction="row">
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Typography.Title level={4} >{index + 1}. {item.name}</Typography.Title>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Typography.Text level={2} >{item.other}</Typography.Text>
                      </Col>
                    </Row>
                  )
                }
                else{
                  return (
                    <Row key={index} direction="row">
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Typography.Title level={4} >{index + 1}. {item.name}</Typography.Title>
                      </Col>
                      {item.choices.map((itemC, indexC) => {
                     if (itemC.is_selected === true) {
                          return (
                            <Row key={indexC}>
                              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Checkbox defaultChecked={true} disabled>{itemC.name} </Checkbox>
                              </Col>
                            </Row>
                          )                         
                          }
                        else {
                          return (
                            <Col key={indexC} xs={{ span: 24 }} md={{ span: 24 }}>
                              <Checkbox defaultChecked={false} disabled>{itemC.name}</Checkbox>
                            </Col>
                          )
                        }
                      })}
                    </Row>
                  )                  
                }

                }

              )}
              <hr />
              <Row direction="row">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 20 }}>
                  <Col xs={{ span: 24 }} >
                    <Typography.Title style={{ textAlign: "center" }} level={5} >{languagesCms.FIRST_ANSWER_DATE} : {moment(data.created).format("DD-MM-YYYY HH:mm")}</Typography.Title>
                  </Col>
                </Col>
              </Row>
              <hr />
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default SurveyAnswersDetail;
