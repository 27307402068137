import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm, message} from 'antd';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {ImportExcel} from './../components';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const Attendee = (props) => {
    const {Search} = Input;
    let [data, setData] = useState([]);
    let [excelData, setExcelData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [activeUserCount, setActiveUserCount] = useState(0)
    let [loginUserCount, setLoginUserCount] = useState(0)
    let [adminCount, setAdminCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false);
    let [isSmall, ] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let [module] = modules.filter(el => el._id === path);

    let query = []
    query["deleted"] = false;
    query["allow_donation"] = false;
    query["blood_donation"] = false;
    query["platelet_donation"] = false;

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let profileSettings;
    let customization;
    let settings = useGlobal("settings")[0]

    if (settings) {
        settings.forEach(element => {
            if (element._id === "profileSettings") {
                profileSettings = element
            }
            if (element._id === "customization") {
                customization = element
            }
        });
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'name';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name, email, phone";
        }

        let restData = await api.get(`/rest/attendees?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    if (!item.hasOwnProperty('groups')) {
                        item.groups = []
                    }
                    if (item.isLogin)
                        item.isLogin = <div className="true-circle"></div>;
                    else
                        item.isLogin = <div className="false-circle"></div>;

                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    let activeCount = () => {
        api.get(`/api/attendeesActiveCount?isLogin=true`, {}).then(({data: {result, result_message}}) => {
            setActiveUserCount(result.activeUserCount)
            setLoginUserCount(result.loginUserCount)
            setAdminCount(result.adminCount)
        });

    }
    useEffect(() => {
        if (modules) {
            setLoading(true)
            activeCount()
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        api.delete(`/rest/attendees/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
        await activeCount()
    }

    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/attendees/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    /*let getReport = async () => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/loggedInUsers&lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `Kullanici_Giriş_Raporu.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }*/

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    function turkishSort(a, b) {
        var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
        if (a.length === 0 || b.length === 0) {
            return a.length - b.length;
        }
        for (var i = 0; i < a.length && i < b.length; i++) {
            var ai = alfabe.indexOf(a[i]);
            var bi = alfabe.indexOf(b[i]);
            if (ai !== bi) {
                return ai - bi;
            }
        }
    }

    let columns = [
        {
            title: languagesCms.EMAIL,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email - b.email,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.PHONE,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone - b.phone,
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.NAME,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => turkishSort(a.name, b.name),
            sortDirections: ['descend', 'ascend']
        }, {
      title: languagesCms.LASTNAME,
            dataIndex: 'lastname',
            key: 'lastname',
            sorter: (a, b) => turkishSort(a.lastname, b.lastname),
            sortDirections: ['descend', 'ascend']
        },
        {
      title: languagesCms.GROUP,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (groups) => groups.length > 0 && groups.map((e, i) => <div key={i} style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title: languagesCms.LOGGED_IN,
            width: "50px",
            dataIndex: 'isLogin',
            key: 'isLogin',
            sorter: (a, b) => a.isLogin - b.isLogin,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/attendees/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {!isSmall && languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {!isSmall && languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    /*let totalOnline = async () => {

        let restData = await api.get(`/rest/attendees?${qs.stringify({
            page: 1,
            pageSize: 10000,
            query
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

    }*/

    let downloadExcel = async () => {
        var option = {};
        let dataTable = [];
        let query = []
        if (customization.isSmarteventView) {
            query["deleted"] = false;
        }

        let excelData = await api.get(`/rest/attendees?${qs.stringify({
            page: 1,
            pageSize: 10000,
            query
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                if (excelData) {
                    let group1 = "";
                    let group2 = "";
                    let group3 = "";
                    let group4 = "";
                    let group5 = "";
                    let group6 = "";
                    let group7 = "";
                    let group8 = "";
                    let group9 = "";
                    let group10 = "";
                    let group11 = "";
                    let group12 = "";
                    let group13 = "";
                    let group14 = "";
                    let group15 = "";
                    let group16 = "";
                    let group17 = "";
                    let group18 = "";
                    let group19 = "";
                    let group20 = "";
                    if (excelData[i].groups) {
                        if (excelData[i].groups[0]) {
                            group1 = excelData[i].groups[0].name;
                        }
                        if (excelData[i].groups[1]) {
                            group2 = excelData[i].groups[1].name;
                        }
                        if (excelData[i].groups[2]) {
                            group3 = excelData[i].groups[2].name;
                        }
                        if (excelData[i].groups[3]) {
                            group4 = excelData[i].groups[3].name;
                        }
                        if (excelData[i].groups[4]) {
                            group5 = excelData[i].groups[4].name;
                        }
                        if (excelData[i].groups[5]) {
                            group6 = excelData[i].groups[5].name;
                        }
                        if (excelData[i].groups[6]) {
                            group7 = excelData[i].groups[6].name;
                        }
                        if (excelData[i].groups[7]) {
                            group8 = excelData[i].groups[7].name;
                        }
                        if (excelData[i].groups[8]) {
                            group9 = excelData[i].groups[8].name;
                        }
                        if (excelData[i].groups[9]) {
                            group10 = excelData[i].groups[9].name;
                        }
                        if (excelData[i].groups[10]) {
                            group11 = excelData[i].groups[10].name;
                        }
                        if (excelData[i].groups[11]) {
                            group12 = excelData[i].groups[11].name;
                        }
                        if (excelData[i].groups[12]) {
                            group13 = excelData[i].groups[12].name;
                        }
                        if (excelData[i].groups[13]) {
                            group14 = excelData[i].groups[13].name;
                        }
                        if (excelData[i].groups[14]) {
                            group15 = excelData[i].groups[14].name;
                        }
                        if (excelData[i].groups[15]) {
                            group16 = excelData[i].groups[15].name;
                        }
                        if (excelData[i].groups[16]) {
                            group17 = excelData[i].groups[16].name;
                        }
                        if (excelData[i].groups[17]) {
                            group18 = excelData[i].groups[17].name;
                        }
                        if (excelData[i].groups[18]) {
                            group19 = excelData[i].groups[18].name;
                        }
                        if (excelData[i].groups[19]) {
                            group20 = excelData[i].groups[19].name;
                        }

                    }

                    let obj = {
                        'id': excelData[i]._id,
                        'role': excelData[i].role,
                        'name': excelData[i].name,
                        'lastname': excelData[i].lastname,
                        'phone': excelData[i].phone,
                        'email': excelData[i].email,
                        'city': excelData[i].location,
                        'birthday': excelData[i].birthday,
                        'position': excelData[i].position,
                        'deleted': excelData[i].deleted ? 'Silindi' : 'Aktif',
                        'isLogin': excelData[i].isLogin ? 'Giriş Yaptı' : 'Giriş Yapmadı',
                        'isLoginDate': excelData[i].isLoginTime ? moment(excelData[i].isLoginTime).format("DD-MM-YYYY") : '',
                        'isLoginTime': excelData[i].isLoginTime ? moment(excelData[i].isLoginTime).format("HH:mm") : '',
                        'group_1': group1,
                        'group_2': group2,
                        'group_3': group3,
                        'group_4': group4,
                        'group_5': group5,
                        'group_6': group6,
                        'group_7': group7,
                        'group_8': group8,
                        'group_9': group9,
                        'group_10': group10,
                        'group_11': group11,
                        'group_12': group12,
                        'group_13': group13,
                        'group_14': group14,
                        'group_15': group15,
                        'group_16': group16,
                        'group_17': group17,
                        'group_18': group18,
                        'group_19': group19,
                        'group_20': group20,
                    }

                    //Ek alanlar
                    if (profileSettings.showCompanyDev) {
                        obj.company = excelData[i].company;
                    }
                    if (profileSettings.showPositionDev) {
                        obj.position = excelData[i].position;
                    }
                    if (profileSettings.showDepartmentDev) {
                        obj.department = excelData[i].department;
                    }
                    if (profileSettings.showRegisterNoDev) {
                        obj.registerNo = excelData[i].registerNo
                    }
                    if (profileSettings.showJobStartDateDev) {
                        obj.jobStartDate = excelData[i].jobStartDate ? moment(excelData[i].jobStartDate).format("DD-MM-YYYY") : ''
                    }
                    if (profileSettings.showTcDev) {
                        obj.tc = excelData[i].tc;
                    }
                    if (profileSettings.showBirthdayStringDev) {
                        obj.birthdayString = excelData[i].birthdayString;
                    }

                    dataTable.push(obj);
                }
            }
        }

        let sheetAreas = ['id', 'role', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'deleted', 'isLogin', 'isLoginDate',
            'isLoginTime',];

        //Ek alanlar
        if (profileSettings.showCompanyDev) {
            sheetAreas.push("company");
        }
        if (profileSettings.showPositionDev) {
            sheetAreas.push("position");
        }
        if (profileSettings.showDepartmentDev) {
            sheetAreas.push("department");
        }
        if (profileSettings.showRegisterNoDev) {
            sheetAreas.push("registerNo");
        }
        if (profileSettings.showJobStartDateDev) {
            sheetAreas.push("jobStartDate");
        }
        if (profileSettings.showTcDev) {
            sheetAreas.push("tc");
        }
        if (profileSettings.showBirthdayStringDev) {
            sheetAreas.push("birthdayString");
        }
        sheetAreas.push('group_1', 'group_2', 'group_3', 'group_4', 'group_5', 'group_6', 'group_6', 'group_7', 'group_8', 'group_9',
            'group_10', 'group_11', 'group_12', 'group_13', 'group_14', 'group_15', 'group_16', 'group_17', 'group_18', 'group_19', 'group_20')

        //smartevent için
        // if(customization.isSmarteventView){
        //   sheetAreas = ['name', 'lastname', 'phone', 'email','isLogin','isLoginDate',
        //   'isLoginTime'];
        // }


        option.fileName = 'UserList'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: sheetAreas,
                sheetHeader: sheetAreas,
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let parseDateExcel = (excelTimestamp) => {
        const secondsInDay = 24 * 60 * 60;
        const excelEpoch = new Date(1899, 11, 31);
        const excelEpochAsUnixTimestamp = excelEpoch.getTime();
        const missingLeapYearDay = secondsInDay * 1000;
        const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
        const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
        const parsed = excelTimestampAsUnixTimestamp + delta;
        return isNaN(parsed) ? null : parsed;
    };

    let uploadExcel = async () => {

        let attendee_id;
        let userData;
        let userGroups = [];

        let updateCount = 0;
        let insertCount = 0;

        if (excelData) {

            for (let i in excelData) {
                attendee_id = excelData[i].id;
                userGroups = [];
                if (excelData[i].group_1) {
                    userGroups.push(excelData[i].group_1.toString().trim())
                }
                if (excelData[i].group_2) {
                    userGroups.push(excelData[i].group_2.toString().trim())
                }
                if (excelData[i].group_3) {
                    userGroups.push(excelData[i].group_3.toString().trim())
                }
                if (excelData[i].group_4) {
                    userGroups.push(excelData[i].group_4.toString().trim())
                }
                if (excelData[i].group_5) {
                    userGroups.push(excelData[i].group_5.toString().trim())
                }
                if (excelData[i].group_6) {
                    userGroups.push(excelData[i].group_6.toString().trim())
                }
                if (excelData[i].group_7) {
                    userGroups.push(excelData[i].group_7.toString().trim())
                }
                if (excelData[i].group_8) {
                    userGroups.push(excelData[i].group_8.toString().trim())
                }
                if (excelData[i].group_9) {
                    userGroups.push(excelData[i].group_9.toString().trim())
                }
                if (excelData[i].group_10) {
                    userGroups.push(excelData[i].group_10.toString().trim())
                }
                if (excelData[i].group_11) {
                    userGroups.push(excelData[i].group_11.toString().trim())
                }
                if (excelData[i].group_12) {
                    userGroups.push(excelData[i].group_12.toString().trim())
                }
                if (excelData[i].group_13) {
                    userGroups.push(excelData[i].group_13.toString().trim())
                }
                if (excelData[i].group_14) {
                    userGroups.push(excelData[i].group_14.toString().trim())
                }
                if (excelData[i].group_15) {
                    userGroups.push(excelData[i].group_15.toString().trim())
                }
                if (excelData[i].group_16) {
                    userGroups.push(excelData[i].group_16.toString().trim())
                }
                if (excelData[i].group_17) {
                    userGroups.push(excelData[i].group_17.toString().trim())
                }
                if (excelData[i].group_18) {
                    userGroups.push(excelData[i].group_18.toString().trim())
                }
                if (excelData[i].group_19) {
                    userGroups.push(excelData[i].group_19.toString().trim())
                }
                if (excelData[i].group_20) {
                    userGroups.push(excelData[i].group_20.toString().trim())
                }
                if (typeof excelData[i].birthday === 'number') {
                    excelData[i].birthday = moment(parseDateExcel(excelData[i].birthday)).add(12, 'hours').format()
                }

                userData = {
                    "name": excelData[i].name,
                    "lastname": excelData[i].lastname || '',
                    "email": excelData[i].email || '',
                    "phone": excelData[i].phone || '',
                    "location": excelData[i].city || '',
                    "birthday": excelData[i].birthday || '',
                    "groups": userGroups,
                }

                //Ek alanlar
                if (profileSettings.showCompanyDev) {
                    if (excelData[i].company) {
                        userData.company = excelData[i].company.toString().trim() || '';
                    } else {
                        userData.company = '';
                    }
                }

                if (profileSettings.showPositionDev) {
                    if (excelData[i].position) {
                        userData.position = excelData[i].position.toString().trim() || '';
                    } else {
                        userData.position = '';
                    }
                }

                if (profileSettings.showDepartmentDev) {
                    if (excelData[i].department) {
                        userData.department = excelData[i].department.toString().trim() || '';
                    } else {
                        userData.department = '';
                    }
                }

                if (profileSettings.showRegisterNoDev) {
                    if (excelData[i].registerNo) {
                        userData.registerNo = excelData[i].registerNo.toString().trim() || '';
                    } else {
                        userData.registerNo = '';
                    }
                }

                if (profileSettings.showJobStartDateDev) {
                    if (excelData[i].jobStartDate) {
                        userData.jobStartDate = excelData[i].jobStartDate.toString().trim() || '';
                    } else {
                        userData.jobStartDate = '';
                    }
                }

                if (profileSettings.showTcDev) {
                    if (excelData[i].tc) {
                        userData.tc = excelData[i].tc.toString().trim() || '';
                    } else {
                        userData.tc = '';
                    }
                }

                if (profileSettings.showBirthdayStringDev) {
                    if (excelData[i].birthdayString) {
                        userData.birthdayString = excelData[i].birthdayString.toString().trim() || '';
                    } else {
                        userData.birthdayString = '';
                    }
                }

                if (attendee_id) {
                    // update
                    await api.put("/rest/attendees/" + attendee_id, userData).then(({
                                                                                        data: {
                                                                                            result,
                                                                                            result_message
                                                                                        }
                                                                                    }) => {
                    })
                    updateCount++;
                } else {
                    // insert
                    userData["role"] = "user";
                    userData["password"] = "passVeli";

                    await api.post("/rest/attendees", userData).then(({data: {result, result_message}}) => {
                    })
                    insertCount++;
                }


            }
            if (updateCount || insertCount) {
                message.error("Excel Yüklendi. " + updateCount + " Güncellendi, " + insertCount + " Kayıt Eklendi.", 2);
            }

        }


    }

    useEffect(() => {
        uploadExcel();
    }, [excelData]);

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1 style={{display: 'inline-block', marginRight: "2rem"}}>{module ? module.name : ""} </h1>
                    {module &&
                        <p style={{display: 'inline-block'}}><font face="tahoma" size="1"> {module.name === "Kullanıcılar" ? "  ("+languagesCms.TOTAL_USERS+ + activeUserCount + " , "+languagesCms.ACTIVE_USERS+" : " + loginUserCount + " , "+languagesCms.ADMINS+" : " + adminCount + ")" : ""}</font>
                        </p>
                    }
                </div>
                <div className="list-buttons">
                    {/* <Button type="primary" icon={<FileMarkdownOutlined />} size="large" style={{ marginRight: "5px" }} onClick={() => getReport()}>Giriş Logları İndir</Button> */}
                    {!isSmall &&
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    }
                    <Button size={'large'} onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{!isSmall && 'Export Excel'}</Button>
                    <Link to="/attendees/add" style={{marginRight: "5px"}}>
                        <Button type="light" icon={<PlusOutlined/>}
                                size={'large'}>{!isSmall && languagesCms.ADD_NEW}</Button>
                    </Link>
                    {!isSmall &&
                        <ImportExcel setExcelData={setExcelData}/>
                    }
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default Attendee;
