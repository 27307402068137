import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message, InputNumber, Switch ,Alert} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import {  FileInputSingle } from '../components';
import { GroupPicker, LangPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const PodcastsDetail = (props) => {

    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 


    let newRecord = {
        lang: langCode ? langCode :  langCms[0].code ,
        title: '',
        order: 0,
        active: true,
        private: true,
        groups: []
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
 
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if(modules !== null){
       [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
                api.get("/rest/podcasts/" + id).then(({ data: { result, result_message } }) => {
                    setData(result);
                    setLoading(false)
                });
        }
    }, [id]);


    let validate = useCallback(() => {
        let errors = {};

        if (data.lang == null)
        errors.lang = languagesCms.ENFORCED

        if (data.media == null)
        errors.media = languagesCms.ENFORCED

        if (data.title === null || data.title.length === 0)
            errors.title = languagesCms.ENFORCED


        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            if (id) {
                api.put("/rest/podcasts/" + id, data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/podcasts')

                    }
                    else
                        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
            } else {
                api.post("/rest/podcasts", data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/podcasts')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/podcasts">
<Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>    
                            <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.LANG}>
                                <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.GROUP}>
                                <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.TITLE} required help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                                        <Input name="title" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.ORDER} required help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                                        <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                 <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.STATUS} required help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                        <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.PRIVATE} required help={errors.private} validateStatus={errors.private ? 'error' : 'success'}>
                                        <Switch checked={data.private ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, private: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col span={24}>
                                <Form.Item label={languagesCms.MEDIA} help={errors.media} validateStatus={errors.media ? 'error' : 'success'}>
                                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="media" />
                                    <Alert  message={languagesCms.MEDIA_THUMB_MESSAGE} banner />
                                    <Alert  message={languagesCms.UPLOAD_IMAGE_MESSAGE} banner />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
            </div>
        </div>
    );
};



export default PodcastsDetail;
