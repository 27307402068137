import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, InputNumber, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput,  } from '../components';
import { Link } from 'react-router-dom';
import { useCallback } from "react";
import Parse from 'parse';

/*import  { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'

langCode ? langCode :  langCms[0].code */

const PlaceDetail = (props) => {
    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;

    let [customization] = useGlobal("customization");
    let [languagesCms] = useGlobal("languagesCms");

    let parseServerURL = customization.parseServerURL
    let parseAppId = customization.parseAppId

    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL
    let Place = Parse.Object.extend("Place");

    let newRecord = {
        name: null,
        description: "",
        active: true,
        order: 0,
        workingHours: "",
        diningHall: false,
        service: false,
        timeCategory: "reservationSettings",
        pdf: null
    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)

    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            let query = new Parse.Query(Place);
            query.get(id).then((place) => {
                setData(place.attributes);
                setLoading(false)
            }, (error) => {
                message.error("Kayıt bilgilerine ulaşılamadı.", 2);
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null)
            errors.name = languagesCms.ENFORCED

        if (data.coordinate === null || data.coordinate === undefined)
            errors.coordinate = languagesCms.ENFORCED

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            let iosFormat = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"><style>*{font-family: '-apple-system','HelveticaNeue'; font-size:14px;}</style>"
            //data.fieldOfficeMessage ? data.fieldOfficeMessage = iosFormat + data.fieldOfficeMessage : data.fieldOfficeMessage=data.fieldOfficeMessage
            if(data.fieldOfficeMessage){
                data.fieldOfficeMessage = iosFormat + data.fieldOfficeMessage
            }
            if (id) {
                let query = new Parse.Query(Place);
                query.equalTo("objectId", id);
                await query.first().then((object) => {
                    object.save(data).then(() => {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/place')
                    }, (error) => {
                        message.error("Kayıt düzünlenemedi", 2);
                    });

                }, (error) => {
                    message.error("Kayıt bilgilerine ulaşılamadı.", 2);
                });
            } else {
                let Place = Parse.Object.extend("Place")
                let place = new Place()
                place.save(data).then((place) => {
                    message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                    history.push('/place')
                }, (error) => {
                    message.error(error, 2);
                });
            }
        }

    };


    /*let imageHandler = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {

            const quill = quillRef.current.getEditor();
            const file = input.files[0];
            const formData = new FormData();

            formData.append('files_0', file);

            // Save current cursor state
            const range = quill.getSelection(true);

            // // Insert temporary loading placeholder image
            quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loading.gif`);
            // Move cursor to right side of image (easier to continue typing)
            quill.setSelection(range.index + 1);

            let url = await api.post("/api/upload", formData, { headers: { 'ContenType': 'multipart/form-data' } }).then(({
                data: {
                    result,
                    result_message
                }
            }) => {
                if (result_message.type === 'success') {
                    return result[0].url;
                }
            });
            quill.deleteText(range.index, 1);
            quill.insertEmbed(range.index, 'image', url);
        };
    }*/

    //const formats = ['header','font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link','image', 'color', 'size', 'video', 'align', 'background', 'direction', 'code-block', 'code','script','clean']
    /*const quillmodules = useMemo(() => ({
        toolbar: {
            container: [
                [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ['link', 'image', 'video'],
                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { align: [] },
                    { direction: 'rtl' },
                  ],
                ['clean'],
                ['code-block'],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{ 'color': [] }, { 'background': [] }],
                [{ script: 'sub' }, { script: 'super' }, 'formula']
            ],
            handlers: {
                image: imageHandler
            }
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    }), [])*/

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""} - Bina İşlemleri</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/place">
                        <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                    <Form layout="horizontal" size={"large"} onFinish={save}>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label={languagesCms.NAME} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Aktif" help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label={languagesCms.ORDER}>
                                    <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item label={languagesCms.EXPLANATION} help={errors.description} validateStatus={errors.description ? 'error' : 'success'}>
                                    <Input.TextArea name="description" value={data.description} onChange={v => setData({ ...data, description: v.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                <Form.Item label="Çalışma Saatleri" help={errors.workingHours} validateStatus={errors.workingHours ? 'error' : 'success'}>
                                    <Input.TextArea name="workingHours" value={data.workingHours} onChange={e => setData({ ...data, workingHours: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Yemek" help={errors.diningHall} validateStatus={errors.diningHall ? 'error' : 'success'}>
                                    <Switch checked={data.diningHall ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, diningHall: v })} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item label="Servis" help={errors.service} validateStatus={errors.service ? 'error' : 'success'}>
                                    <Switch checked={data.service ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, service: v })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row direction="row">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>                                <Form.Item label="Rezervasyon Gün Ayarları" help={errors.timeCategory} validateStatus={errors.timeCategory ? 'error' : 'success'}>
                                <Select defaultValue={(data.timeCategory) ? data.timeCategory : 'Ayar seçiniz'} style={{ width: 300 }} onChange={v => { setData({ ...data, timeCategory: v }) }}>
                                    <Select.Option value="reservationSettings">Genel</Select.Option>
                                    <Select.Option value="reservationSettingsBranch">Şube</Select.Option>
                                </Select>
                            </Form.Item>
                            </Col>
                        </Row>

                       
                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item required label={languagesCms.LOCATION} help={errors.coordinate} validateStatus={errors.coordinate ? 'error' : 'success'}>
                                    <MapInput languagesCms={languagesCms} record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" required={false} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row direction="row">
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default PlaceDetail;
