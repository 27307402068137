import React, { useEffect, useState, useGlobal } from "reactn";
import moment from 'moment';
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message, Select, InputNumber, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { FileInputSingle, GroupPicker, LangPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback,  } from "react";

const Info6SubDetail = (props) => {
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms");
    let [langCms] = useGlobal("langCms");
    let [langCode] = useGlobal("langCode");
    let path = props.location.pathname.split('/')[1];
    let [module] = modules.filter(el => el._id === path);

    let params = useParams()
    let history = useHistory()
    let parent_id = params.id !== "add" ? params.id : false;
    let id = params.subid !== "add" ? params.subid : false;

    let newRecord = {
        module: module ? module._id : "",
        parent: parent_id,
        lang: langCode ? langCode :  langCms[0].code ,
        groups: [],
        order: 0,
        header: '',
        type: 'Multiple',
        medias: [],
        date: new Date()
    }

    let [data, setData] = useState(id ? [] : newRecord);
    let [parent, setParent] = useState({});
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);
    let [validationCheck, setValidationCheck] = useState(false)

    //onetime run
    useEffect(() => {
        get();
        parentInfo();
    }, []);

    let parentInfo = async () => {
        if (parent_id) {
            api.get("/rest/informations/" + parent_id).then(({ data: { result, result_message } }) => {
                setParent(result);
            });
        }

    }

    let get = async () => {
        parentInfo();
        if (id) {
            await api.get("/rest/informations/" + id).then(({ data: { result, result_message } }) => {
                setData(result);
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let validate = useCallback(() => {
        let errors = {};

        if (data.lang === null)
            errors.lang = languagesCms.ENFORCED

        if (data.order === null)
            errors.order = languagesCms.ENFORCED

        if (data.header === null || data.header.length === 0)
            errors.header = languagesCms.ENFORCED

        if (data.type === null)
            errors.type = languagesCms.ENFORCED

        if (data.type === 'PDF' && (data.pdf === null || !data.pdf))
            errors.pdf = languagesCms.ENFORCED

        if (data.type === 'PDF' && (data.no === null || !data.no))
            errors.no = languagesCms.ENFORCED

        if (data.type === 'PDF' && (data.topic === null || !data.topic))
            errors.topic = languagesCms.ENFORCED

        if (data.type === 'PDF' && (data.directorate === null || !data.directorate))
            errors.directorate = languagesCms.ENFORCED

        if (data.type === 'PDF' && data.directorate === "other" && (data.otherDirectorate === null || !data.otherDirectorate))
            errors.otherDirectorate = languagesCms.ENFORCED

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            if (id) {
                api.put("/rest/informations/" + id, data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/' + module._id + "/detail/" + parent_id)
                    }
                    else
                        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
            } else {
                api.post("/rest/informations", data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/' + module._id + "/detail/" + parent_id)
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                    <h5>{parent ? parent.header : ""}</h5>
                </div>
                <div className="list-buttons">
                    <Link to={"/" + (module ? module._id : "") + "/detail/" + parent_id}>
                        <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.LANG}>
                                        <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.GROUP} help={errors.groups} validateStatus={errors.active ? 'error' : 'success'}>
                                        <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.TYPE} required help={errors.type} validateStatus={errors.active ? 'error' : 'success'}>
                                        <Select defaultValue={(data.type) ? data.type : languagesCms.SELECT_TYPE} style={{ width: 250 }} onChange={v => { setData({ ...data, type: v }) }}>
                                            <Select.Option value="Multiple">Multiple</Select.Option>
                                            <Select.Option value="PDF">PDF</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                        <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.ORDER} required validateStatus={errors.name ? 'error' : 'success'}>
                                        <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label={languagesCms.TITLE} required help={errors.header} validateStatus={errors.header ? 'error' : 'success'}>
                                        <Input name="header" value={data.header} onChange={e => setData({ ...data, header: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                {data.type === "PDF" &&
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="No" required help={errors.no} validateStatus={errors.no ? 'error' : 'success'}>
                                            <Input name="no" value={data.no} onChange={e => setData({ ...data, no: e.target.value })} />
                                        </Form.Item>
                                    </Col>
                                }
                                {data.type === "PDF" &&
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="Tarih:" required help={errors.date} validateStatus={errors.date ? 'error' : 'success'}>
                                            <DatePicker defaultValue={() => moment(data.date)} onChange={v => setData({ ...data, date: v })} format='DD/MM/YYYY' showTime={true} />
                                            <br /><small>Varsayılan değer bulunduğunuz gündür. </small>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row direction="row">
                                {data.type === "PDF" &&
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="Müdürlük" required help={errors.directorate} validateStatus={errors.directorate ? 'error' : 'success'}>
                                            <Select defaultValue={(data.directorate) ? data.directorate : ''} placeholder="Müdürlük seçin" style={{ width: 450 }} onChange={v => { setData({ ...data, directorate: v }) }}>
                                                <Select.Option value="Acenteler Müdürlüğü">Acenteler Müdürlüğü</Select.Option>
                                                <Select.Option value="Aktüerya ve Reasürans Müdürlüğü">Aktüerya ve Reasürans Müdürlüğü</Select.Option>
                                                <Select.Option value="Bilgi İşlem Müdürlüğü">Bilgi İşlem Müdürlüğü</Select.Option>
                                                <Select.Option value="Bireysel Satış Müdürlüğü">Bireysel Satış Müdürlüğü</Select.Option>
                                                <Select.Option value="Bölge Satış ve Şube Müdürlükleri">Bölge Satış ve Şube Müdürlükleri</Select.Option>
                                                <Select.Option value="Değişim ve Proje Yönetimi Müdürlüğü">Değişim ve Proje Yönetimi Müdürlüğü</Select.Option>
                                                <Select.Option value="Destek Hizmetleri ve Satın Alma Müdürlüğü">Destek Hizmetleri ve Satın Alma Müdürlüğü</Select.Option>
                                                <Select.Option value="Fon Yönetimi Müdürlüğü">Fon Yönetimi Müdürlüğü</Select.Option>
                                                <Select.Option value="Hukuk Müşavirliği">Hukuk Müşavirliği</Select.Option>
                                                <Select.Option value="İletişim Müşavirliği">İletişim Müşavirliği</Select.Option>
                                                <Select.Option value="İnsan Kaynakları ve Eğitim Müdürlüğü">İnsan Kaynakları ve Eğitim Müdürlüğü</Select.Option>
                                                <Select.Option value="Kurumsal Satış Müdürlüğü">Kurumsal Satış Müdürlüğü</Select.Option>
                                                <Select.Option value="Muhasebe Müdürlüğü">Muhasebe Müdürlüğü</Select.Option>
                                                <Select.Option value="Müşteri İlişikilleri ve Dijital Kanallar Yönetimi Müdürlüğü">Müşteri İlişikilleri ve Dijital Kanallar Yönetimi Müdürlüğü</Select.Option>
                                                <Select.Option value="Müşteri Operasyonları Müdürlüğü">Müşteri Operasyonları Müdürlüğü</Select.Option>
                                                <Select.Option value="Risk Yönetimi ve İç Kontrol Müdürlüğü">Risk Yönetimi ve İç Kontrol Müdürlüğü</Select.Option>
                                                <Select.Option value="Sağlık Müşavirliği">Sağlık Müşavirliği</Select.Option>
                                                <Select.Option value="Strateji ve Kurumsal Performans Yönetimi Müdürlüğü">Strateji ve Kurumsal Performans Yönetimi Müdürlüğü</Select.Option>
                                                <Select.Option value="Ürün Yönetimi Müdürlüğü">Ürün Yönetimi Müdürlüğü</Select.Option>
                                                <Select.Option value="Yasal ve Yönetsel Raporlama Müdürlüğü">Yasal ve Yönetsel Raporlama Müdürlüğü</Select.Option>
                                                <Select.Option value="Yazılım Geliştirme Müdürlüğü">Yazılım Geliştirme Müdürlüğü</Select.Option>
                                                <Select.Option value="other">Diğer</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {data.directorate === "other" &&
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="Müdürlük" required help={errors.otherDirectorate} validateStatus={errors.otherDirectorate ? 'error' : 'success'}>
                                            <Input name="Müdürlük" value={data.otherDirectorate} onChange={e => setData({ ...data, otherDirectorate: e.target.value })} />
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row direction="row">
                                {data.type === "PDF" &&
                                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                        <Form.Item label="Konu" required help={errors.topic} validateStatus={errors.topic ? 'error' : 'success'}>
                                            <Select defaultValue={(data.topic) ? data.topic : ''} style={{ width: 450 }} onChange={v => { setData({ ...data, topic: v }) }}>
                                                <Select.Option value="Aracılar">Aracılar</Select.Option>
                                                <Select.Option value="Adres / Telefon Değişikliği">Adres / Telefon Değişikliği</Select.Option>
                                                <Select.Option value="Atama / Yükselme / Görev Bölümü /Görev Yeri Değişikliği">Atama / Yükselme / Görev Bölümü /Görev Yeri Değişikliği</Select.Option>
                                                <Select.Option value="Destek Portalı">Destek Portalı</Select.Option>
                                                <Select.Option value="e-BEAS">e-BEAS</Select.Option>
                                                <Select.Option value="Emeklilik Yatırım Fonları">Emeklilik Yatırım Fonları</Select.Option>
                                                <Select.Option value="Gelir Sigortaları">Gelir Sigortaları</Select.Option>
                                                <Select.Option value="Yönetim Mesajı/Uyarı">Yönetim Mesajı/Uyarı</Select.Option>
                                                <Select.Option value="Kampanya">Kampanya</Select.Option>
                                                <Select.Option value="Katkı Payı Güncellemesi">Katkı Payı Güncellemesi</Select.Option>
                                                <Select.Option value="Komisyon">Komisyon</Select.Option>
                                                <Select.Option value="Prim/Projeksiyon Tabloları">Prim/Projeksiyon Tabloları</Select.Option>
                                                <Select.Option value="Sağlık Sigortası">Sağlık Sigortası</Select.Option>
                                                <Select.Option value="Şirketlerarası Aktarım">Şirketlerarası Aktarım</Select.Option>
                                                <Select.Option value="Uygulamalar - Vergi">Uygulamalar - Vergi</Select.Option>
                                                <Select.Option value="Uygulamalar - BES">Uygulamalar - BES</Select.Option>
                                                <Select.Option value="Uygulamalar - Genel">Uygulamalar - Genel</Select.Option>
                                                <Select.Option value="Uygulamalar - Hayat Sigortası">Uygulamalar - Hayat Sigortası</Select.Option>
                                                <Select.Option value="Uygulamalar - İnsan Kaynakları">Uygulamalar - İnsan Kaynakları</Select.Option>
                                                <Select.Option value="Uygulamalar - MASAK">Uygulamalar - MASAK</Select.Option>
                                                <Select.Option value="Uygulama Talimatı - BES">Uygulama Talimatı - BES</Select.Option>
                                                <Select.Option value="Uygulama Talimatı - Hayat">Uygulama Talimatı - Hayat</Select.Option>
                                                <Select.Option value="Organizasyon Değişikliği">Organizasyon Değişikliği</Select.Option>
                                                <Select.Option value="Yönetmelik / Kurallar / Esaslar">Yönetmelik / Kurallar / Esaslar</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row direction="row">
                                {data.type === "PDF" &&
                                    <Col span={24}>
                                        <Form.Item label="PDF" required={data.type === 'PDF' ? true : false} help={errors.pdf} validateStatus={errors.pdf ? 'error' : 'success'}>
                                            <FileInputSingle languagesCms={languagesCms} name='pdf' fileType='pdf' title='Medyalar' type='file' multi={false} record={data} setRecord={setData} />
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};

export default Info6SubDetail;
