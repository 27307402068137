import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name, moduleChange="1",setModuleChange } = props;
	let [modules, setModules] = useState([]);
	let langCode = props.langCode;
	let langCms = props.langCms;
	let _params = { sort: "order", page: 1, pageSize: 100, lang: langCode ? langCode : langCms[0].code, appShow: "true" };
	let languagesCms = props.languagesCms;
	useMemo(() => {
		api.get(`/rest/modules?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
			let dbModules = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
			setModules(dbModules);
			}
		})
	}, [])

	return <Select value={record[name] || []} placeholder={languagesCms.SELECT_MODULE}
		onChange={v => { if(moduleChange!=="1")setModuleChange(true); setRecord({ ...record, [name]: v }) }}>
		<Select.Option key="not_modul" value="not_modul" >{languagesCms.NOT_MODULE}</Select.Option>
		<Select.Option key="notifications" value="notifications" >{languagesCms.NOTIFICATION}</Select.Option>
		{(modules) && modules.map(b =>
			<Select.Option key={b.refId} value={b.refId} >{b.name}</Select.Option>)}
	</Select>;
};