import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import Parse from 'parse';

export default (props) => {
    let { record, setRecord, name, planId, parseServerURL, parseAppId } = props;
    let [coordinates, setCoordinates] = useState([]);
    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL

    let PlanCoordinates = Parse.Object.extend("PlanCoordinates");

    useEffect(() => {
        let query = new Parse.Query(PlanCoordinates);
        query.limit(10000000)
        query.containedIn("planId", [planId]);
        query.equalTo("isAvailable", true);
        query.find().then(function (results) {
            setCoordinates(results)
        }).catch(function (error) {
            setCoordinates([])
        });
    }, [])

    return <Select value={record[name]} placeholder="Masa seçin"
        onChange={v => setRecord({ ...record, [name]: v })}>
        {(coordinates) && coordinates.map(b =>
            <Select.Option key={b.id} value={b.id} >{b.get("name")}</Select.Option>)}
    </Select>
};