import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom'

import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';

import { EditOutlined, DeleteOutlined, PlusOutlined,LeftOutlined, Loading3QuartersOutlined } from "@ant-design/icons";

const SessionOrderItem = (props) => {
    let params = useParams()
    let query = []
    let parentId = params.parentId;
    let subId = params.id;
    let id = params.id !== "add" ? params.id : false;
    query["subCategoryId"]=id;
    const { Search } = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = { page: page.current, pageSize: page.pageSize }
        } else {
            page = { page: 1, pageSize: 10 };
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = { sort: shortString, ...page, query };

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let restData = await api.get(`/rest/sessionOrderItem?${qs.stringify(_params)}`, { _params }).then(({ data }) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);


    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/sessionOrderItem/${item_id}`, ({ data }) => { });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/sessionOrderItem/${item._id}`, ({ data }) => { });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },

        {
            title: 'Başlangıç Saati',
            dataIndex: 'startTime',
            key: 'startTime',
            sorter: (a, b) => a.startTime - b.startTime,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Bitiş Saati',
            dataIndex: 'endTime',
            key: 'endTime',
            sorter: (a, b) => a.endTime - b.endTime,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.ORDER,
            dataIndex: 'order',
            key: 'order',
            sorter: (a, b) => a.order - b.order,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'right',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/sessionOrder/" + parentId + "/edit/" +id+"/" +record._id}>
                        <Button icon={<EditOutlined />}>{languagesCms.EDIT}</Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined />}>{languagesCms.DELETE}</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Button type="danger" icon={<DeleteOutlined />} size="large" onClick={deleteSelectedRows} style={{ marginRight: "5px" }}>{languagesCms.DELETE_SELECTED}</Button>
                    <Link to={"/sessionOrder/" + parentId + "/add/"+subId}>
                        <Button type="light" icon={<PlusOutlined />} size="large" style={{ marginRight: "5px" }}>{languagesCms.ADD_NEW}</Button>
                    </Link>
                    <Link to={"/sessionOrder/detail/" + parentId }>
                        <Button type="light" icon={<LeftOutlined />} size="large" style={{ marginRight: "5px" }} >{languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_SESSION} onChange={onChange} onSearch={(v) => { setSearch(v); get() }} />
                <Table dataSource={data} columns={columns}
                    onChange={handleTableChange} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "large" }}
                    pagination={{
                        total: totalCount
                    }}
                    rowSelection={{ ...rowSelection }} />
            </div>

        </div>
    );
};



export default SessionOrderItem;
