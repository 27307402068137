import React, { useEffect, useState,useGlobal } from "reactn";
import { Form, Button } from 'antd';
import { Link } from 'react-router-dom';
import {DragOutlined,LeftOutlined} from '@ant-design/icons';
import api from '../../service/index'
// C:\www\ICILETISIM\iciletisim-cms-new\src\service\index.js
import qs from 'qs';

 const Languages = (props) => {   
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
   }


  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  let [modules, setModules] = useState([]);
  let [languagesCms] = useGlobal("languagesCms"); 
  let _params = { page: 1, pageSize: 100 };

  let getResponse = async () => {

    await api.get(`/rest/languages?${qs.stringify(_params)}`).then(({data}) => {
      setModules(data.result.rows);
    });
}
    useEffect( () => {
      getResponse()
    }, []);

  const onDragStart = (event) => {
   const initialPosition = Number(event.currentTarget.dataset.position);
   
   setDragAndDrop({
    ...dragAndDrop,
    draggedFrom: initialPosition,
    isDragging: true,
    originalOrder: modules
   });
   
   event.dataTransfer.setData("text/html", '');
  }
  
  const onDragOver = (event) => {
   
   event.preventDefault();
   
   let newList = dragAndDrop.originalOrder;
  
   const draggedFrom = dragAndDrop.draggedFrom; 

   const draggedTo = Number(event.currentTarget.dataset.position); 
 
   const itemDragged = newList[draggedFrom];
   const remainingItems = newList.filter((item, index) => index !== draggedFrom);
 
    newList = [
     ...remainingItems.slice(0, draggedTo),
     itemDragged,
     ...remainingItems.slice(draggedTo)
    ];
     
   if (draggedTo !== dragAndDrop.draggedTo){
    setDragAndDrop({
     ...dragAndDrop,
     updatedOrder: newList,
     draggedTo: draggedTo
    })
   }
 
  }
  
  const onDrop = (event) => {
   
   setModules(dragAndDrop.updatedOrder);
   
   setDragAndDrop({
    ...dragAndDrop,
    draggedFrom: null,
    draggedTo: null,
    isDragging: false
   });
  }
 
 
  let onDragLeave = () => {
    setDragAndDrop({
    ...dragAndDrop,
    draggedTo: null
   });
   
  }
  React.useEffect( ()=>{
   
  }, [dragAndDrop])
  
  React.useEffect( ()=>{
   
  }, [modules])
  
  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
     return(
       <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Languages</h1> 
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
    </div>

    <div className="table-wrap">
    <ul className="dragDrop">
     
     {modules.map( (item, index) => {
      return(
       <li 
        key={index}
        
        data-position={index}
        draggable
        
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        
        onDragLeave={onDragLeave}
        
        className={dragAndDrop && dragAndDrop.draggedTo=== Number(index) ? "dropArea" : ""}
        >
        <div >
          <DragOutlined style={{ fontSize: 20 }} />
          <span className="nameDrag">{item.name}</span>
        </div>
    
         <div className="rightButtons">
           <Link to={"/languages/"+item._id}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
         </div>
         
       </li>
      )
     })}
      
    </ul>
    <Form.Item>
    {/* <Button type="primary" size="large" block onClick={saveOrders}> {languagesCms.SAVE_ORDER} </Button> */}
    </Form.Item>

         </div>
         </div>
         )
 };
 
 export default Languages;
