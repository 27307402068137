import React, { useEffect, useState,useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';
import moment from 'moment';

const PagePublicHolidaysDetail = (props) => {

    let params = useParams()
    let history = useHistory()
    let id = params.id
    let subId = params.subId

    let [items, setItems] = useState({});
    let [data, setData] = useState({});
    let [errors, ] = useState([]);
    let [loading, setLoading] = useState(true);
    let [languagesCms] = useGlobal("languagesCms"); 
    //onetime run
    useEffect(() => {
        get();
    }, []);

    let get = async () => {
        if (id) {
            await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
                if (subId) {
                    setData(result.items[subId]);
                }
                setItems(result)
                setLoading(false)
            });
        } else {
            setLoading(false)
        }
    }

    let save = async () => {
        if (subId) {
            items.items[subId] = data
            api.put("/rest/settings/" + id, items).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'success') {
                    message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                    history.push('/settings/pagePublicHolidays')
                }
                else
                    message.error("Kayıt güncellenirken bir hata oluştu.", 2);
            })
        } else {
            if(!data.date){
                data.date= new Date()
            }
            data._id = Math.random() * 1000
            items.items.push(data)
            api.put("/rest/settings/" + id, items).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'success') {
                    message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                    history.push('/settings/pagePublicHolidays')
                }
                else
                    message.error(languagesCms.ERROR_SAVE_RECORD, 2);
            })
        }
    };

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>Public Holidays</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/settings/pagePublicHolidays">
<Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>


                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="id" help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                                        <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="Title" help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                                        <Input name="title" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="Tarih:" help={errors.date} validateStatus={errors.date ? 'error' : 'success'}>
                                        <DatePicker defaultValue={() => moment(data.date)} onChange={v => setData({ ...data, date: v })} format='DD/MM/YYYY' showTime={true} />
                                        <br /><small> </small>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};



export default PagePublicHolidaysDetail;
