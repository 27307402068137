import React, { useEffect, useState,useGlobal } from "reactn";
import { Row, Col, Form, Input, Button, Card, message, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../../service/index'
import { Link } from 'react-router-dom';

const SmarteventSetting = (props) => {

  let history = useHistory()
  let id = "smatevent_settings"

  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }

  let save = async () => {
    if (id) {
      api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
          history.push('/settings')
        }
        else
          message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      })
    } else {
      api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          history.push('/settings')
        } else {
          message.error(result_message.message, 2);
        }
      })
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Smartevent Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="id" help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                    <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="IOS Header Url" help={errors.ios_header} validateStatus={errors.ios_header ? 'error' : 'success'}>
                    <Input name="ios_header" value={data.ios_header} onChange={e => setData({ ...data, ios_header: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Android Header Url" help={errors.android_header} validateStatus={errors.android_header ? 'error' : 'success'}>
                    <Input name="android_header" value={data.android_header} onChange={e => setData({ ...data, android_header: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="IOS Background Image Url" help={errors.ios_back} validateStatus={errors.ios_back ? 'error' : 'success'}>
                    <Input name="ios_back" value={data.ios_back} onChange={e => setData({ ...data, ios_back: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Android Background Image Url" help={errors.android_back} validateStatus={errors.android_back ? 'error' : 'success'}>
                    <Input name="android_back" value={data.android_back} onChange={e => setData({ ...data, android_back: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="IOS Footer Image Url" help={errors.ios_footer} validateStatus={errors.ios_footer ? 'error' : 'success'}>
                    <Input name="ios_footer" value={data.ios_footer} onChange={e => setData({ ...data, ios_footer: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Android Footer Image Url" help={errors.android_footer} validateStatus={errors.android_footer ? 'error' : 'success'}>
                    <Input name="android_footer" value={data.android_footer} onChange={e => setData({ ...data, android_footer: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="IOS Menu Top Space" validateStatus={errors.ios_menuTopSpace ? 'error' : 'success'}>
                    <InputNumber name="ios_menuTopSpace" value={data.ios_menuTopSpace} onChange={v => setData({ ...data, ios_menuTopSpace: v })} />
                    <br /><small>IOS modül başlangıç uzaklığı</small>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Android Menu Top Space" validateStatus={errors.android_menuTopSpace ? 'error' : 'success'}>
                    <InputNumber name="android_menuTopSpace" value={data.android_menuTopSpace} onChange={v => setData({ ...data, android_menuTopSpace: v })} />
                    <br /><small>Android modül başlangıç uzaklığı</small>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="IOS Module Size" validateStatus={errors.ios_moduleSize ? 'error' : 'success'}>
                    <InputNumber name="ios_moduleSize" value={data.ios_moduleSize} onChange={v => setData({ ...data, ios_moduleSize: v })} />
                    <br /><small>IOS modül boyutu</small>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Android Module Size" validateStatus={errors.android_moduleSize ? 'error' : 'success'}>
                    <InputNumber name="android_moduleSize" value={data.android_moduleSize} onChange={v => setData({ ...data, android_moduleSize: v })} />
                    <br /><small>Android modül boyutu</small>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Pagination Point Colors" help={errors.paginationPointColors} validateStatus={errors.paginationPointColors ? 'error' : 'success'}>
                    <Input name="paginationPointColors" value={data.paginationPointColors} onChange={e => setData({ ...data, paginationPointColors: e.target.value })} />
                    <br /><small>Sayfalama noktalarının rengi.</small>
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default SmarteventSetting;
