import React, {useEffect, useState, useGlobal} from "reactn";

import {useParams} from 'react-router-dom'
import {Row, Col, Form, Input, Button, Card, message, InputNumber, Alert} from 'antd';
import {useHistory} from 'react-router-dom';
import {LeftOutlined} from "@ant-design/icons";
import {FileInputSingle, GroupPicker, LangPicker} from '../components';

import api from '../service/index'
import {Link} from 'react-router-dom';

import {useCallback} from "react";

const ContactDetail = (props) => {

    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 

    /*if (settings) {
        settings.forEach(element => {
            if (element._id === "profileSettings") {
                profileSettings = element
            }
        });
    }*/

    let newRecord = {
        name: '',
        groups: [],
        phone: '',
        phoneWhatsApp: '',
        email: '',
        company: '',
        position: '',
        order: 0,
        lang: langCode ? langCode :  langCms[0].code ,
    }
    let [data, setData] = useState(id ? [] : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
            api.get("/rest/contacts/" + id).then(({data: {result, result_message}}) => {
                if (id) {
                    result.sendSms = false
                }
                delete result.password;
                setData(result);
                setLoading(false)
            });
        }
    }, [id]);

    let validate = useCallback(() => {
        let errors = {};

        if (data.name === null || data.name.length === 0)
            errors.name = languagesCms.ENFORCED

        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => {
        if (validationCheck) setErrors(validate())
    }, [validationCheck, data, validate]);


    let save = async () => {
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({top: 20, behavior: 'smooth'});
        } else {

            if (id) {
                api.put("/rest/contacts/" + id, data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/contacts')
                    } else
                        message.error(result_message.message, 2);
                })
            } else {
                api.post("/rest/contacts", data).then(({data: {result, result_message}}) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/contacts')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };


    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/contacts">
                        <Button type="light" icon={<LeftOutlined/>}
                                size={'large'}>{!isSmall && languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                {!loading &&
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>
                            <Row direction="row">
                                <Col xs={{span: 24}}>
                                    <Form.Item label={languagesCms.PHOTO} help={errors.image}
                                               validateStatus={errors.image ? 'error' : 'success'}>
                                        <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="image"/>
                                        <Alert message={languagesCms.AVATAR_PIXEL} banner/>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.GROUP} help={errors.groups}
                                               validateStatus={errors.active ? 'error' : 'success'}>
                                        <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.LANG}>
                                        <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.NAME_LASTNAME} required help={errors.name}
                                               validateStatus={errors.name ? 'error' : 'success'}>
                                        <Input name="name" value={data.name}
                                               onChange={e => setData({...data, name: e.target.value})}/>
                                    </Form.Item>
                                </Col>

                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.ORDER} help={errors.order}
                                               validateStatus={errors.order ? 'error' : 'success'}>
                                        <InputNumber name="order" value={data.order}
                                                     onChange={v => setData({...data, order: v})}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.EMAIL} validateStatus={errors.email ? 'error' : 'success'}>
                                        <Input name="email" value={data.email}
                                               onChange={e => setData({...data, email: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.PHONE} help={errors.phone}
                                               validateStatus={errors.phone ? 'error' : 'success'}>
                                        <Input name="phone" value={data.phone}
                                               onChange={e => setData({...data, phone: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="WhatsApp" help={errors.phoneWhatsApp}
                                               validateStatus={errors.phoneWhatsApp ? 'error' : 'success'}>
                                        <Input name="phoneWhatsApp" placeholder="Ör: +905*********"
                                               value={data.phoneWhatsApp}
                                               onChange={e => setData({...data, phoneWhatsApp: e.target.value})}/>
                                        <Alert message={languagesCms.CONTACT_ALERT_MESSAGE} banner/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.COMPANY} help={errors.company}
                                               validateStatus={errors.company ? 'error' : 'success'}>
                                        <Input name="company" value={data.company}
                                               onChange={e => setData({...data, company: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label={languagesCms.POSITION} help={errors.position}
                                               validateStatus={errors.position ? 'error' : 'success'}>
                                        <Input name="position" value={data.position}
                                               onChange={e => setData({...data, position: e.target.value})}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" disabled={loading} htmlType="submit" size="large"
                                                block> {languagesCms.SAVE} </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                }
            </div>
        </div>
    );
};
export default ContactDetail;
