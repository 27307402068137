import React from "react";
import { useGlobal } from "reactn";
import { Form, Input, Button, Select } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from './../service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { env } from '../app.config'
const { Option } = Select

const Login = (props) => {

  let [langCms] = useGlobal("langCms")
  let [, setToken] = useGlobal("token");
  let [, setModules] = useGlobal("modules");
  let [, setLanguagesCms] = useGlobal("languagesCms");
  let [, setUser] = useGlobal("user");
  let [, setSettings] = useGlobal("settings");
  let [, setCustomization] = useGlobal("customization");
  let [, setLangs] = useGlobal("lang");
  let [langCode, setLangCode] = useGlobal("langCode");
  let [, setPdfIcon] = useGlobal("pdf");
  let [, setLogo] = useGlobal("logo");
  let [, setLogoEN] = useGlobal("logoEN");
  let [, setLogin_setting] = useGlobal("login_setting");

  const auth = async ({ username, password }) => {
    let params = {
      "email": username,
      "password": password,
      "loginType": "emailPass"
    }

    let query = {
      "lang": langCode ? langCode : langCms[0].code,
      "adminShow": "true"
    }
    let queryLangCms = {
      "langCode": langCode ? langCode : langCms[0].code,
    }

    delete api.headers['Authorization'];
    await api.post('/api/login', params).then(async ({ data }) => {
      setToken(data.result.token);
      setUser(data.result);
      localStorage.setItem("user", JSON.stringify(data.result));
      //Cookies.set("user", data.result)

      api.setHeader('Authorization', "Bearer " + data.result.token);

      Cookies.set("token", data.result.token)
      await api.get(`/rest/languagesCms?${qs.stringify(queryLangCms)}`).then(({ data }) => {
        if (langCode) {
        data.result.rows.forEach(element => {
            if (element._id === langCode) {
              setLanguagesCms(element.items);
              localStorage.setItem("languagesCms", JSON.stringify(element.items));
              props.history.push(element.type);
            }
        })
        } 
          else {
            data.result.rows.forEach(element => {
              if (element._id === langCms[0].code) {
                setLanguagesCms(element.items);
                localStorage.setItem("languagesCms", JSON.stringify(element.items));
                props.history.push(element.type);
              }
          })
          }
      })

      await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {

        setSettings(data.result.rows);
        if (data.result.rows) {
          data.result.rows.forEach(element => {
            if (element._id === "customization") {
              setCustomization(element)
              localStorage.setItem("customization", JSON.stringify(element));
              setLogo(element.cmsLogo)
              localStorage.setItem("logo", JSON.stringify(element.cmsLogo));
              setLogoEN(element.cmsLogoEN)
              localStorage.setItem("cmsLogoEN", JSON.stringify(element.cmsLogoEN));
            }
            if (element._id === "login_setting") {
              setLogin_setting(element.companyName)
              localStorage.setItem("login_setting", JSON.stringify(element.companyName));
            }
            if (element._id === "supportedLangs") {
              setLangs(element.items)
              localStorage.setItem("langs", JSON.stringify(element.items));
            }
            if (element._id === "surveyWithMediaIcons") {
              setPdfIcon(element.pdf)
              localStorage.setItem("pdfIcon", JSON.stringify(element.pdf));
            }
          });
        }
        localStorage.setItem("settings", JSON.stringify(data.result.rows));
      })

      await api.get(`/rest/modules?${qs.stringify(query)}`).then(({ data }) => {
        setModules(data.result.rows);
        localStorage.setItem("modules", JSON.stringify(data.result.rows)); 
        props.history.push(data.result.rows[0].type);
      })



    }).catch((e) => {
      alert('Password or Username is incorrect');
    });

  };

  const onFinish = (values) => {
    auth(values);
  };

  function handleMenuClick(e) {
    localStorage.setItem("langCode", JSON.stringify(e));
    setLangCode(e)
  }

  if (env.COMPANY === "ISBANK")
    return (
      <div className="login-wrap h-100">
        <div className="form" style={{ alignItems: "center" }}>
          <a href={env.REDIRECTURL} className="ant-btn ant-btn-light ant-btn-lg">Tekrar giriş yapmak için tıklayınız.</a>
        </div>
      </div>
    )
  else
    return (
      <div className="login-wrap">
        <div className="form">
          < Form
            name="normal_login"
            className="login-form"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <div className="logo"></div>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {langCms && langCms.length > 1 &&
              <Form.Item>
                <Select defaultValue={langCms[0].code} style={{ width: 120 }} onChange={(e)=> handleMenuClick(e)}>
                  {langCms.map((element, index) =>
                    <Option key={index} value={element.code}>{element.code}</Option>
                  )}
                </Select>
              </Form.Item>}

            <Form.Item>
              <Button
                type="light"
                htmlType="submit"
                className="login-form-button"
                id="1"
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div >
    );
};

export default Login;
