import React, { useEffect } from 'react';
import { setGlobal, useGlobal } from "reactn";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Admin from './Admin';
import axios from 'axios'
import api from './service';
import Cookies from 'js-cookie';
import qs from 'qs';

import OAuth from './components/isbankAuth';
import { env } from './app.config'

axios.interceptors.response.use(result => {
  return result
}, (err) => {
  if (err.response && err.response.status === 401) {
    setGlobal({ token: null })
  }
  return Promise.reject(err)
})

setGlobal({
  isSmall: window.innerWidth <= 1024,
  collapsed: false,
  token: Cookies.get("token"),

  user: localStorage.getItem("user") === undefined ? {} : JSON.parse(localStorage.getItem("user")),
  modules: localStorage.getItem("modules") === undefined ? [] : JSON.parse(localStorage.getItem("modules")),
  settings: localStorage.getItem("settings") === undefined ? [] : JSON.parse(localStorage.getItem("settings")),
  customization: localStorage.getItem("customization") === undefined ? {} : JSON.parse(localStorage.getItem("customization")),
  langs: localStorage.getItem("langs") === undefined ? {} : JSON.parse(localStorage.getItem("langs")),
  langCms: localStorage.getItem("langCms") === undefined ? {} : JSON.parse(localStorage.getItem("langCms")),
  langCode: localStorage.getItem("langCode") === undefined ? {} : JSON.parse(localStorage.getItem("langCode")),
  languagesCms: localStorage.getItem("languagesCms") === undefined ? {} : JSON.parse(localStorage.getItem("languagesCms")),
  logo: localStorage.getItem("logo") === undefined ? "" : JSON.parse(localStorage.getItem("logo")),
  pdfIcon: localStorage.getItem("pdfIcon") === undefined ? "" : JSON.parse(localStorage.getItem("pdfIcon")),
  login_setting: localStorage.getItem("login_setting") === undefined ? "" : JSON.parse(localStorage.getItem("login_setting")),
});

const App = (props) => {
  let [, setToken] = useGlobal('token');
  let [isSmall, setIsSmall] = useGlobal('isSmall')
  let [, setCollapsed] = useGlobal('collapsed');
  let [, setLangsCms] = useGlobal("langCms");
  let [langCode,setLangCode]=useGlobal("langCode");

  let getLangs=async ()=>{
        await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
        if (data.result.rows) {
          data.result.rows.forEach(element => {
            if (element._id === "supportedCmsLangs") {
              setLangsCms(element.items)
              localStorage.setItem("langsCms", JSON.stringify(element.items));
              if(!langCode){
                setLangCode(element.items[0].code)
              }
            }
            });
          }
        })
      }
  useEffect(() => {
    getLangs()
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024)
    }
  }, [setIsSmall, setToken])
  useEffect(() => {
    getLangs()
    if (isSmall)
      setCollapsed(true)
    else
      setCollapsed(false)
  }, [isSmall, setCollapsed])

  return (
    <Router>
      <Switch>
        {env.COMPANY === "ISBANK" && <Route path="/ouath/:id/:key" component={OAuth} />}
        <Route path="/login" component={Login} />
        <Route path="/" component={Admin} />
      </Switch>

    </Router>
  );
};


export default App;
