import React, { useState, useMemo } from 'react';
import {Select} from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let {record, setRecord, name, mode,} = props;
	let [cities, setCities] = useState([]);
	let _params = {sort: "-createdAt", page: 1, pageSize: 100 };
	let languagesCms = props.languagesCms;

	useMemo(() => {
		api.get(`/rest/cities?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
			let dbCities = data.result.rows.sort((a,b) => a.name.localeCompare(b.name))
			setCities(dbCities);
			}
		})
	}, [])

	if(props.getCityById){
		return <Select value={record[name] || [] }  
				showSearch={true}
				mode={mode} 
				placeholder={languagesCms.SELECT_CITY} 			    
				onChange={v => setRecord({...record, [name]: v })}
				filterOption={(input, option) =>
					 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				 }
				>

			    {(cities) && cities.map(b =>
				<Select.Option key={b.name} value={b._id} >{b.name}</Select.Option>)}

		    </Select>
	}
	else{
		return <Select value={record[name] || [] }  
				showSearch={true}
				mode={mode} 
				placeholder={languagesCms.SELECT_CITY} 			    
				onChange={v => setRecord({...record, [name]: v })}
				filterOption={true}
				>

			    {(cities) && cities.map(b =>
				<Select.Option key={b.name} value={b.name} >{b.name}</Select.Option>)}

		    </Select>
	}
	
};