import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useEffect, useState, useGlobal } from "reactn";
import api from '../service/index'

const { Sider } = Layout;

const SideMenu = ({ testFunc }) => {
    const [collapsed, setCollapsed] = useGlobal('collapsed');
    let [isSmall] = useGlobal('isSmall');
    let [modules] = useGlobal("modules");
    let [user, ] = useGlobal("user");
    let [attendeeGroupModules, setAttendeeGroupModules] = useState([])
    //let [AttendeeGroup, setAttendeeGroup] = useState([]);
    let logo = useGlobal("logo")[0];
    let cpName = useGlobal("login_setting")[0];

    var url = {
        backgroundImage: `url(${logo})`,
        backgroundPosition:'center',
        backgroundSize:'contain'
    };
    let getGroups=async ()=>{
        let modules=[];
        if(user){
            let attendee=await api.get(`/rest/attendees/`+user._id)
            if(attendee.data.result && attendee.data.result.groups && attendee.data.result.groups.length>0){
                await Promise.all(attendee.data.result.groups.map(async (x,i)=>{
                    let attendeeGroup=await api.get(`/rest/attendeeGroups/`+attendee.data.result.groups[i])
                    if(attendeeGroup.data.result.modules){
                        modules=modules.concat(attendeeGroup.data.result.modules.filter(ele => !modules.includes(ele)))
                    }
                    setAttendeeGroupModules(modules)
                }))
            }
        }
        else{
            setAttendeeGroupModules([])
        }

    }
    useEffect(() => {
        getGroups()
    }, [])
    function tabTitle(titleName) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title= cpName + " - " + titleName;
    }

    const getMenu = () => {
        if (modules) {
            if(attendeeGroupModules && attendeeGroupModules.length>0){
                return modules.forEach(item => {
                    let findModul=attendeeGroupModules.find(z=>z.id===item._id)
                    if(findModul){
                        if (item.type === "infos") {
                            item.type = item.refId;
                        }
                        return (<Menu.Item key={item.type} onClick={() => tabTitle(item.name) }>
                            <Link to={"/" + item.type}>
                                <img alt="" src={item.icon} width="40" />
                                <span>{item.name}</span>
                            </Link>
                        </Menu.Item>);
                    }})
            }
            else{
                return modules.map(item => {
                    if (item.type === "infos") {
                        item.type = item.refId;
                    }
                    return (<Menu.Item key={item.type} onClick={() => tabTitle(item.name) }>
                        <Link to={"/" + item.type}>
                            <img alt="" src={item.icon} width="40" />
                            <span>{item.name}</span>
                        </Link>
                    </Menu.Item>);
                })
            }
        }

    }

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} theme="light" width={isSmall ? '150' : '260'}>
            <div className="logo-wrap">
                <div className="logo" style={url}></div>
            </div>
            <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
                {getMenu()}
            </Menu>
        </Sider>
    );
};


export default SideMenu;