import React, {useState, useEffect, useMemo} from 'react';
import { Input,Form,Button, Select,List,Switch,Typography, Modal,Checkbox,Row, Col,InputNumber,DatePicker, Alert} from 'antd';
import {Pie} from 'react-chartjs-2';
import api from '../service/index';
import {FileInputSingle} from '../components';
import moment from "moment";
import { nanoid } from 'nanoid';
//import { useParams } from 'react-router-dom'

let QuestionInput = props => {
    //let params = useParams()
    let {question, setQuestion, disabled} = props;
    let surveyId = props.surveyId;
    let [questionChart, setQuestionChart] = useState(null);
    //let id = params.id !== "add" ? params.id : false;
	let languagesCms = props.languagesCms;



    let [questionImageSwitch, setQuestionImageSwitch] = useState(false)
    let setField = field => value => {
        setQuestion({...question, [field]: value});
    };
    let setChoiceField = i => field => value => {
        setField('choices')(
            question.choices.map((c, _i) => i === _i ? {...c, [field]: value} : c)
        );
    };
    let addChoice = (choice) => {
        if (choice && choice.media) {
            setField('choices')(question.choices.map(element => {
               if (element.id === choice.id) {
                  element.urlName = choice.media.id
                  element.media = choice.media
                  element.name = choice.name
               }
               return element
            }))
        }else{
            setField('choices')(
                [...question.choices, {
                    name: '',
                    id: nanoid(),
                    is_other: false,
                    is_media: false,
                    is_selected: false,
                    correctAnswer: false,
                    type: [],
                    other: '',
                    media: question.choices.media ? {
                        _id: "",
                        thumb: "",
                        url: "",
                        type: "",
                        mimeType: ""
                    } : null 
                }]
            );
         }
    };

    useEffect(() => {
        setQuestionImageSwitch(false)
        if (question.type === 'Media' && question.choices.length < 1) {
            addChoice()
        } 
    },[question.type])

    let deleteChoice = i => {
        setField('choices')(
            question.choices.filter((c, _i) => _i !== i)
        );
    };
    return <div style={{border: '1px solid #ccc', borderRadius: 5, width: 800, padding: 10}}>
        <div style={{display: 'block', marginBottom: 10}}>
            <Button style={{float: 'right', zIndex: 10}} type="danger" disabled={disabled}
                    onClick={props.delete}>{languagesCms.DELETE_QUESTION}</Button>
            {question.type !== 'Free' && question.type !== 'Media' && question.childType !== 'Media' && question.type !== 'TextToImage'&&
                <Button type="primary" onClick={() => setQuestionChart(question)}> Report </Button>
            }
        </div>
        <ChartModal record={questionChart} setRecord={setQuestionChart} surveyId={surveyId}/>

        <Form.Item label={languagesCms.QUESTION_TYPE} required={true}>
            <Select value={question.type} onChange={setField('type')} disabled={disabled}>
                <Select.Option value="Single">{languagesCms.SINGLE_TEXT} </Select.Option>
                <Select.Option value="Multiple">{languagesCms.MULTI_TEXT} </Select.Option>
                <Select.Option value="Free">{languagesCms.FREE_TEXT} </Select.Option>
                <Select.Option value="Media">{languagesCms.ADD_MEDIA}</Select.Option>
                <Select.Option value="TextToImage">{languagesCms.TEXT_PICTURE}</Select.Option>
                <Select.Option value="Date">{languagesCms.DATE}</Select.Option>
                <Select.Option value="DateRange">{languagesCms.DATE_RANGE}</Select.Option>
                {/* <Select.Option value="Selected">Sonuçlu </Select.Option> */}
            </Select>
        </Form.Item>
        <div style={{
            // display: "flex",
            justifyContent: (question.type === "DateRange" || question.type === "Date") && "space-between"
        }}>
            <Form.Item style={{width: 180, float: "left"}}>
                <Typography.Text strong>{languagesCms.REQUIRED_QUESTION}</Typography.Text>
                <Switch style={{marginLeft: 5}} checked={question.required} disabled={disabled}
                        onChange={setField('required')}/>
            </Form.Item>

            { question.type === 'Single' &&(
                <Form.Item style={{width: 180}}>
                <Typography.Text strong>{languagesCms.QUESTION_PICTURE}</Typography.Text>
                <Switch style={{marginLeft: 5}} checked={question.media } disabled={disabled}
                       onChange={setQuestionImageSwitch} />
            </Form.Item>
            )}
            { ( (question.media || questionImageSwitch === true ) && question.type === "Single") && (
                <Form.Item label={languagesCms.PHOTO} >
                    <FileInputSingle languagesCms={languagesCms}  record={question} setRecord={setQuestion} name="media"/>
                    <Alert type="info" message="Görsel 1920 x 1080 Boyutlarında Olmalıdır." banner /> 
                </Form.Item>
            )}

            {(question.type === "Date" || question.type === "DateRange") &&
                <Form.Item>
                    <Typography.Text strong>{languagesCms.TIME_OPTION}</Typography.Text>
                    <Switch style={{marginLeft: 5}} onChange={setField('clockOption')} checked={question.clockOption}/>
                </Form.Item>
            }
            {(question.type === "Date" || question.type === "DateRange") &&
                <Form.Item>
                    <Typography.Text strong>{languagesCms.MEDIA_WİTH_QUESTION_MESSAGE}</Typography.Text>
                    <Switch style={{marginLeft: 5}} onChange={setField('dateRangeConstraint')}
                            checked={question.dateRangeConstraint}/>
                </Form.Item>
            }
           
        </div>
        {question.dateRangeConstraint && (question.type === "Date" || question.type === "DateRange") && (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    paddingRight: 15
                }}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Typography.Text strong>{languagesCms.EARLIEST_DATE} </Typography.Text>
                    <DatePicker
                        format="DD-MM-YYYY"
                        defaultValue={moment(question.earliestDate || new Date())}
                        disabledDate={(current) => current && current > question.latestDate}
                        style={{width: 250, marginLeft: 5}}
                        placeholder={languagesCms.SELECT_EARLIEST_DATE}
                        onChange={(e) => setField("earliestDate")(e ? e._d : null)
                        }/>
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop: 10}}>
                    <Typography.Text strong>{languagesCms.LATEST_DATE} </Typography.Text>
                    <DatePicker
                        defaultValue={moment(question.latestDate || new Date())}
                        disabledDate={(current) => current && current < question.earliestDate}
                        format="DD-MM-YYYY"
                        style={{width: 250, marginLeft: 5}}
                        placeholder={languagesCms.SELECT_LATEST_DATE}
                        onChange={(e) => setField("latestDate")(e ? e._d : null)
                        }/>
                </div>
            </div>
        )}
        <Form.Item label={languagesCms.QUESTION_TEXT} required={true}>
            <Input.TextArea value={question.name} multiple={true} style={{width: 750, height: 100}}
                            disabled={disabled}
                            onChange={e => setField('name')(e.target.value)}/>
        </Form.Item>

        {question.type !== 'Free' && question.type !== "Date" && question.type !== "DateRange" &&
         <>
            {question.type === 'Selected'  && <>
                <Form.Item label={languagesCms.OPTIONS} required={true}>
                    <Select value={question.childType} onChange={setField('childType')} disabled={disabled}>
                        <Select.Option value="Media">{languagesCms.MEDIA}</Select.Option>
                        <Select.Option value="Text">{languagesCms.TEXT}</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Typography.Text strong>{languagesCms.SHOW_REPLY}</Typography.Text>
                    <Switch style={{marginLeft: 5}} checked={question.showAnswer} disabled={disabled}
                            onChange={setField('showAnswer')}/>
                </Form.Item>
            </>}

            <div style={{borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 15}}>
                <Typography.Text strong>{languagesCms.OPTIONS}</Typography.Text>
                {question.type !== 'Media' && 
                 <Button style={{float: 'right'}} onClick={addChoice} disabled={disabled}>{languagesCms.ADD_OPTION}</Button>
                }
                {question.type !== 'Media' && 
                 <Alert banner message={languagesCms.FREE_TEXT_ALERT_MESSAGE}/>
                }
                
            </div>
            <List>
                {question.choices.map((c, i) =>
                    <List.Item key={i} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div style={{marginRight: 5}}>{i + 1}.</div>
               {question.type === "TextToImage" &&(
                   <div>
                   <FileInputSingle languagesCms={languagesCms} name='media' title='Medyalar'  multi={true} record={c} setRecord={value => setChoiceField(i)('media')(value.media)} />
                        <Alert type="info" message="Kare görsel olmalıdır." banner />
                     </div>
               )
               }
                        {question.childType !== 'Media' && question.type !== 'Media' && question.type!=="TextToImage" && 
                            <div>
                                <Input style={{width: 350, marginLeft: 2, display: 'flex', alignItems: 'center'}}
                                       disabled={disabled}
                                       value={c.name} onChange={e => setChoiceField(i)('name')(e.target.value)}/>

                            </div>
                        }
                        {question.childType === 'Media' && question.type!=="TextToImage" &&
                            <div> Görsel
                                <FileInputSingle languagesCms={languagesCms} name="media" record={c.media} disabled={disabled}
                                                 setRecord={value => setChoiceField(i)('media')(value.media)}
                                                 set/>
                            </div>
                        }
                        {question.type === 'Media' && question.type!=="TextToImage" &&
                            <div>
                                <Checkbox.Group style={{width: 200, marginLeft: 5}} name="type" disabled={disabled}
                                                defaultValue={c.type} onChange={e => setChoiceField(i)('type')(e)}>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="photo">{languagesCms.PHOTO}</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="video">Video</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="pdf">PDF</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </div>
                        }
                        {c.is_other && (
                            <Form.Item className="freeLengthChoice">
                                <Typography.Text strong>{languagesCms.SELECT_LENGTH}</Typography.Text>
                                <InputNumber value={question.freeLengthChoice} onChange={setField('freeLengthChoice')}
                                             placeholder={languagesCms.MAX_CHARACTER} type="number"/>
                            </Form.Item>
                        )}
                        <div
                            style={{display: 'flex', justifyContent: 'space-between', float: 'right', minWidth: '20%'}}>
                            {i === question.choices.length - 1 && question.type !== 'Selected' && question.type !== 'Media' && question.type!=="TextToImage"&&  c.is_media !== true &&
                                <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>{languagesCms.FREE_TEXT}<Switch style={{marginLeft: 5}} checked={c.is_other} disabled={disabled}
                                            onChange={value => {
                                                setChoiceField(i)('is_other')(value)
                                            }}/>
                                </div>
                            }
                            {question.type === 'Selected' &&
                                <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>
                                    <Switch style={{marginLeft: 5, float: 'right'}} checked={c.correctAnswer}
                                            disabled={disabled} checkedChildren={languagesCms.TRUE} unCheckedChildren={languagesCms.FALSE}
                                            onChange={value => setChoiceField(i)('correctAnswer')(value)}/>
                                </div>
                            }
                            {i === question.choices.length - 1 && question.type !== 'Selected' && question.type !== 'Media' && question.type!=="TextToImage"&& c.is_other !== true &&
                                <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>{languagesCms.MEDIA}
                                
                                    <Switch style={{marginLeft: 5}} checked={c.is_media} disabled={disabled}
                                            onChange={value => setChoiceField(i)('is_media')(value)}/>
                                </div>
                            }
                            {question.type !== 'Media' && i === question.choices.length - 1 && c.is_media &&
                                <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>
                                    <Checkbox.Group style={{marginLeft: 5, float: 'right'}} name="type"
                                                    disabled={disabled}
                                                    defaultValue={c.type} onChange={e => setChoiceField(i)('type')(e)}>
                                        <Row>
                                            <Col span={12}>
                                                <Checkbox value="photo">{languagesCms.PHOTO}</Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox value="video">Video</Checkbox>
                                            </Col>
                                            <Col span={12}>
                                                <Checkbox value="pdf">PDF</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </div>
                            }
                            { question.type !== 'Media' &&	
                              <div style={{marginLeft: 10, float: 'right'}}>
                                 <Button type="danger" shape="circle" disabled={disabled}
                                      onClick={() => deleteChoice(i)}>{languagesCms.DELETE}</Button>
                            </div>
                            }
                        </div>
                    </List.Item>)}

                {question.type === "Multiple" &&
                    <div className="multipleChoiceSelectOptions">
                    <Alert type="info" message={`En Az ${question.minMultipleChoice} Tane , En Fazla ${question.maxMultipleChoice} Tane Seçenek Girmelisiniz.`} banner />
                        <Form.Item label={languagesCms.MIN_MULTI_SELECT}>
                            <Select value={question.minMultipleChoice || 1}
                                    defaultValue={question.minMultipleChoice || 1}
                                    onChange={setField('minMultipleChoice')}>
                                <Select.Option value={1}>{1}</Select.Option>
                                {
                                    question.choices.slice(1, question.choices.length - 1).map((choice, index) =>
                                        <React.Fragment key={index + 2}>
                                            <Select.Option value={index + 2}>{index + 2}</Select.Option>
                                        </React.Fragment>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label={languagesCms.MAX_MULTI_SELECT}>
                            <Select value={question.minMultipleChoice > (question.maxMultipleChoice || 1)
                                ? question.minMultipleChoice : question.maxMultipleChoice}
                                    defaultValue={question.choices.length || 1}
                                    disabled={question.minMultipleChoice === question.choices.length - 1}
                                    onChange={setField('maxMultipleChoice')}>
                                {(question.minMultipleChoice === 1 || !question.minMultipleChoice) &&
                                    <Select.Option value={1}>{1}</Select.Option>
                                }
                                {
                                    question.choices.slice(question.minMultipleChoice - 1,
                                        question.minMultipleChoice < 2 ? question.choices.length + 1 : question.choices.length)
                                        .map((choice, index) =>
                                            <React.Fragment key={index + (question.minMultipleChoice || 2)}>
                                                <Select.Option
                                                    value={index + (question.minMultipleChoice || 2)}>{index + (question.minMultipleChoice || 2)}</Select.Option>
                                            </React.Fragment>
                                        )
                                }
                            </Select>
                        </Form.Item>

                    </div>
                }


            </List>

        </>}
        {question.type === "Free" &&
            <Form.Item className="freeLengthChoice">
                <Typography.Text strong>{languagesCms.SELECT_LENGTH}</Typography.Text>
                <InputNumber value={question.freeLengthChoice} onChange={setField('freeLengthChoice')}
                             placeholder={languagesCms.MAX_CHARACTER} type="number"/>
            </Form.Item>
        }
    </div>;
};
export default (props) => {
    let {record, setRecord, name, disabled} = props;
    let questions = record[name];
    let [page, setPage] = useState(0);
	let languagesCms = props.languagesCms;
    let addQuestion = () => {
        setRecord({
            ...record,
            questions: [...(questions ? questions : []), {
                name: '',
                type: 'Single',
                other: '',
                maxMultipleChoice: 1,
                minMultipleChoice: 1,
                dateRangeConstraint: false,
                choices: [],
                is_answered: false,
                earliestDate: new Date(),
                latestDate: new Date(),
                freeLengthChoice: 1000,
                required: true
            }]
        });
        setPage(questions ? questions.length : 0);
    };

    return <Form.Item>
        <div style={questions && questions.length > 0 ? {width: 800} : {display: 'flex'}}>
            {questions && questions.map((q, i) =>
                <Button key={i} shape="circle" type={(i === page) ? 'primary' : 'dashed'}
                        onClick={() => setPage(i)} style={{marginRight: 5, marginBottom: 10}}>
                    {i + 1}
                </Button>)}
            <Button type="primary" disabled={disabled} style={{float: 'right'}}
                    onClick={addQuestion}>{languagesCms.ADD_QUESTION}</Button>
        </div>

        {questions && questions.map((q, i) => i === page &&
            <QuestionInput key={i} question={q} disabled={disabled} languagesCms={languagesCms}
                           surveyId={record._id}
                           setQuestion={newQ => setRecord({
                               ...record,
                               questions: questions.map((_q, _i) => _i === i ? newQ : _q)
                           })}
                           delete={() => {
                               setPage(page - 1)
                               setRecord({
                                   ...record,
                                   questions: questions.filter((_q, _i) => _i !== i)
                               })
                           }}/>)}
    </Form.Item>;
};

let ChartModal = props => {

    let {record, setRecord} = props;
    let surveyId = props.surveyId;
    let [chartData, setChartData] = useState(null);
    let [data, setData] = useState([]);
    let [options, setOptions] = useState({});


    useMemo(() => {
        if (record) {

            api.get(`/api/surveyWithMediaAnswers/chartData?id=${surveyId}&questionName=${record.name}`).then(({
             data: {result,result_message}}) => {
                setData(result);
            })
        }
    }, [record])


    useEffect(() => {
        let labels = [];
        let datasetsData = [];
        data.forEach(element => {
            labels.push(element._id);
            datasetsData.push(element.count);
        });

        setChartData({
            chartData: {
                labels: labels,
                datasets: [
                    {
                        label: '#datasetsData# %',
                        data: datasetsData,
                        backgroundColor: [
                            'rgba(0, 0, 255, 0.6)',
                            'rgba(255, 64, 64, 0.6)',
                            'rgba(127, 25,5 0, 0.6)',
                            'rgba(255, 185, 15, 0.6)',
                            'rgba(153, 102, 255, 0.6)',

                            'rgba(255, 159, 64, 0.6)',
                            'rgba(255, 99, 132, 0.6)',
                            'rgba(139, 0, 139, 0.6)',
                            'rgba(255, 140, 0, 0.6)',
                            'rgba(153, 50, 204, 0.6)',

                            'rgba(143, 188, 143, 0.6)',
                            'rgba(121, 205, 205, 0.6)',
                            'rgba(255, 20, 147, 0.6)',
                            'rgba(255, 48, 48, 0.6)',
                            'rgba(34, 139, 34, 0.6)',

                            'rgba(139, 117, 0, 0.6)',
                            'rgba(130, 130, 130, 0.6)',
                            'rgba(255, 105, 180, 0.6)',
                            'rgba(255, 246, 143, 0.6)',
                            'rgba(154, 192, 205, 0.6)',
                        ]
                    }
                ]
            }
        });

        setOptions({
            options: {
                responsive: true,
                legend: {
                    position: 'bottom',
                },
                title: {
                    display: false,
                    text: 'Chart.js Doughnut Chart'
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue;
                            });
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
                            return percentage + "%";
                        }
                    }
                }
            }
        })

    }, [data]);

    const printIframe = (id) => {
		const iframe = document.frames
			? document.frames[id]
			: document.getElementById(id);
		const iframeWindow = iframe.contentWindow || iframe;
		iframe.focus();
		iframeWindow.print();

		return false;
	};

    return (
        	record &&
            <div>
                <iframe
                    id="printSurvey"
                    src={"/SurveyWithMediaDetailPrint/" + surveyId + "/question/" + record.name}
                    title="Print"
                    style={{ position: 'absolute', zIndex: '-1' }}
                    width={window.innerWidth <= 1024 ? '100%': '50%'}
                />
                <Modal title={record === null ? "" : record.name} visible={record === null ? false : true} onOk={() => setRecord(null)} onCancel={() => setRecord(null)}
                    footer={[
                       <Button key="print" onClick={() => printIframe('printSurvey')}>
                               İndir
						{/* {languagesCms.PRINT} */}
					</Button>,
                        <Button key="submit" type="primary" onClick={() => setRecord(null)}>
                            OK
                        </Button>
                    ]}
                >
                    <ChartJS chartData={chartData} options={options} ></ChartJS>
    
                </Modal>
            </div>
    )
}

let ChartJS = props => {
    let {chartData} = props.chartData;
    let {options} = props.options;
    return (
        <div className="chart">
            <Pie data={chartData} options={options}/>
        </div>
    )
}
