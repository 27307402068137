import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row,Col,Form, Input, Button, DatePicker, Card, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const MeetingDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;

  let newRecord = {
    topic: '',
    startAt: new Date(),

  }
  
  let [data, setData] = useState(id ? {} : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
  
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/rest/meetings/" + id).then(({ data: { result, result_message } }) => {
          setData(result);   
          setLoading(false)
        });
      } 
  }, [id]);

 
  let validate = useCallback(() => {
    let errors = {};

    if (data.topic === null || data.topic.length === 0)
      errors.topic = languagesCms.ENFORCED

    if (data.startAt === null)
      errors.startAt = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
 
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if(id) {
        api.put("/rest/meetings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/meetings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/meetings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/meetings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      } 
    }        
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/meetings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Konu"required help={errors.topic} validateStatus={errors.topic ? 'error' : 'success'}>
                  <Input name="topic" value={data.topic} onChange={e => setData({ ...data, topic: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="Başlangıç"required help={errors.date} validateStatus={errors.date ? 'error' : 'success'}>
                <DatePicker defaultValue={() => moment(data.startAt).utc()} onChange={v => {
                  setData({ ...data, startAt: v })
                }} format='DD/MM/YYYY HH:mm' showTime={true} />
              </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Şifre" help={errors.password} validateStatus={errors.password ? 'error' : 'success'}>
                  <Input name="password" type='password' value={data.password} onChange={e => setData({ ...data, password: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};



export default MeetingDetail;
