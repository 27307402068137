import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Button, Switch, Card, message, InputNumber,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { ImageUrl, GroupPicker, MultipleLangPicker } from '../components';


import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const PhotoAlbumDetail = (props) => {

    let params = useParams()
    let history = useHistory()
    let id = params.id !== "add" ? params.id : false;
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms"); 
    let [langCode] = useGlobal("langCode"); 


    let newRecord = {
        langs: [{ lang: langCode ? langCode : langCms[0].code, title: "" }],
        active: false,
        order: 0,
        groups: []

    }

    let [data, setData] = useState(id ? {} : newRecord);
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(id ? true : false);
    let [validationCheck, setValidationCheck] = useState(false)
    
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if(modules !== null){
       [module] = modules.filter(el => el._id === path);
    }

    //onetime run
    useEffect(() => {
        if (modules && id) {
            setLoading(true)
                api.get("/rest/photoAlbumCategories/" + id).then(({ data: { result, result_message } }) => {
                    setData(result);
                    setLoading(false)
                });
        }
    }, [id]);


    let validate = useCallback(() => {
        let errors = {};

        if (data.imageurl === null)
            errors.imageurl = languagesCms.ENFORCED


        errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
        errors.hasError = errors.all.length > 0;
        return errors;
    }, [data]);

    useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

    let save = async () => {
        /*let r = data.langs.filter(l => l.lang === "" || l.title === "").length
        if (r) {
            message.error('Lütfen dil seçimini veya dile bağlı başlığı giriniz.')
            return false
        }*/
        setValidationCheck(true)
        let err = validate()
        if (err.hasError) {
            setErrors(err)
            window.scrollTo({ top: 20, behavior: 'smooth' });
        }
        else {
            if (id) {
                api.put("/rest/photoAlbumCategories/" + id, data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        history.push('/photoAlbum')

                    }
                    else
                        message.error(languagesCms.ERROR_SAVE_RECORD, 2);
                })
            } else {
                api.post("/rest/photoAlbumCategories", data).then(({ data: { result, result_message } }) => {
                    if (result_message.type === 'success') {
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        history.push('/photoAlbum')
                    } else {
                        message.error(result_message.message, 2);
                    }
                })
            }
        }
    };

    
    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    <Link to="/photoAlbum">
<Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>                    </Link>
                </div>
            </div>
            <div className="form-wrap">
                    <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
                        <Form layout="horizontal" size={"large"} onFinish={save}>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <MultipleLangPicker langCms={langCms} langCode={langCode}  title={'title'} record={data} setRecord={setData} name="lang" languagesCms={languagesCms} />
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label={languagesCms.GROUP}  >
                                        <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                                    </Form.Item>
                                </Col>
                            </Row> 

                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                                        <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.ORDER} help={errors.order} validateStatus={errors.order ? 'error' : 'success'}>
                                        <InputNumber name="order" value={data.order} onChange={v => setData({ ...data, order: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item label={languagesCms.MEDIA} required help={errors.imageurl} validateStatus={errors.imageurl ? 'error' : 'success'}>
                                        <ImageUrl record={data} setRecord={setData} name="imageurl" />
                                        <Alert  message={languagesCms.ALBUM_ALERT_MESSAGE} banner />
                                        <Alert  message={languagesCms.PHOTO_SIZE_MESSAGE} banner />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row direction="row">
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </Card>
            </div>
        </div>
    );
};



export default PhotoAlbumDetail;
