import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row,Col,Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { MapInput,CoordinateTypesPicker} from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";

const CoordinateDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let newRecord = {
    type: 'Özel Hastane',
    code: '',
    city: '',
    district: '',
    locality: '',
    phone: '',
    coordinate: '',
  }
  

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)
  
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
        api.get("/rest/coordinates/" + id).then(({ data: { result, result_message } }) => {
          setData(result);   
          setLoading(false)
        });
    }
  }, [id]);


  let validate = useCallback(() => {
    let errors = {};

    if (data.code === null || data.code.length === 0)
      errors.code = languagesCms.ENFORCED

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED
    
    if (data.city === null || data.city.length === 0)
      errors.city = languagesCms.ENFORCED

    if (data.district == null || data.district.length === 0)
      errors.district = languagesCms.ENFORCED
      
    if (data.locality == null || data.locality.length === 0)
      errors.locality = languagesCms.ENFORCED

    if (data.phone == null || data.phone.length === 0)
      errors.phone = languagesCms.ENFORCED

    if (data.phone == null || data.phone.length === 0)
      errors.phone = languagesCms.ENFORCED

    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  
  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else { 
      if(id) {
        api.put("/rest/coordinates/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/health')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/coordinates", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/health')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }         
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/health">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item label={languagesCms.TYPE} required help={errors.type} validateStatus={errors.active ? 'error' : 'success'}>
              <CoordinateTypesPicker languagesCms={languagesCms} record={data} setRecord={setData} name="type" />                    
              </Form.Item>
              </Col>
             </Row> 
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.ORGANISATION}required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>
             </Row>

             <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.ORGANISATION_CODE}required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="code" value={data.code} onChange={e => setData({ ...data, code: e.target.value })} />
                </Form.Item>
              </Col>
             </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.CITY} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="city" value={data.city} onChange={e => setData({ ...data, city: e.target.value })} />
                </Form.Item>
              </Col>
             </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.DISTRICT} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="district" value={data.district} onChange={e => setData({ ...data, district: e.target.value })} />
                </Form.Item>
              </Col>
             </Row>
             <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.NEIGHBORHOOD_VILLAGE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="locality" value={data.locality} onChange={e => setData({ ...data, locality: e.target.value })} />
                </Form.Item>
              </Col>
             </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.PHONE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="phone" value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} />
                </Form.Item>
              </Col>
             </Row> 


             <Row direction="row">
              <Col span={24}>
              <Form.Item label={languagesCms.LOCATION} required>
                  <MapInput languagesCms={languagesCms} record={data} setRecord={setData} name="coordinate" disabled={false} title="coordinate" required={false} />
              </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};



export default CoordinateDetail;
