import React, { useEffect, useState} from 'react';
import {Select} from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let {record, setRecord, name} = props;
	let [educations,setEducation] = useState([]);
	let _params = {sort: "-createdAt", page: 1, pageSize: 100 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/educations?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
			let dbEducations = data.result.rows.sort((a,b) => a.name.localeCompare(b.name))
			setEducation(dbEducations);
			}
		})
	}, [_params])

	return <Select value={record[name] || [] } mode='single' placeholder={languagesCms.SELECT_EDUCATION}
			onChange={v => setRecord({...record, [name]: v })}>
			{(educations) && educations.map(b =>
				<Select.Option key={b._id} value={b._id} >{b.name}</Select.Option>)}
		</Select>;
};