import React, { useEffect, useState } from "reactn";
import { useParams } from 'react-router-dom'
import {  Card } from 'antd';
import { Pie } from 'react-chartjs-2';
import api from '../service/index'

const SurveyDetailPrint = (props) => {
  let params = useParams()
  // let id = params.id !== "add" ? params.id : false;
  let surveyId = params.surveyId || false;
  let name = params.name || false;
  let [data, setData] = useState(null);
  let [loading, ] = useState(false);





  //if (modules !== null) {
   //let [module] = modules.filter(el => el._id === path);
  //}

  const [, setIsLoading] = useState(true);

  let [chartData, setChartData] = useState(null);
  let [options, setOptions] = useState({});

  useEffect(() => {
    if (surveyId)
      api.get(`/api/surveyAnswers/chartData?id=${surveyId}&questionName=${name}`).then(({ data: { result, result_message } }) => {
        if (result.length > 0) {
          setData(result);
          document.title = result[0].questionName
        }
      })
  }, [surveyId])

  useEffect(() => {
    let labels = [];
    let datasetsData = [];
    let total = 0;

    if (data) {
      data.forEach(element => {
        total += element.count;
      })

      data.forEach(element => {
        labels.push(element._id + " - " + element.count.toString() + " (%" + (Math.floor(((element.count / total) * 100) + 0.5)).toString() + ")");
        datasetsData.push(element.count);
      });

      if (datasetsData)
        setChartData({
          chartData: {
            labels: labels,
            datasets: [
              {
                data: datasetsData,
                backgroundColor: [
                  'rgba(0, 0, 255, 0.6)',
                  'rgba(255, 64, 64, 0.6)',
                  'rgba(127, 25,5 0, 0.6)',
                  'rgba(255, 185, 15, 0.6)',
                  'rgba(153, 102, 255, 0.6)',

                  'rgba(255, 159, 64, 0.6)',
                  'rgba(255, 99, 132, 0.6)',
                  'rgba(139, 0, 139, 0.6)',
                  'rgba(255, 140, 0, 0.6)',
                  'rgba(153, 50, 204, 0.6)',

                  'rgba(143, 188, 143, 0.6)',
                  'rgba(121, 205, 205, 0.6)',
                  'rgba(255, 20, 147, 0.6)',
                  'rgba(255, 48, 48, 0.6)',
                  'rgba(34, 139, 34, 0.6)',

                  'rgba(139, 117, 0, 0.6)',
                  'rgba(130, 130, 130, 0.6)',
                  'rgba(255, 105, 180, 0.6)',
                  'rgba(255, 246, 143, 0.6)',
                  'rgba(154, 192, 205, 0.6)',
                ]
              }
            ]
          }
        });

      setOptions({
        options: {
          responsive: true,
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
            text: 'Chart.js Doughnut Chart'
          },
          labels: {
            display: false,
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue;
                });
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
                return percentage + "%";
              }
            }
          }
        }
      })
    }

  }, [data]);

  const handleMessage = (event) => {
    if (event.data.action === 'receipt-loaded') {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div>
      <br />
      <br />
      <br />
      <br />

      <div className="form-wrap"> 
        {!loading && chartData &&
          <Card title="" loading={loading}>
            <h1>{name}</h1>
            <ChartJS chartData={chartData} options={options} ></ChartJS>
          </Card>
        }
      </div>
    </div>
  );
};

let ChartJS = props => {
  let { chartData } = props.chartData;
  let { options } = props.options;
  return (
    <div className="chart">
      <Pie data={chartData} options={options} />
    </div>
  )
}



export default SurveyDetailPrint;
