import React, { useEffect, useState,useGlobal } from "reactn";
import { Row,Col,Form, Input, Button, Card,Switch, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';

const LoginSetting = (props) => {

  let history = useHistory()
  let id = "login_setting"

  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };
  
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Login Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Background Color"  help={errors.background_color} validateStatus={errors.background_color ? 'error' : 'success'}>
                  <Input name="background_color" value={data.background_color} onChange={e => setData({ ...data, background_color: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span:12 }}>
                <Form.Item label="is Forgot Pass Phone" help={errors.isForgotPassPhone} validateStatus={errors.isForgotPassPhone ? 'error' : 'success'}>
                  <Switch checked={data.isForgotPassPhone ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, isForgotPassPhone: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="IOS Header Url"  help={errors.ios_header} validateStatus={errors.ios_header ? 'error' : 'success'}>
                  <Input name="ios_header" value={data.ios_header} onChange={e => setData({ ...data, ios_header: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Android Header Url"  help={errors.android_header} validateStatus={errors.android_header ? 'error' : 'success'}>
                  <Input name="android_header" value={data.android_header} onChange={e => setData({ ...data, android_header: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="IOS Background Image Url"  help={errors.ios_back} validateStatus={errors.ios_back ? 'error' : 'success'}>
                  <Input name="ios_back" value={data.ios_back} onChange={e => setData({ ...data, ios_back: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Android Background Image Url"  help={errors.android_back} validateStatus={errors.android_back ? 'error' : 'success'}>
                  <Input name="android_back" value={data.android_back} onChange={e => setData({ ...data, android_back: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="IOS Footer Image Url"  help={errors.ios_footer} validateStatus={errors.ios_footer ? 'error' : 'success'}>
                  <Input name="ios_footer" value={data.ios_footer} onChange={e => setData({ ...data, ios_footer: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Android Footer Image Url"  help={errors.android_footer} validateStatus={errors.android_footer ? 'error' : 'success'}>
                  <Input name="android_footer" value={data.android_footer} onChange={e => setData({ ...data, android_footer: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Company Id"  help={errors.companyId} validateStatus={errors.companyId ? 'error' : 'success'}>
                  <Input name="companyId" value={data.companyId} onChange={e => setData({ ...data, companyId: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Company Name"  help={errors.companyName} validateStatus={errors.companyName ? 'error' : 'success'}>
                  <Input name="companyName" value={data.companyName} onChange={e => setData({ ...data, companyName: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK1 Text"  help={errors.kvkk1_name} validateStatus={errors.kvkk1_name ? 'error' : 'success'}>
                  <Input name="kvkk1_name" value={data.kvkk1_name} onChange={e => setData({ ...data, kvkk1_name: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK1 Url"  help={errors.kvkk1_url} validateStatus={errors.kvkk1_url ? 'error' : 'success'}>
                  <Input name="kvkk1_url" value={data.kvkk1_url} onChange={e => setData({ ...data, kvkk1_url: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="KVKK1 Visible" help={errors.kvkk1_visible} validateStatus={errors.kvkk1_visible ? 'error' : 'success'}>
                  <Switch checked={data.kvkk1_visible ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, kvkk1_visible: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="KVKK1 Title Color"  help={errors.kvkk1_title_color} validateStatus={errors.kvkk1_title_color ? 'error' : 'success'}>
                  <Input name="kvkk1_title_color" value={data.kvkk1_title_color} onChange={e => setData({ ...data, kvkk1_title_color: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK2 Text"  help={errors.kvkk2_name} validateStatus={errors.kvkk2_name ? 'error' : 'success'}>
                  <Input name="kvkk2_name" value={data.kvkk2_name} onChange={e => setData({ ...data, kvkk2_name: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK2 Url"  help={errors.kvkk2_url} validateStatus={errors.kvkk2_url ? 'error' : 'success'}>
                  <Input name="kvkk2_url" value={data.kvkk2_url} onChange={e => setData({ ...data, kvkk2_url: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="KVKK2 Visible" help={errors.kvkk2_visible} validateStatus={errors.kvkk2_visible ? 'error' : 'success'}>
                  <Switch checked={data.kvkk2_visible ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, kvkk2_visible: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="KVKK2 Title Color"  help={errors.kvkk2_title_color} validateStatus={errors.kvkk2_title_color ? 'error' : 'success'}>
                  <Input name="kvkk2_title_color" value={data.kvkk2_title_color} onChange={e => setData({ ...data, kvkk2_title_color: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>



            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK3 Text"  help={errors.kvkk3_name} validateStatus={errors.kvkk3_name ? 'error' : 'success'}>
                  <Input name="kvkk3_name" value={data.kvkk3_name} onChange={e => setData({ ...data, kvkk3_name: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="KVKK3 Url"  help={errors.kvkk3_url} validateStatus={errors.kvkk3_url ? 'error' : 'success'}>
                  <Input name="kvkk3_url" value={data.kvkk3_url} onChange={e => setData({ ...data, kvkk3_url: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item label="KVKK3 Visible" help={errors.kvkk3_visible} validateStatus={errors.kvkk3_visible ? 'error' : 'success'}>
                  <Switch checked={data.kvkk3_visible ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, kvkk3_visible: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="KVKK3 Title Color"  help={errors.kvkk3_title_color} validateStatus={errors.kvkk3_title_color ? 'error' : 'success'}>
                  <Input name="kvkk3_title_color" value={data.kvkk3_title_color} onChange={e => setData({ ...data, kvkk3_title_color: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default LoginSetting;
