import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Button, Card, message , Alert} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { ImageUrlMulti, MultipleLangPicker} from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback } from "react";


const PhotoAlbumItemDetailMulti = (props) => {
  let params = useParams()
  let history = useHistory()
  let photoAlbum=params.id !== "add" ? params.id : false;
  let id = params.subid !== "add" ? params.subid : false;

  let [modules] = useGlobal("modules");
  let [langCode] = useGlobal("langCode");
  let [langCms] = useGlobal("langCms");
  let [languagesCms] = useGlobal("languagesCms"); 

  /*let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }*/
  
  let newRecord = {
    langs: [{ lang: langCode ? langCode : langCms? langCms[0].code : "", title: "" }],    
    medias:[],
    mediasThumbs:[],
    order: 0,
    categoryId: params.id,
    type:'image',
    title:'-',
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false)

  //onetime run
  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
    }
  }, [])

  let get = async () => {
    if(id) {
      await api.get("/rest/photoAlbumItems/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {

      setLoading(false)
    }
  }

  let validate = useCallback(() => {
    let errors = {};

    if (data.url === null)
      errors.url = languagesCms.ENFORCED

    if (data.order === null)
      errors.order = languagesCms.ENFORCED
   
    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);
  
  
  let save = async () => {
    if (data.langs[0].lang === "" || data.langs[0].title === "") {
      message.error('Lütfen dil seçimini veya dile bağlı başlığı giriniz.')
      return false
    }
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else{
      // if(id) {
      //   api.put("/rest/photoAlbumItems/" + id, data).then(({ data: { result, result_message } }) => {
      //     if (result_message.type === 'success') {
      //       message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
      //       history.push("/photoAlbum/detail/"+photoAlbum)
      //     }
      //     else
      //       message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      //   })
      // } else {
  

      data["medias"].forEach(item => {
        let newData = {
          langs: data["langs"],
          url: item.url,
          thumb: item.thumb,
          order: 0,
          categoryId: params.id,
          type:'image'
        }
       
      api.post("/rest/photoAlbumItems", newData).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          } else {
            message.error(result_message.message, 2);
          }
        })
      });


      history.push("/photoAlbum/detail/"+photoAlbum)
      return;

      
      // }  
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{languagesCms.ADD_MULTI}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/photoAlbum/detail/"+photoAlbum}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col span={12}>
                <Form.Item label={languagesCms.PICTURES} required help={errors.url} validateStatus={errors.url ? 'error' : 'success'}>
                  {/* <ImageUrl record={data} setRecord={setData} name="url" /> */}
                  <ImageUrlMulti name='medias' title='Medyalar' type='image' multi={true} ext={['image']} record={data} setRecord={setData} />
                  <Alert  message={languagesCms.PHOTO_SIZE_MESSAGE} banner />
                </Form.Item>
              </Col>
            
            </Row>

            <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <MultipleLangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms}  title={'title'}  record={data} setRecord={setData} name="lang" />
                </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};



export default PhotoAlbumItemDetailMulti;
