import React from 'react';
import { useGlobal } from "reactn";
import { Avatar, Dropdown, Menu } from 'antd';

import { Link } from 'react-router-dom';
import { SettingOutlined, FileSearchOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { env } from '../app.config';

const HeaderBar = (props) => {

  let [, setToken] = useGlobal("token");
  let [, setModules] = useGlobal("modules");
  let [user,] = useGlobal("user");
  let [languagesCms] = useGlobal("languagesCms");
  let [,setLangCode] = useGlobal("langCode");
  let [langCms,] = useGlobal("langCms")

  let logout = (props) => {
    Cookies.remove('token');
    window.localStorage.clear()
    setToken(null)
    setModules([])
    setLangCode(langCms[0].code)
    if (env.COMPANY === "ISBANK")
      window.location.href = env.REDIRECTURL
  }
  const menu = (
    <Menu className="head-menu">
      <Menu.Item>
        <a rel="noopener noreferrer" href="./adminInfo" > {languagesCms ? languagesCms.ADMIN_INFORMATION : null}</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout} danger>
      {languagesCms ? languagesCms.LOG_OUT : null} 
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="topMenu">
      <div>
        {user && user.role === 'superAdmin' &&
          <Link to="/Logs">
            <FileSearchOutlined className="logs"/>
          </Link>
        }
      </div>
      <div>
        {user && user.role === 'superAdmin' &&
          <Link to="/settings">
            <SettingOutlined className="settings" />
          </Link>
        }
      </div>
      

      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <div><Avatar
          onClick={(e) => e.preventDefault()}
          className="ant-dropdown-link"
          src="/images/default-avatar.svg"
        />
        </div>
      </Dropdown>
    </div>
  );
};



export default HeaderBar;
