import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, Alert, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { FileInputSingle,LangPicker} from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';


import { useCallback } from "react";

const ShakeWinItemDetail = (props) => {
  

  let params = useParams()
  let history = useHistory()
  let shakeWin = params.id !== "add" ? params.id : false;
  let id = params.subid !== "add" ? params.subid : false;
  
  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode"); 
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  let newRecord = {
    name: '',
    total:0,
    usedTotal:0,
    categoryId: params.id,
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(true);

  let [validationCheck, setValidationCheck] = useState(false)

  //onetime run

    useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])

  let get = async () => {
    if (id) {
      await api.get("/rest/shakeWinItem/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }

  let validate = useCallback(() => {
    let errors = {};

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED

      if (data.total == null || data.total.length === 0)
      errors.total = languagesCms.ENFORCED

    if (data.medias == null || data.medias.length === 0)
      errors.medias = languagesCms.ENFORCED
    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);


  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        api.put("/rest/shakeWinItem/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push("/shakeWin/detail/" + shakeWin)
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/shakeWinItem", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push("/shakeWin/detail/" + shakeWin)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };



  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/shakeWin/detail/" + shakeWin}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>
            
              <Row direction="row">
              
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.PRODUCT_COUNT} required help={errors.total} validateStatus={errors.total ? 'error' : 'success'}>
                    <Input name="total" value={data.total}   onChange={e => setData({ ...data, total: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.WON_COUNT}help={errors.usedTotal} validateStatus={errors.usedTotal ? 'error' : 'success'}>
                    <Input name="usedTotal"  disabled={true}  value={data.usedTotal}   onChange={e => setData({ ...data, usedTotal: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.LANG}>
                    <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.MEDIA} required help={errors.medias} validateStatus={errors.medias ? 'error' : 'success'}>
                    <FileInputSingle languagesCms={languagesCms} record={data} setRecord={setData} name="medias" />
                    <Alert  message={languagesCms.AVATAR_PIXEL} banner />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
      </div>
    </div>
  );
};



export default ShakeWinItemDetail;
