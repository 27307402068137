import React, { useState, useEffect } from 'react';
import { Input, Form, Button, Select, List, Switch, Typography, Modal } from 'antd';
import { Pie } from 'react-chartjs-2';
import axios from 'axios'

let host = process.env.API
let PersonalityInventoryInput = props => {
	let { question, setQuestion, disabled } = props;
	let surveyId = props.surveyId;
	let languagesCms = props.languagesCms;
	let [questionChart, setQuestionChart] = useState(null);
	let setField = field => value => {
		setQuestion(
			{
				...question,
				[field]: value
			});
	};

	let setChoiceField = i => field => value => {
		setField('choices')(
			question.choices.map((c, _i) => i === _i ? { ...c, [field]: value } : c)
		);
	};

	let addChoice = () => {
		setField('choices')(
			[...question.choices, {
				name: '',
				is_other: false,
				is_selected: false,
				other: ''
			}]
		);
	};

	let deleteChoice = i => {
		setField('choices')(
			question.choices.filter((c, _i) => _i !== i)
		);
	};

	return <div style={{ border: '1px solid #ccc', borderRadius: 5, width: 500, padding: 10 }}>
		<Button style={{ float: 'right', zIndex: 10 }} type="danger" disabled={disabled}
			onClick={props.delete}>{languagesCms.DELETE_QUESTION}</Button>

		<Button type="primary" onClick={() => setQuestionChart(question)}> {languagesCms.REPORT} </Button>

		<ChartModal record={questionChart} setRecord={setQuestionChart} surveyId ={surveyId}/>

		<Form.Item label={languagesCms.QUESTION_TYPE} required={true}>
			<Select value={question.question_type} onChange={setField('question_type')} disabled={disabled}>
				<Select.Option value="entertaining">Eğlendirici</Select.Option>
				<Select.Option value="resourceful">Becerikli</Select.Option>
				<Select.Option value="adventurous">Maceracı</Select.Option>
				<Select.Option value="entrepreneur">Girişimci</Select.Option>
				<Select.Option value="manager">Yönetici</Select.Option>
			</Select>
		</Form.Item>
		<Form.Item>
			<Typography.Text strong>{languagesCms.REQUIRED_QUESTION}</Typography.Text>
			<Switch style={{ marginLeft: 5 }} checked={question.required} disabled={disabled}
				onChange={setField('required')} />
		</Form.Item>
		<Form.Item label={languagesCms.QUESTION_TEXT} required={true}>
			<Input.TextArea value={question.name} multiple={true} style={{ width: 400, height: 100 }}
				disabled={disabled}
				onChange={e => setField('name')(e.target.value)} />
		</Form.Item>
		{question.type !== 'F' && <>
			<div style={{ borderTop: '1px solid #eee', paddingTop: 10 }}>
				<Typography.Text strong>{languagesCms.OPTIONS}</Typography.Text>
				<Button style={{ float: 'right' }} onClick={addChoice} disabled={disabled}>{languagesCms.ADD_OPTION}</Button>
			</div>
			<List>
				{question.choices.map((c, i) => 
				<List.Item key={i} style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ marginRight: 5 }}>{i + 1}.</div>
					<div> {languagesCms.OPTIONS} 
						<Input style={{ width:150 ,marginLeft:5 }} disabled={disabled}
						value={c.name} onChange={e => setChoiceField(i)('name')(e.target.value)} />
						<br/>{languagesCms.POINT}
						<Input style={{ width:150 , marginTop:15,marginLeft:25 }} disabled={disabled}
						value={c.point} onChange={e => setChoiceField(i)('point')(e.target.value)} />
					</div>
						
					
					<div style={{ marginRight: 50 }}>
						<Button type="danger" shape="circle-outline" disabled={disabled}
							onClick={() => deleteChoice(i)}>{languagesCms.DELETE}</Button>
					</div>
				</List.Item>)}
			</List>
		</>}

	</div>;
};

export default (props) => {
	let { record, setRecord, name, disabled} = props;
	let questions = record[name];
	let languagesCms = props.languagesCms;
	let [page, setPage] = useState(0);

	let addQuestion = () => {
		setRecord({
			...record,
			questions: [...(questions ? questions : []), {
				name: '',
				question: '',
				type: 'S',
				other: '',
				question_type:"",
				question_title: '',
				typeColor:"",
				is_answered: false,
				required: true,
				choices: [],
			}]
		});
		setPage(questions ? questions.length : 0);
	};



	return  <Form.Item >
		<div style={questions && questions.length > 0 ? { width: 750 } : { display: 'flex' }}>
			{questions && questions.map((q, i) =>
				<Button disabled={disabled} key={i} shape="circle" type={(i === page) ? 'primary' : 'dashed'}
					onClick={() => setPage(i)} style={{ marginRight: 5 , marginBottom: 10 }} >
					{i + 1}
				</Button>)}
			<Button type="primary" disabled={disabled} style={{ float: 'right' }}
				onClick={addQuestion}>{languagesCms.ADD_QUESTION}</Button>
		</div>

		{questions && questions.map((q, i) => i === page &&
			<PersonalityInventoryInput languagesCms={languagesCms} key={i} question={q} disabled={disabled}
			    surveyId = {record._id} 
				setQuestion={newQ => setRecord({
					...record,
					questions: questions.map((_q, _i) => _i === i ? newQ : _q)
				})}
				delete={() => setRecord({
					...record,
					questions: questions.filter((_q, _i) => _i !== i)
				})} />)}
	</Form.Item>;
};

let ChartModal = props => {

	let { record, setRecord } = props;
	let surveyId = props.surveyId;
	
	let [chartData, setChartData] = useState(null);
	let [data, setData] = useState([]);
	

	useEffect(() => {
		if (record)
			axios.get(host + `/api/surveyAnswers/chartData?id=${surveyId}&questionName=${record.name}`,{
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			}).then(({ data: { result, result_message } }) => {
					if (result.length > 0) {
						setData(result);
					}
				});
	}, [record])


	useEffect(() => {
		let labels= [];
		let datasetsData= [];

		data.forEach(element => {
			labels.push(element._id);
			datasetsData.push(element.count);
			
		});
		setChartData({
			chartData: {
				labels: labels,
				datasets: [
					{
						data: datasetsData,
						backgroundColor: [
							'rgba(0, 0, 255, 0.6)',
							'rgba(255, 64, 64, 0.6)',
							'rgba(127, 25,5 0, 0.6)',
							'rgba(255, 185, 15, 0.6)',
							'rgba(153, 102, 255, 0.6)',

							'rgba(255, 159, 64, 0.6)',
							'rgba(255, 99, 132, 0.6)',
							'rgba(139, 0, 139, 0.6)',
							'rgba(255, 140, 0, 0.6)',
							'rgba(153, 50, 204, 0.6)',

							'rgba(143, 188, 143, 0.6)',
							'rgba(121, 205, 205, 0.6)',
							'rgba(255, 20, 147, 0.6)',
							'rgba(255, 48, 48, 0.6)',
							'rgba(34, 139, 34, 0.6)',

							'rgba(139, 117, 0, 0.6)',
							'rgba(130, 130, 130, 0.6)',
							'rgba(255, 105, 180, 0.6)',
							'rgba(255, 246, 143, 0.6)',
							'rgba(154, 192, 205, 0.6)',
						]
					}
				]
			}
		});

	}, [data]);

	return (
		<div>
			<Modal title={record === null ? "" : record.name} visible={record === null ? false : true} onOk={() => setRecord(null)} onCancel={() => setRecord(null)}>

				<ChartJS chartData={chartData}></ChartJS>

			</Modal>
		</div>
	)
}

let ChartJS = props => {
	let { chartData } = props.chartData;
	return (
		<div className="chart">
			<Pie data={chartData} />
		</div>
	)
}
