import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, DatePicker, Row, Col, Select, Checkbox, Popconfirm, message } from 'antd';
import Parse from 'parse';
import { CheckOutlined, CloseOutlined, Loading3QuartersOutlined, DownloadOutlined, SearchOutlined, LeftOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';

const Place = (props) => {
  let [data, setData] = useState();
  let [totalCount, ] = useState(0);
  let [loading, setLoading] = useState(false);
  let [customization] = useGlobal('customization');
  let parseServerURL = customization.parseServerURL;
  let parseAppId = customization.parseAppId;
  let [placeData, setPlaceData] = useState();
  let [planData, setPlanData] = useState();
  let [userData, setUserData] = useState();
  let [, setReservationData] = useState();
  let [planCoordinatesData, setPlanCoordinatesData] = useState();
  let [departmentsData, setDepartmentsData] = useState();
  let [languagesCms] = useGlobal("languagesCms"); 
  let [filters, setFilters] = useState({
    departments: '',
    planCoordinates: '',
    place: '',
    plan: '',
    users: '',
    date: '',
    lunch: false,
    service: false,
  });

  Parse.initialize(parseAppId);
  Parse.serverURL = parseServerURL;

  // Veri Çekme
  let planCoordinates = Parse.Object.extend('PlanCoordinates');
  let reservation = Parse.Object.extend('Reservation');
  let plan = Parse.Object.extend('Plan');
  let place = Parse.Object.extend('Place');
  let user = Parse.Object.extend('User');
  let departments = Parse.Object.extend('Departments');

  let planCoordinatesQuery = new Parse.Query(planCoordinates);
  let reservationQuery = new Parse.Query(reservation);
  let planQuery = new Parse.Query(plan);
  let placeQuery = new Parse.Query(place);
  let userQuery = new Parse.Query(user);
  let departmentsQuery = new Parse.Query(departments);
  // Veri Çekme

  let columns = [
    {
      title: languagesCms.BUILDING,
      dataIndex: 'placeName',
      key: 'placeName',
    },
    {
      title: languagesCms.FLOOR,
      dataIndex: 'floorName',
      key: 'floorName',
      align: 'center',
    },
    {
      title: languagesCms.TABLE,
      dataIndex: 'itemId',
      key: 'itemId',
      align: 'center',
      render: (itemId) => <div>{planCoordinatesData.find(p => p.id === itemId) ? planCoordinatesData.find(p => p.id === itemId).name : ""}</div>,
    },
    {
      title: 'Kullanıcı id',
      dataIndex: 'users',
      key: 'users',
      align: 'center',
      render: (users) => {
        return (
          <div>
            {users.attributes.user_id}
          </div>
        );
      },
    },
    {
      title: 'Kullanıcı Adı',
      dataIndex: 'users',
      key: 'users',
      align: 'center',
      render: (users) => {
        return (
          <div>
            {[users.attributes.name, users.attributes.lastName].filter(x => x).join(' ')}
          </div>
        );
      },
    },
    {
      title: languagesCms.SECTION,
      dataIndex: 'users',
      key: 'users',
      align: 'center',
      render: (users) => {
        return (
          <div>
            {users.attributes.position}
          </div>
        );
      },
    },
    {
      title: 'Yemekli mi ?',
      dataIndex: 'lunch',
      key: 'lunch',
      align: 'center',
      render: (lunch) => {
        return <div>{lunch ? <CheckOutlined /> : <CloseOutlined />}</div>;
      },
    },
    {
      title: 'Servis Hizmeti',
      dataIndex: 'service',
      key: 'service',
      align: 'center',
      render: (service) => {
        return <div>{service ? <CheckOutlined /> : <CloseOutlined />}</div>;
      },
    },
    {
      title: languagesCms.DATE,
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.date - b.date,
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: languagesCms.DETAIL,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id) => {
        return (
          <Space size="small">
            <Popconfirm onConfirm={() => deleteRow(id)} title={languagesCms.CHECK_DELETE} okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
              <Button type="danger" icon={<DeleteOutlined />}>{languagesCms.DELETE}</Button>
            </Popconfirm>
            <Link to={`/planReservation/${id}/`}>
              <Button> Detay <RightOutlined /> </Button>
            </Link>
          </Space>
        );
      },
    }
  ];

  const { Option } = Select;

  let deleteRow = async (item_id) => {
    let queryRes = new Parse.Query(reservation);
    queryRes.equalTo("objectId", item_id);
    let object = await queryRes.first();

    object.destroy().then((object) => {
      fullData();
    }, (error) => {
      message.error(languagesCms.ERROR_DELETE, 2);
    });
  }

  let fullData = async () => {
    // veriler parsedan çekildi
    planQuery.limit(1000000000);
    let planEdit = await planQuery.find();
    placeQuery.limit(1000000000);
    let placeEdit = await placeQuery.find();
    userQuery.limit(1000000000);
    let userEdit = await userQuery.find();
    reservationQuery.limit(1000000000);
    let reservationEdit = await reservationQuery.find(); //rezerve edilmiş koltuklar
    planCoordinatesQuery.limit(1000000000);
    let planCoordinatesEdit = await planCoordinatesQuery.find(); // tüm koltuklar
    departmentsQuery.limit(1000000000);
    departmentsQuery.ascending("order")
    let departmentsEdit = await departmentsQuery.find(); // tüm koltuklar

    // parse objesi olarak gelen veriler temize çekildi
    let plan = planEdit.map(function (p) {
      return { ...p.attributes, id: p.id };
    });

    let departments = departmentsEdit.map(function (p) {
      return { ...p.attributes, id: p.id };
    });

    let place = await placeEdit.map(function (p) {
      return { ...p.attributes, id: p.id };
    });

    let user = userEdit.map(function (p) {
      return { ...p.attributes, id: p.id };
    });

    //floorId
    let floors = await planEdit.map(function (p) {
      let [pla] = place.filter((x) => x.id === p.attributes.placeId.id);

      if (pla) {
        return {
          id: p.id,
          name: p.attributes.title,
          placeId: pla.id,
          placeName: pla.name,
        };
      } else {
        return {
          id: p.id,
          name: p.attributes.title,
          placeId: '',
          placeName: '',
        };
      }
    });

    let reservation = reservationEdit.map(function (p) {
      let [floor] = floors.filter((x) => x.id === p.attributes.floorId);
      let [selectedUser] = user.filter(
        (x) => x.user_id === p.attributes.userId,
      );

      return {
        ...p.attributes,
        id: p.id,
        floorName: floor.name,
        placeId: floor.placeId,
        placeName: floor.placeName,
        user_id: selectedUser.user_id,
        department: selectedUser.position,
        date: moment(p.attributes.date).format('YYYY-MM-DD'),
      };
    });

    let planCoordinates = planCoordinatesEdit.map(function (p) {
      return { ...p.attributes, id: p.id };
    });

    setPlaceData(place);
    setPlanData(plan);
    setUserData(user);
    setReservationData(reservation);
    setPlanCoordinatesData(planCoordinates);
    setData(reservation);
    setDepartmentsData(departments);
    setLoading(false);

    return reservation;
  };

  useEffect(() => {
    setLoading(true);
    fullData();
  }, []);

  let placeChange = (e) => {
    setFilters({ ...filters, place: e });
  };

  let departmentsChange = async (e) => {
    setFilters({ ...filters, departments: e });
  };

  let planChange = (e) => {
    setFilters({ ...filters, plan: e });
  };

  let userChange = (e) => {
    setFilters({ ...filters, users: e });
  };

  let dateChange = (e) => {
    setFilters({ ...filters, date: e });
  };

  let lunchChange = (e) => {
    setFilters({ ...filters, lunch: e.target.checked });
  };

  let serviceChange = (e) => {
    setFilters({ ...filters, service: e.target.checked });
  };

  let planCoordinatesChange = (e) => {
    setFilters({ ...filters, planCoordinates: e });
  };

  let applyFilters = async () => {
    setLoading(true);
    let result = await fullData();

    if (filters.place && filters.place.length) {
      result = result.filter((o1) =>
        filters.place.some((o2) => o1.placeId === o2),
      );
    }

    if (filters.departments && filters.departments.length) {
      let fd = []
      await departmentsQuery.get(filters.departments).then((department) => {
        if (department.attributes.name !== "Tüm Bölümler") {
          fd.push(department.attributes.name)
        }
      }, (error) => { });

      if (fd && fd.length > 0) {
        result = result.filter((o1) =>
          fd.some((o2) => o1.department === o2)
        );
      }

    }

    if (filters.plan && filters.plan.length) {
      result = result.filter((o1) =>
        filters.plan.some((o2) => o1.floorId === o2),
      );
    }

    if (filters.users && filters.users.length) {
      result = result.filter((o1) =>
        filters.users.some((o2) => o1.users.id === o2),
      );
    }

    if (filters.date) {
      result = result.filter(
        (o1) => moment(filters.date).format('YYYY-MM-DD') === o1.date,
      );
    }

    if (filters.planCoordinates && filters.planCoordinates.length) {
      result = result.filter((o1) =>
        filters.planCoordinates.some((o2) => o1.itemId === o2),
      );
    }

    if (filters.lunch) {
      result = result.filter((o1) => o1.lunch !== false);
    }

    if (filters.service) {
      result = result.filter((o1) => o1.service !== false);
    }

    setData(result);
  };

  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];
    let excelData = data;

    if (excelData) {
      for (let i in excelData) {
        if (excelData) {
          if (planCoordinatesData.find(p => p.id === excelData[i].itemId))
            var masa = planCoordinatesData.find(p => p.id === excelData[i].itemId).name;
          else
            masa = ""

          let obj = {
            Bina: excelData[i].placeName,
            Kat: excelData[i].floorName,
            Kullanıcı_id: excelData[i].users.attributes.user_id,
            Kullanıcı: [excelData[i].users.attributes.name, excelData[i].users.attributes.lastName].filter(x => x).join(' '),
            Bölüm: excelData[i].users.attributes.position,
            Yemek: excelData[i].lunch ? 'Var' : 'Yok',
            Servis: excelData[i].service ? 'Var' : 'Yok',
            Tarih: excelData[i].date,
            Masa: masa
          };

          dataTable.push(obj);
        }
      }
    }

    let sheetAreas = ['Bina', 'Kat', 'Masa', 'Tarih', 'Kullanıcı_id', 'Kullanıcı', 'Bölüm', 'Yemek', 'Servis'];
    option.fileName = languagesCms.REPORT;
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: sheetAreas,
        sheetHeader: [languagesCms.BUILDING, languagesCms.FLOOR, languagesCms.TABLE, languagesCms.DATE, '_id', languagesCms.USER, languagesCms.SECTION, 'Yemek', 'Servis'],
      },
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

  };

  let KVKKdownloadExcel = async () => {
    var option = {};
    let dataTable = [];

    userQuery.limit(1000000000);
    userQuery.equalTo("isKVKKAplied", true);
    userQuery.ascending("name")

    await userQuery.find().then(function (results) {
      results.forEach((item, key) => {
        let user = item.attributes

        let obj = {
          Kullanıcı_id: user.user_id,
          Kullanıcı: [user.name, user.lastName].filter(x => x).join(' '),
          Bölüm: user.position,
          KVKK: user.isKVKKAplied ? "Onayladı" : "Onaylamadı"
        };

        dataTable.push(obj);
      })
    }).catch(function (error) { });

    let sheetAreas = ['Kullanıcı_id', 'Kullanıcı', languagesCms.SECTION, "KVKK"];
    option.fileName = 'KVKKRapor';
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: sheetAreas,
        sheetHeader: ['_id', languagesCms.USER, languagesCms.SECTION, "KVKK"],
      },
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Rezervasyon Raporu</h1>
        </div>
        <div className="list-buttons">
          <Button size="large" onClick={KVKKdownloadExcel} style={{ marginRight: '5px' }} icon={<DownloadOutlined />} > KVKK Export Excel </Button>
          <Button size="large" onClick={downloadExcel} style={{ marginRight: '5px' }} icon={<DownloadOutlined />} > Export Excel </Button>
          <Link to="/place">
            <Button type="light" icon={<LeftOutlined />} size="large"> GERİ </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Row gutter={{ sm: 24, md: 12, lg: 3 }} style={{ backgroundColor: '#fff', padding: '13px', marginTop: '5px' }} >
          <Col span={6}>
            <div>Binalar</div>
            <Select onChange={placeChange} maxTagCount="responsive" mode="multiple" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {placeData && placeData.map((place, i) => (
                <Option value={place.id} key={i}> {place.name} </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Katlar</div>
            <Select onChange={planChange} maxTagCount="responsive" mode="multiple" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {planData && planData.map((plan, i) => (
                <Option value={plan.id} key={i}> {plan.title} </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Masalar</div>
            <Select onChange={planCoordinatesChange} maxTagCount="responsive" mode="multiple" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {planCoordinatesData && planCoordinatesData.map((pc, i) => (
                <Option value={pc.id} key={i}> {pc.name} </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Kullanıcı id</div>
            <Select onChange={userChange} maxTagCount="responsive" mode="multiple" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {userData && userData.map((users, i) => (
                <Option value={users.id} key={i}> {users.user_id}</Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Kullanıcılar</div>
            <Select onChange={userChange} maxTagCount="responsive" mode="multiple" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {userData && userData.map((users, i) => (
                <Option value={users.id} key={i}> {users.name} {users.lastName} </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>Bölümler</div>
            <Select onChange={departmentsChange} maxTagCount="responsive" mode="single" style={{ width: '95%' }} showSearch optionFilterProp="children" >
              {departmentsData && departmentsData.map((department, i) => (
                <Option value={department.id} key={i}> {department.name} </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <div>{languagesCms.DATE}</div>
            <DatePicker onChange={dateChange} style={{ width: '95%' }} />
          </Col>
          <Col span={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }} >
            <Checkbox defaultChecked={false} onChange={lunchChange}> Yemek </Checkbox>
          </Col>
          <Col span={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }} >
            <Checkbox defaultChecked={false} onChange={serviceChange}> Servis </Checkbox>
          </Col>
          <Col span={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
            <Button type="primary" icon={<SearchOutlined />} style={{ marginRight: '5px' }} size="large" onClick={() => applyFilters()} disabled={loading} > Filtrele </Button>
          </Col>
        </Row>
        <Table dataSource={data} columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }} />
      </div>
    </div>
  );
};

export default Place;
