import React, { useState, useEffect } from 'react';
import { Upload,message, Progress, Button } from 'antd';
import { PlusOutlined, DownloadOutlined} from '@ant-design/icons';
import api from '../service/index'

export default (props) => {
	let { record, setRecord, name, pop} = props;

	let [files, setFiles] = useState([]);
	let [listFiles, setListFiles] = useState([]);
	let [, setLoading] = useState(false)
	let [uploadStart, setUploadStart] = useState(false)
	const [progress, setProgress] = useState(0);
	let languagesCms = props.languagesCms;
	let [, set_Download] = useState(false)

	useEffect(() => {
		setListFiles(convertToUploadFiles(record[name]));
		setFiles(record[name]);
	}, []);

	let startUploadingFiles = (fileList) => {
		let newList = fileList.map((item, index) => {
			return { uid: index, name: '', status: item._id ? 'done' : 'uploading', url: item.url || '' }
		});
		return newList;
	}

	let convertToUploadFiles = (fileList) => {
		let newList = fileList.map((item, index) => {
			return { uid: index, name: '', status: 'done', url: item.thumb }
		});
		return newList;
	}


	let multiDownload = (imageUrl) => {
		listFiles.forEach((item, index) => {
			imageUrl = item.url
			if (!imageUrl.length) return 0;
			fetch(imageUrl, {
			method: "GET",
			headers: {},
			})
			.then((response) => {
				let extension = imageUrl.split(".").reverse()[0]
				response.arrayBuffer().then(function (buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					`image_${index}.${extension}`
				);
				document.body.appendChild(link);
				link.click();
				});
			})
			.catch((error) => {
				console.log("Fotoğraf indirilirken bir hata oluştu")
			});
			})	
	  };


	/*let remove = item => {
		setRecord({ ...record, [name]: record[name].filter(x => x._id !== item.file.uid) });

	};*/

	let handleChange = info => {
		if (info) {
			if (!info.event && uploadStart === false) {
				if (info.file.status === "removed") {
					let restFiles = files;
					let newFiles = restFiles.filter((item) => item.thumb !== info.file.url);
					setRecord({ ...record, [name]: newFiles })
					setListFiles(convertToUploadFiles(newFiles));
					setFiles(newFiles)
				}
				else{
					const formData = new FormData();
					formData.append('files_0', info.file.originFileObj)
					if (info.file.originFileObj.size > 56844918)
						message.warning(languagesCms.WARNING_FILE_SIZE)
					else {
						setUploadStart(true)
						setListFiles(startUploadingFiles([...files, { url: "" }]));
						api.post("/api/upload", formData, {
							headers: { 'ContenType': 'multipart/form-data' },
							onUploadProgress: event => {
								const percent = Math.floor((event.loaded / event.total) * 100);
								setProgress(percent-1);
							}
						}).then(({ data: { result, result_message } }) => {
							set_Download(result[0].url ? result[0].url : result[0].thumb)
							setTimeout(() => setProgress(100), 1000);
							setTimeout(() => setProgress(0), 2000);
							if (result_message.type === 'success') {
								let restFiles = files;
								if(pop)
									restFiles = [result[0]].concat(restFiles)
								else
									restFiles.push(result[0])
								setRecord({ ...record, [name]: restFiles })

								setListFiles(convertToUploadFiles(restFiles));
							}
							setUploadStart(false)
						});
					}
				}
				
			}
			
		}
	
		setLoading(false);
	};
	
	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);
	return <>
	
		<Upload listType="picture-card" fileList={listFiles} onChange={handleChange}>
			{listFiles.length >= 20 ? null : uploadButton}
		</Upload>
		{ listFiles.length > 0 ?
		<Button type="primary" icon={<DownloadOutlined />} onClick={() => multiDownload()} size="medium" style={{ margin: "auto" }}/> :null}

		{progress > 0 ? <Progress style={{width:'60%'}} percent={progress} /> : null}

	</>
};
