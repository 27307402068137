import React, { useEffect, useState,useGlobal } from "reactn";
import { Row,Col,Form, Input, Button, Card, message,Switch} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import {SettingOutlined} from '@ant-design/icons';


import api from '../../service/index'
import { Link } from 'react-router-dom';


const WebApp = (props) => {
  let history = useHistory()
  let id = "webApp"

  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms");
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>WebApp</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Token Check" help={errors.tokenCheck} validateStatus={errors.tokenCheck ? 'error' : 'success'}>
                  <Switch checked={data.tokenCheck ? true : false} checkedChildren="True" unCheckedChildren="False" onChange={v => setData({ ...data, tokenCheck: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="mainCards1"  help={errors.mainCards1} validateStatus={errors.mainCards1 ? 'error' : 'success'}>
                  <Input name="mainCards1"  value={data.mainCards1} onChange={e => setData({ ...data, mainCards1: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="mainCards2"  help={errors.mainCards2} validateStatus={errors.mainCards2 ? 'error' : 'success'}>
                  <Input name="mainCards2"  value={data.mainCards2} onChange={e => setData({ ...data, mainCards2: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Card title={"NavbarMenu"}>
              <div className="table-wrap"  style={{ marginTop: 10 }} >
                <ul className="dragDrop">
                  {data.navbarMenu.map( (item, index) => {
                    return(
                      <li>
                        <div >
                          <SettingOutlined style={{ fontSize: 20 }} />
                          <span className="nameDrag">{item.title}</span>
                        </div>
    
                        <div className="rightButtons">
                          <Link to={"/settings/webApp/"+id+"/edit/"+index}><Button type="primary" size="medium" > {languagesCms.EDIT}</Button></Link>
                        </div>
         
                      </li>
                    )
                  })}
      
                </ul>
              </div>
                
            </Card>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default WebApp;
