import React, { useEffect, useState} from 'react';
import {Select} from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let {record, setRecord, name} = props;
	let [currency,setCurrency] = useState([]);
	let _params = {sort: "-createdAt", page: 1, pageSize: 100 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/currency?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
			let dbCurrency = data.result.rows.sort((a,b) => a.name.localeCompare(b.name))
			setCurrency(dbCurrency);
			}
		})
	}, [_params])

	return <Select value={record[name] || [] } mode='single' placeholder={languagesCms.CURRENCY}
			onChange={v => setRecord({...record, [name]: v })}>
			{(currency) && currency.map(b =>
				<Select.Option key={b._id} value={b.name} >{b.name}</Select.Option>)}
		</Select>;
};