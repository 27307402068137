import React, { useMemo, useState} from 'react';
import {Select} from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let {record, setRecord, name} = props;
	let [speaker,setGroup] = useState([]);
	let _params = {sort: "order", page: 1, pageSize: 100 };
	let languagesCms = props.languagesCms;
	useMemo(() => {
		api.get(`/rest/speakers?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result && data.result.rows.length>0){
			let dbGroups = data.result.rows.sort((a,b) => a.name.localeCompare(b.name))
			setGroup(dbGroups);
			}
		})
	}, [])

	return <Select value={record[name] || [] } mode='multiple' placeholder={languagesCms.SELECT_SPEAKER}
			onChange={v => setRecord({...record, [name]: v })}>
			{(speaker) && speaker.map(b =>
				<Select.Option key={b._id} value={b._id} >{b.name}</Select.Option>)}
		</Select>;
};