import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';


import ExportJsonExcel from 'js-export-excel';

import {
    EditOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const OpenPosition = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isSmall] = useGlobal('isSmall')
    let [langCms] = useGlobal("langCms");
    let [langCode] = useGlobal("langCode");
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }


    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }

        let restData = await api.get(`/rest/openPositions?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;

                    if (item.sendNotification)
                        item.sendNotification = <CheckOutlined/>;
                    else
                        item.sendNotification = <CloseOutlined/>;
                    if (!item.hasOwnProperty('groups')) {
                        item.groups = []
                    }
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/openPositions/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/openPositions/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let getReport = async (record) => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/openPositions?id=${record._id}&lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${record.name.replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    let getReportCV = async (record) => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/openPositionsCV?id=${record._id}&lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${record.name.replace(/ /g, '')}_CV_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }


    let downloadExcelJoin = async () => {
        var option = {};
        let dataTable = [];
        let excelData = await api.get(`/rest/openPositions?${qs.stringify({
            page: 1,
            pageSize: 10000
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                if (excelData[i].joins) {
                    for (let j in excelData[i].joins) {
                        let attendee = await api.get(`/rest/attendees/${excelData[i].joins[j].id}?${qs.stringify({
                            page: 1,
                            pageSize: 10000
                        })}`, {}).then(({data}) => {
                            return data.result;
                        });

                        let obj = {

                            'attendee': attendee ? attendee.name + " " + attendee.lastname : "Kullanıcı Bulunamadı",
                            "phone": attendee ? attendee.phone : "",
                            "email": attendee ? attendee.email : "",
                            "company": attendee ? attendee.company : "",
                            "department": attendee ? attendee.department : "",
                            "position": attendee ? attendee.position : "",
                            'name': excelData[i].name,
                            'date': moment(excelData[i].joins[j].date).format("DD-MM-YYYY"),
                            'releaseDate': moment(excelData[i].releaseDate).format("DD-MM-YYYY"),
                            'joinEndDate': moment(excelData[i].joinEndDate).format("DD-MM-YYYY"),
                        }
                        dataTable.push(obj);
                    }
                }
            }
        }

        let sheetAreas = ['name', 'releaseDate', 'joinEndDate', 'attendee', 'phone', 'email', 'company', 'department', 'position', 'date'];


        option.fileName = "Bireysel Başvurular";
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',

                sheetFilter: sheetAreas,
                sheetHeader: [languagesCms.POSITION, languagesCms.RELEASE_DATE, languagesCms.PARTICIPATION_DATE, languagesCms.APPLICANTS, languagesCms.PHONE, 'Email', languagesCms.COMPANY, languagesCms.DEPARTMENT, languagesCms.POSITION, languagesCms.APPLICATION_DATE],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    let downloadExcelOtherJoin = async () => {
        var option = {};
        let dataTable = [];
        let excelData = await api.get(`/rest/openPositions?${qs.stringify({
            page: 1,
            pageSize: 10000
        })}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                if (excelData[i].otherJoins) {
                    for (let j in excelData[i].otherJoins) {
                        let attendee = await api.get(`/rest/attendees/${excelData[i].otherJoins[j].referenceId}?${qs.stringify({
                            page: 1,
                            pageSize: 10000
                        })}`, {}).then(({data}) => {
                            return data.result;
                        });

                        let obj = {

                            'attendee': attendee ? attendee.name + " " + attendee.lastname : "Kullanıcı Bulunamadı",
                            "phone": attendee ? attendee.phone : "",
                            "email": attendee ? attendee.email : "",
                            "company": attendee ? attendee.company : "",
                            "department": attendee ? attendee.department : "",
                            "position": attendee ? attendee.position : "",
                            'name': excelData[i].name,
                            'date': moment(excelData[i].otherJoins[j].date).format("DD-MM-YYYY"),
                            'cvName': excelData[i].otherJoins[j].cvName,
                            'cvUrl': excelData[i].otherJoins[j].cvUrl,
                            'releaseDate': moment(excelData[i].releaseDate).format("DD-MM-YYYY"),
                            'joinEndDate': moment(excelData[i].joinEndDate).format("DD-MM-YYYY"),
                        }
                        dataTable.push(obj);
                    }
                }
            }
        }

        let sheetAreas = ['name', 'releaseDate', 'joinEndDate', 'attendee', 'phone', 'email', 'company', 'department', 'position', 'date', 'cvName', 'cvUrl'];


        option.fileName = "CV ile Başvurular";
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',

                sheetFilter: sheetAreas,
                sheetHeader: [languagesCms.POSITION, languagesCms.RELEASE_DATE, languagesCms.PARTICIPATION_DATE, 'Başvuran', 'Başvuran Telefonu', 'Başvuran Email', 'Başvuran Şirket', 'Başvuran Departman', 'Başvuran Ünvan', languagesCms.APPLICATION_DATE, 'CV isim', 'CV url'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }


    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
      title: languagesCms.GROUP,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (group) => group.length > 0 && group.map(e => <div style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title:languagesCms.LANG,
            dataIndex: 'lang',
            key: 'lang',
            sorter: (a, b) => a.lang - b.lang,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.NOTIFICATION,
            dataIndex: 'sendNotification',
            key: 'sendNotification',
            sorter: (a, b) => a.sendNotification - b.sendNotification,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.START_DATE,
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.releaseDate - b.releaseDate,
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
        },
        {
            title:languagesCms.END_DATE,
            dataIndex: 'joinEndDate',
            key: 'joinEndDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.joinEndDate - b.joinEndDate,
            render: (text) => moment(text).format("YYYY-MM-DD  HH:mm"),
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Button type="primary" icon={<FileExcelOutlined/>} onClick={() => getReport(record)}>
                        {isSmall ? '' : languagesCms.PERSONEL_APPLICATION}
                    </Button>
                    <Button type="primary" icon={<FileExcelOutlined/>} onClick={() => getReportCV(record)}>

                        {isSmall ? '' : languagesCms.CV_APPLICATION}
                    </Button>
                    <Link to={"/openPositions/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>

                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">

                    <Button size="large" onClick={downloadExcelJoin} style={{marginRight: "5px"}}
                            icon={
                                <DownloadOutlined/>}>{isSmall ? '' : languagesCms.PERSONEL_APPLICATION}
                    </Button>
                    <Button size="large" onClick={downloadExcelOtherJoin} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{isSmall ? 'CV' : languagesCms.CV_APPLICATION}
                    </Button>
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to="/openPositions/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_OPENPOSITION} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default OpenPosition;
