import React, { useEffect, useState, useGlobal } from "reactn";
import { Table, Space, Button, Input } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import api from '../service/index'
import { Link } from 'react-router-dom';
import qs from 'qs';
import { EditOutlined, CheckOutlined, CloseOutlined, DownloadOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import moment from "moment";

const Attendee = (props) => {
  let [data, setData] = useState([]);
  let [constData, setConstData] = useState([]);
  let [, setTotalCount] = useState(0)
  let [, setSearch] = useState(false)
  let [loading, setLoading] = useState(false);
  let [languagesCms] = useGlobal("languagesCms");
  let [modules] = useGlobal("modules");


  let query = []
  query["allow_donation"] = false;
  query["blood_donation"] = false;
  query["platelet_donation"] = false;

  /* let settings = useGlobal("settings")[0]

 if (settings) {
    settings.forEach(element => {
      if (element._id === "profileSettings") {
        profileSettings = element
      }
      if (element._id === "customization") {
        customization = element
      }
    });
  }*/

  let get = async (page, filters, sorter) => {

    let restData = await api.get(`/rest/attendees?${qs.stringify({ page: 1, pageSize: 10000 })}`,).then(({ data }) => {
      if (data.result) {
        setLoading(false)
        setTotalCount(data.result.total);
        return data.result.rows.map((item, key) => {
          if (item.active)
            item.active = <CheckOutlined />;
          else
            item.active = <CloseOutlined />;
          if (!item.hasOwnProperty('groups')) {
            item.groups = []
          }
          if (!item.deleted)
            item.deleted = false;
          else
            item.deleted = true;

          item.key = key;
          return item;
        })
      }
    });

    setConstData(restData)
    setData(restData);
  }

  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
    }
  }, [])

  let onChange = async (e) => {
    applySearch(e.target.value)
  }

  let applySearch = (text) => {

    if (text.length > 2) {
      setSearch(text)
      const val = text.toLocaleLowerCase();
      const tempList = Object.assign([], constData);
      let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
      setData(filteredData)
    } else {
      setData([...constData])
    }
  }

  const isContainsFilterValue = (t, val) => {
    const isContains2 = t.name == null ? false : t.name.toLocaleLowerCase().indexOf(val) !== -1;
    const isContains3 = t.email == null ? false : t.email.toLocaleLowerCase().indexOf(val) !== -1;
    const isContains4 = t.phone == null ? false : t.phone.toLocaleLowerCase().indexOf(val) !== -1;
    return isContains2 || isContains3 || isContains4
  }

  let turkishSort = (a, b) => {
    var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Departman',
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Durumu',
      width: "50px",
      dataIndex: 'deleted',
      key: 'deleted',
      align: 'center',
      sorter: (a, b) => a.deleted - b.deleted,
      render: (deleted) => <div className="w-100 centered">{deleted ? <div className="false-circle"></div> : <div className="true-circle"></div>}</div>
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Kayıt Tarihi',
      width: "200px",
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
      render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm")
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={"/attendees/edit/" + record._id}>
            <Button icon={<EditOutlined />}>{languagesCms && languagesCms.EDIT}</Button>
          </Link>
        </Space>
      ),
    },
  ];

  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];
    let query = []

    query["deleted"] = false;

    let excelData = await api.get(`/rest/attendees?${qs.stringify({ page: 1, pageSize: 10000, query })}`, {}).then(({ data }) => {
      return data.result.rows;
    });

    if (excelData) {
      for (let i in excelData) {

        let obj = {
          'id': excelData[i]._id,
          'role': excelData[i].role,
          'name': excelData[i].name,
          'lastname': excelData[i].lastname,
          'phone': excelData[i].phone,
          'email': excelData[i].email,
          'city': excelData[i].location,
          'birthday': excelData[i].birthday,
          'position': excelData[i].position,
          'deleted': excelData[i].deleted ? 'Silindi' : 'Aktif',

        }

        //Ek alanlar 
        obj.company = excelData[i].company;

        obj.position = excelData[i].position;

        obj.department = excelData[i].department;



        dataTable.push(obj);
      }
    }

    let sheetAreas = ['id', 'role', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'deleted'];

    //Ek alanlar 
    sheetAreas.push("company");

    sheetAreas.push("position");

    sheetAreas.push("department");


    option.fileName = 'UserList'
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['id', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'position', 'deleted'],
        sheetHeader: ['id', 'name', 'lastname', 'phone', 'email', 'city', 'birthday', 'position', 'deleted'],

      }
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }


  return (
    <div>
      <div className="list-head">
        <div className="list-buttons">
          {/* <Button type="primary" icon={<FileMarkdownOutlined />} size="large" style={{ marginRight: "5px" }} onClick={() => getReport()}>Giriş Logları İndir</Button> */}

          <Button size="large" onClick={downloadExcel} style={{ marginRight: "5px" }} icon={<DownloadOutlined />}>Export Excel</Button>


        </div>
      </div>

      <div className="table-wrap">
        <Input size="large" placeholder="İsim Ara" onChange={onChange} />
        <Table dataSource={data} columns={columns} loading={{ spinning: loading, indicator: <Loading3QuartersOutlined spin />, size: "medium" }} />
      </div>

    </div>
  );
};



export default Attendee;
