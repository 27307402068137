import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Input, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    DeleteOutlined,
    LeftOutlined,
    FileTextOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const SurveyAnswers = (props) => {
    let params = useParams()
    let query = []
    let id = params.id !== "add" ? params.id : false;
    query["survey_id"] = id;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [isSmall, ] = useGlobal('isSmall')

    let [search, setSearch] = useState(false)
    const {Search} = Input;
    let [loading, setLoading] = useState(false)

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }


    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-created';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";

            let restData = await api.get(`/rest/surveyAnswers/getNameAnswer?${qs.stringify(_params)}`, {_params}).then(({data}) => {
                if (data.result) {
                    setLoading(false)
                    setTotalCount(data.result.length);
                    return data.result.map((item, key) => {
                        if (item.active)
                            item.active = <CheckOutlined/>;
                        else
                            item.active = <CloseOutlined/>;

                        item.key = key;

                        return item;
                    })
                }
            });
            setData(restData);
        } else {
            let restData = await api.get(`/rest/surveyAnswers?${qs.stringify(_params)}`, {_params}).then(({data}) => {
                if (data.result) {
                    setLoading(false)
                    setTotalCount(data.result.total);
                    return data.result.rows.map((item, key) => {
                        if (!item.attendee_id) {
                            item.attendee_id = null
                        }
                        if (item.active)
                            item.active = <CheckOutlined/>;
                        else
                            item.active = <CloseOutlined/>;
                        item.key = key;
                        return item;
                    })
                }

            });
            if (restData.length > 0) {
                let datas = restData.filter((z) => z.attendee_id !== null && z.attendee_id.deleted !== true)
                setData(datas);
            }

        }

    }

    useEffect(() => {
        get();
    }, [module]);


    useEffect(() => {
        if (modules) {
            setLoading(true)
        }
    }, [])

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/surveyAnswers/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/surveyAnswers/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title:languagesCms.USER,
            dataIndex: 'attendee_id',
            key: 'attendee_id',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend'],
            render: (user) => user && [user.name, user.lastname].filter(x => x).join(' ')
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/survey/" + id + "/detail/" + record._id}>
                        <Button icon={<FileTextOutlined/>}>
                            {isSmall ? '' : languagesCms.VIEW}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to={"/survey"}>
                        <Button type="light" icon={<LeftOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default SurveyAnswers;
