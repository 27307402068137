import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row,Col,Form, Input, Button, Card, message ,InputNumber,Switch} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../service/index'
import { Link } from 'react-router-dom';


const SubTopicDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let topic=params.id !== "add" ? params.id : false;
  let id = params.subid !== "add" ? params.subid : false;
  let [data, setData] = useState({parent: params.id})
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms");
  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
    useEffect(() => {
      if (modules) {
          setLoading(true)
          get();
      }
  }, [])

  let get = async () => {
    if(id) {
      await api.get("/rest/topicquestions/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
    if(!data.active){
      data.active=false
    }
      if(id) {
        api.put("/rest/topicquestions/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/topics/detail/'+topic)
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        
        api.post("/rest/topicquestions", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/topics/detail/'+topic)
          } else {
            message.error(result_message.message, 2);
          }
        })
      }  
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to={"/topics/detail/"+topic}>
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={languagesCms.QUESTION} help={errors.question} validateStatus={errors.question ? 'error' : 'success'}>
                  <Input name="question" value={data.question} onChange={e => setData({ ...data, question: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.FAKE_LIKE_COUNT} help={errors.fakeLikeCount} validateStatus={errors.fakeLikeCount ? 'error' : 'success'}>
                    <InputNumber value={data.fakeLikeCount} min={0} defaultValue={0} style={{width: 100}} onChange={v => setData({ ...data, fakeLikeCount: v })} />
	              </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.ACTIVE} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                    <Switch  checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                  </Form.Item>
                </Col>
            </Row>

            
            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
      </div>
    </div>
  );
};



export default SubTopicDetail;
