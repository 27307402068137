import React, { useEffect, useState,useGlobal } from "reactn";
import { Row,Col,Form, Input, Button, Card, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../../service/index'
import { Link } from 'react-router-dom';

const TopRightModule = (props) => {
  let history = useHistory()
  let id = "topRightShareButton"

  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Top Right Share Button</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Module Name"  help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                  <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Module Type"  help={errors.type} validateStatus={errors.type ? 'error' : 'success'}>
                  <Input name="type" value={data.type} onChange={e => setData({ ...data, type: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>



            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="IOS Icon Url"  help={errors.iosIcon} validateStatus={errors.iosIcon ? 'error' : 'success'}>
                  <Input name="iosIcon" value={data.iosIcon} onChange={e => setData({ ...data, iosIcon: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Android Icon Url"  help={errors.androidIcon} validateStatus={errors.androidIcon ? 'error' : 'success'}>
                  <Input name="androidIcon" value={data.androidIcon} onChange={e => setData({ ...data, androidIcon: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Text Color"  help={errors.textColor} validateStatus={errors.textColor ? 'error' : 'success'}>
                  <Input name="textColor" value={data.textColor} onChange={e => setData({ ...data, textColor: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Background Color"  help={errors.backgroundColor} validateStatus={errors.backgroundColor ? 'error' : 'success'}>
                  <Input name="backgroundColor" value={data.backgroundColor} onChange={e => setData({ ...data, backgroundColor: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Version Number"  help={errors.version} validateStatus={errors.version ? 'error' : 'success'}>
                  <Input name="version" value={data.version} onChange={e => setData({ ...data, version: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>
            
            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default TopRightModule;
