import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Button, Input} from 'antd';
import api from '../service/index'
import qs from 'qs';
import moment from 'moment'

import {
    CheckOutlined,
    CloseOutlined,
    FileMarkdownOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const Logs = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms");
    let [langCode] = useGlobal("langCode");
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-date';
        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "attendee,userName,url,method,ip";
        }
        let restData = await api.get(`/rest/requestLogCms?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);


    let getReport = async () => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/requestLogCms?lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${ moment().format("DD-MM-YYYY")}-Günlük.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    let columns = [
        {
            title: languagesCms.USER,
            dataIndex: 'userName',
            key: 'userName',
            sorter: (a, b) => a.userName - b.userName,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Method",
            dataIndex: 'method',
            key: 'method',
            sorter: (a, b) => a.method - b.method,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.DATE,
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            sortDirections: ['descend', 'ascend']
        }, 
        {
            title: languagesCms.DELETED_CONTENT_NAME,
            dataIndex: 'deleted',
            key: 'deleted',
            sorter: (a, b) => a.deleted - b.deleted,
            sortDirections: ['descend', 'ascend']
        }, 
        {
            title: languagesCms.MODULE,
            dataIndex: 'contentModule',
            key: 'contentModule',
            sorter: (a, b) => a.contentModule - b.contentModule,
            sortDirections: ['descend', 'ascend']
        }, 
        {
            title: languagesCms.IP_ADDRESS,
            dataIndex: 'ip',
            key: 'ip',
            sorter: (a, b) => a.ip - b.ip,
            sortDirections: ['descend', 'ascend']
        }
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : languagesCms.USER_LOGS}</h1>
                </div>
                <div className="list-buttons">
                    <Button type="primary" icon={<FileMarkdownOutlined/>} size="large" style={{marginRight: "5px"}}
                            onClick={() => getReport()}>{isSmall ? '' : languagesCms.EXPORT}</Button>
                    
                </div>
            </div>

            <div className="table-wrap">
                <Search type="light" placeholder={languagesCms.LOGS_SEARCH} onChange={onChange}
                        onSearch={(v) => {
                            setSearch(v);
                            get()
                        }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       />
            </div>

        </div>
    );
};


export default Logs;