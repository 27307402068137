import React, { useState, useMemo } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name, mode, lang } = props;
	let [types, setTypes] = useState([]);
	let _params = { sort: "-createdAt", page: 1, pageSize: 100, lang: lang };
	let languagesCms = props.languagesCms;

	useMemo(() => {
		api.get(`/rest/secondhandEstateTypes?${qs.stringify(_params)}`).then(({ data }) => {
			if (data && data.result && data.result.rows.length > 0) {
				let dbTypes = data.result.rows.sort((a, b) => a.name.localeCompare(b.name))
				setTypes(dbTypes);
			}
		})
	}, [])

	return <Select value={record[name] || []} mode={mode} placeholder={languagesCms.SELECT_ESTATE_TYPE}
		onChange={v => setRecord({ ...record, [name]: v })}>
		{(types) && types.map(b =>
			<Select.Option key={b.name} value={b.name} >{b.title}</Select.Option>)}
	</Select>
};