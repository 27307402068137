import React, { useEffect, useState,useGlobal } from "reactn";

import { Row,Col,Form, Input, Button, Card, message ,InputNumber,Switch} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";


import api from '../../service/index'
import { Link } from 'react-router-dom';
import TextArea from "antd/lib/input/TextArea";

const Version = (props) => {
  let history = useHistory()
  let id = "version"

  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Version</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Update Url"  help={errors.update_url} validateStatus={errors.update_url ? 'error' : 'success'}>
                  <Input name="update_url"  value={data.update_url} onChange={e => setData({ ...data, update_url: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="IOS Version Number"  help={errors.ios_version} validateStatus={errors.ios_version ? 'error' : 'success'}>
                  <Input name="ios_version" value={data.ios_version} onChange={e => setData({ ...data, ios_version: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Android Version Number"  help={errors.android_version} validateStatus={errors.android_version ? 'error' : 'success'}>
                  <Input name="android_version" value={data.android_version} onChange={e => setData({ ...data, android_version: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Huawei Version Number"  help={errors.huawei_version} validateStatus={errors.huawei_version ? 'error' : 'success'}>
                  <Input name="huawei_version" value={data.huawei_version} onChange={e => setData({ ...data, huawei_version: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="IOS "  help={errors.ios} validateStatus={errors.ios ? 'error' : 'success'}>
                  <Switch checked={data.ios ? true : false} checkedChildren="True" unCheckedChildren="False" onChange={v => setData({ ...data, ios: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Android"  help={errors.Android} validateStatus={errors.Android ? 'error' : 'success'}>
                  <Switch checked={data.Android ? true : false} checkedChildren="True" unCheckedChildren="False" onChange={v => setData({ ...data, Android: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Huawei"  help={errors.Huawei} validateStatus={errors.Huawei ? 'error' : 'success'}>
                  <Switch checked={data.Huawei ? true : false} checkedChildren="True" unCheckedChildren="False" onChange={v => setData({ ...data, Huawei: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="IOS Update Url"  help={errors.ios_update_url} validateStatus={errors.ios_update_url ? 'error' : 'success'}>
                  <Input name="ios_update_url" value={data.ios_update_url} onChange={e => setData({ ...data, ios_update_url: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Android Update Url"  help={errors.android_update_url} validateStatus={errors.android_update_url ? 'error' : 'success'}>
                  <Input name="android_update_url" value={data.android_update_url} onChange={e => setData({ ...data, android_update_url: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="Huawei Update Url"  help={errors.huawei_update_url} validateStatus={errors.huawei_update_url ? 'error' : 'success'}>
                  <Input name="huawei_update_url" value={data.huawei_update_url} onChange={e => setData({ ...data, huawei_update_url: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Is Login Required"  help={errors.is_login_required} validateStatus={errors.is_login_required ? 'error' : 'success'}>
                  <InputNumber name="is_login_required" value={data.is_login_required} onChange={v => setData({ ...data, is_login_required: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Relase Version"  help={errors.relase_version} validateStatus={errors.relase_version ? 'error' : 'success'}>
                  <InputNumber name="relase_version" value={data.relase_version} onChange={v => setData({ ...data, relase_version: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <i><b><p style={{"text-align":"center"}}>----- Uygulama Kapama -----</p></b></i>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Uygulama Kapansın"  help={errors.closeApp} validateStatus={errors.closeApp ? 'error' : 'success'}>
                  <Switch checked={data.closeApp ? true : false} checkedChildren="True" unCheckedChildren="False" onChange={v => setData({ ...data, closeApp: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item label="Uyarı Metni"  help={errors.appCloseText} validateStatus={errors.appCloseText ? 'error' : 'success'}>
                  <TextArea name="appCloseText" value={data.appCloseText} onChange={e => setData({ ...data, appCloseText: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default Version;
