import React, { useEffect, useState,useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row,Col,Form, Input, Button, Card, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import api from '../../service/index'
import { Link } from 'react-router-dom';

const ShakeWinMessagesDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;

  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms");
  //onetime run
  useEffect(() => {
    
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api.get("/rest/shakeWinMessage/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    } else {

      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/shakeWinMessage/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/shakeWinMessages')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/shakeWinMessage", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/shakeWinMessages')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Salla Kazan Mesajları</h1>
        </div>
        <div className="list-buttons">
          <Link to="/shakeWinMessages">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.LANG} help={errors.lang} validateStatus={errors.version ? 'error' : 'success'}>
                    <Input name="lang" value={data.lang} onChange={e => setData({ ...data, lang: e.target.value })} />
                  </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.CODE} help={errors.code} validateStatus={errors.code ? 'error' : 'success'}>
                    <Input name="code" value={data.code} onChange={e => setData({ ...data, code: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="Title" help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                    <Input name="title" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.MESSAGE} required help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
                    <Input.TextArea name="content" value={data.content} onChange={v => setData({ ...data, content: v.target.value })} />
                  </Form.Item>
                </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default ShakeWinMessagesDetail;
