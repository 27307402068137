import React, { useEffect, useState,useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Card, message} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../../service/index'
import { Link } from 'react-router-dom';

const SmsAndEmailMessageDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id
  let subId = params.subId
  let name = params.name

  let [messages, setMessages] = useState({});
  let [mailMessages, setMailMessages] = useState({});
  let [cmsMessages, setCmsMessages] = useState({});

  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if (id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        if (name === "messages") {
          setData(result.messages[subId]);
          setMessages(result)
        }
        else if (name === "mailMessages") {
          setData(result.mailMessages[subId]);
          setMailMessages(result)
        }
        else if (name === "cmsMessages") {
          setData(result.cmsMessages[subId]);
          setCmsMessages(result)
        }
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }

  let save = async () => {
    if (id) {
      let items
      if (name === "messages") {
        messages.messages[subId] = data
        items = messages
      }
      else if (name === "mailMessages") {
        mailMessages.mailMessages[subId] = data
        items = mailMessages
      }
      else if (name === "cmsMessages") {
        cmsMessages.cmsMessages[subId] = data
        items = cmsMessages
      }
      api.put("/rest/settings/" + id, items).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
          history.push('/settings/smsAndEmailMessage')
        }
        else
          message.error(languagesCms.ERROR_SAVE_RECORD, 2);
      })
    } else {
      api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
          history.push('/settings/smsAndEmailMessage')
        } else {
          message.error(result_message.message, 2);
        }
      })
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Sms And Email Message</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings/smsAndEmailMessage">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label="id" help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                    <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.LANG} help={errors.lang} validateStatus={errors.lang ? 'error' : 'success'}>
                    <Input name="lang" value={data.lang} onChange={e => setData({ ...data, lang: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="Title" help={errors.title} validateStatus={errors.title ? 'error' : 'success'}>
                    <Input name="title" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                  </Form.Item>
                </Col>

              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.MESSAGE} required help={errors.content} validateStatus={errors.content ? 'error' : 'success'}>
                    <Input.TextArea name="content" value={data.content} onChange={v => setData({ ...data, content: v.target.value })} />
                  </Form.Item>
                </Col> 
              </Row>

              { name === "messages" &&
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <Form.Item label="Mesaj (Giriş : Telefon)" required help={errors.contentPhone} validateStatus={errors.contentPhone ? 'error' : 'success'}>
                      <Input.TextArea name="contentPhone" value={data.contentPhone} onChange={v => setData({ ...data, contentPhone: v.target.value })} />
                    </Form.Item>
                  </Col> 
                </Row>
              }
             

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default SmsAndEmailMessageDetail;
