import React, { useEffect, useState,useGlobal } from "reactn";

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Card,
  message,
  Alert,
  Tabs,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';

const { TabPane } = Tabs;
const { Title } = Typography;

const Customization = (props) => {
  let history = useHistory()
  let id = "customization"
  let [languagesCms] = useGlobal("languagesCms"); 
  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [, setLogo] = useGlobal("logo");
  let [, setCustomization] = useGlobal("customization");
  let [, setSettings] = useGlobal("settings");

  //onetime run
  useEffect(() => {
    get();
  }, []);



  let get = async () => {
    if (id) {
      await api
        .get('/rest/settings/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  let save = async () => {
    if (id) {
      api.put('/rest/settings/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
              setSettings(data.result.rows);
              if (data.result.rows) {
                data.result.rows.forEach(element => {
                  if (element._id === "customization") {
                    setCustomization(element)
                    localStorage.setItem("customization", JSON.stringify(element));
                    setLogo(element.cmsLogo)
                    localStorage.setItem("logo", JSON.stringify(element.cmsLogo));
                  }
                });
              }
              localStorage.setItem("settings", JSON.stringify(data.result.rows));
            })
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings');
          } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        });
    } else {
      api
        .post('/rest/settings', data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        });
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Customization</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">
              GERİ
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading && (
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    label="id"
                    help={errors._id}
                    validateStatus={errors._id ? 'error' : 'success'}
                  >
                    <Input
                      name="_id"
                      disabled={true}
                      value={data._id}
                      onChange={(e) =>
                        setData({ ...data, _id: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Tabs defaultActiveKey="1" centered>
                <TabPane tab={languagesCms.GENERAL_VIEW} key="1">
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label={languagesCms.SMARTEVENT_VIEW}
                        help={errors.isSmarteventView}
                        validateStatus={
                          errors.isSmarteventView ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.isSmarteventView ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, isSmarteventView: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label={languagesCms.SHOW_NAME}
                        help={errors.isShow}
                        validateStatus={errors.isShow ? 'error' : 'success'}
                      >
                        <Switch
                          checked={data.isShow ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) => setData({ ...data, isShow: v })}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label="Take screenshots and video"
                        help={errors.takeScreenshotsVideo}
                        validateStatus={errors.takeScreenshotsVideo ? 'error' : 'success'}
                      >
                        <Switch
                          checked={data.takeScreenshotsVideo ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) => setData({ ...data, takeScreenshotsVideo: v })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label={languagesCms.SUPPORTED_LANG}
                        help={errors.enforced_lang}
                        validateStatus={
                          errors.enforced_lang ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="enforced_lang"
                          value={data.enforced_lang}
                          onChange={(e) =>
                            setData({ ...data, enforced_lang: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                      <Form.Item
                        label="Bottom Bar Icon Font Size"
                        help={errors.bottomBarIconFontSize}
                        validateStatus={
                          errors.bottomBarIconFontSize ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="bottomBarIconFontSize"
                          value={data.bottomBarIconFontSize}
                          onChange={(e) =>
                            setData({
                              ...data,
                              bottomBarIconFontSize: e.target.value,
                            })
                          }
                        />
                        <Alert
                          type="info"
                          message={languagesCms.MUST_BE_INT}
                          banner
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="App Color"
                        help={errors.color}
                        validateStatus={errors.color ? 'error' : 'success'}
                      >
                        <Input
                          name="color"
                          value={data.color}
                          onChange={(e) =>
                            setData({ ...data, color: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Chat Second Color"
                        help={errors.secondColor}
                        validateStatus={
                          errors.secondColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="secondColor"
                          value={data.secondColor}
                          onChange={(e) =>
                            setData({ ...data, secondColor: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Home Text Color"
                        help={errors.homeTextColor}
                        validateStatus={
                          errors.homeTextColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="homeTextColor"
                          value={data.homeTextColor}
                          onChange={(e) =>
                            setData({ ...data, homeTextColor: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Home Header Color"
                        help={errors.homeHeaderColor}
                        validateStatus={
                          errors.homeHeaderColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="homeHeaderColor"
                          value={data.homeHeaderColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              homeHeaderColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Title Color"
                        help={errors.title_color}
                        validateStatus={
                          errors.title_color ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="title_color"
                          value={data.title_color}
                          onChange={(e) =>
                            setData({ ...data, title_color: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Bottom Sheet Color"
                        help={errors.bottom_sheet}
                        validateStatus={
                          errors.bottom_sheet ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="bottom_sheet"
                          value={data.bottom_sheet}
                          onChange={(e) =>
                            setData({ ...data, bottom_sheet: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Bottom Sheet Text Color"
                        help={errors.bottomSheetTextColor}
                        validateStatus={
                          errors.bottomSheetTextColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="bottomSheetTextColor"
                          value={data.bottomSheetTextColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              bottomSheetTextColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Background
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="IOS Home Background Image Url"
                        help={errors.iosHomeBackgroundImageUrl}
                        validateStatus={
                          errors.iosHomeBackgroundImageUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="iosHomeBackgroundImageUrl"
                          value={data.iosHomeBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              iosHomeBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Android Home Background Image Url"
                        help={errors.androidHomeBackgroundImageUrl}
                        validateStatus={
                          errors.androidHomeBackgroundImageUrl
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="androidHomeBackgroundImageUrl"
                          value={data.androidHomeBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              androidHomeBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="IOS Profil Background Image Url"
                        help={errors.iosProfilBackgroundImageUrl}
                        validateStatus={
                          errors.iosProfilBackgroundImageUrl
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="iosProfilBackgroundImageUrl"
                          value={data.iosProfilBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              iosProfilBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Android Profil Background Image Url"
                        help={errors.androidProfilBackgroundImageUrl}
                        validateStatus={
                          errors.androidProfilBackgroundImageUrl
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="androidProfilBackgroundImageUrl"
                          value={data.androidProfilBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              androidProfilBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="IOS Search Background ImageUrl"
                        help={errors.iosSearchBackgroundImageUrl}
                        validateStatus={
                          errors.iosSearchBackgroundImageUrl
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="iosSearchBackgroundImageUrl"
                          value={data.iosSearchBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              iosSearchBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Android Search Background Image Url"
                        help={errors.androidSearchBackgroundImageUrl}
                        validateStatus={
                          errors.androidSearchBackgroundImageUrl
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="androidSearchBackgroundImageUrl"
                          value={data.androidSearchBackgroundImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              androidSearchBackgroundImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Title level={5} style={{ textAlign: 'center' }}>{languagesCms.PROFILE}</Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Profil Name Text Color"
                        help={errors.profilNameTextColor}
                        validateStatus={
                          errors.profilNameTextColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="profilNameTextColor"
                          value={data.profilNameTextColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              profilNameTextColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Profil Other Text Color"
                        help={errors.profilOtherTextColor}
                        validateStatus={
                          errors.profilOtherTextColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="profilOtherTextColor"
                          value={data.profilOtherTextColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              profilOtherTextColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Profil Place Holder Color"
                        help={errors.profilPlaceHolderColor}
                        validateStatus={
                          errors.profilPlaceHolderColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="profilPlaceHolderColor"
                          value={data.profilPlaceHolderColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              profilPlaceHolderColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="User Placeholder Image Url"
                        help={errors.userPlaceholderImageUrl}
                        validateStatus={
                          errors.userPlaceholderImageUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="userPlaceholderImageUrl"
                          value={data.userPlaceholderImageUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              userPlaceholderImageUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                </TabPane>

                <TabPane tab={languagesCms.TOOLBAR_SPECIAL} key="2">
                  <Title level={5} style={{ textAlign: 'center' }}>
                    ToolBar
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Cms Logo EN"
                        help={errors.cmsLogoEN}
                        validateStatus={
                          errors.toolbarLicmsLogoneColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="cmsLogoEN"
                          value={data.cmsLogoEN}
                          onChange={(e) =>
                            setData({ ...data, cmsLogoEN: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Cms Logo"
                        help={errors.cmsLogo}
                        validateStatus={
                          errors.toolbarLicmsLogoneColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="cmsLogo"
                          value={data.cmsLogo}
                          onChange={(e) =>
                            setData({ ...data, cmsLogo: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="IOS Toolbar Logo Url"
                        help={errors.iosToolbarLogoUrl}
                        validateStatus={
                          errors.iosToolbarLogoUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="iosToolbarLogoUrl"
                          value={data.iosToolbarLogoUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              iosToolbarLogoUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Android Toolbar Logo Url"
                        help={errors.androidToolbarLogoUrl}
                        validateStatus={
                          errors.androidToolbarLogoUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="androidToolbarLogoUrl"
                          value={data.androidToolbarLogoUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              androidToolbarLogoUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Toolbar Line Color"
                        help={errors.toolbarLineColor}
                        validateStatus={
                          errors.toolbarLineColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="toolbarLineColor"
                          value={data.toolbarLineColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              toolbarLineColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    {languagesCms.UPPER_MODULE}
                  </Title>
                  <br />

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Top-Right Module Visible"
                        help={errors.topRightModuleVisible}
                        validateStatus={
                          errors.topRightModuleVisible ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.topRightModuleVisible ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, topRightModuleVisible: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Top-Left Module Visible"
                        help={errors.topLeftModuleVisible}
                        validateStatus={
                          errors.topLeftModuleVisible ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.topLeftModuleVisible ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, topLeftModuleVisible: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Top-Right Share Button Visible"
                        help={errors.topRightShareButtonVisible}
                        validateStatus={
                          errors.topRightShareButtonVisible
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Switch
                          checked={
                            data.topRightShareButtonVisible ? true : false
                          }
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, topRightShareButtonVisible: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab={languagesCms.SPLASH_SPECIAL} key="3">
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="is Splash Image"
                        help={errors.isSplashImage}
                        validateStatus={
                          errors.isSplashImage ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.isSplashImage ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, isSplashImage: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Splash Title"
                        help={errors.splashTitle}
                        validateStatus={
                          errors.splashTitle ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashTitle"
                          value={data.splashTitle}
                          onChange={(e) =>
                            setData({ ...data, splashTitle: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Splash Title Color"
                        help={errors.splashTitleColor}
                        validateStatus={
                          errors.splashTitleColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashTitleColor"
                          value={data.splashTitleColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              splashTitleColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Splash Background Color"
                        help={errors.splashBackgroundColor}
                        validateStatus={
                          errors.splashBackgroundColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashBackgroundColor"
                          value={data.splashBackgroundColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              splashBackgroundColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Splash Icon Url"
                        help={errors.splashIcon}
                        validateStatus={errors.splashIcon ? 'error' : 'success'}
                      >
                        <Input
                          name="splashIcon"
                          value={data.splashIcon}
                          onChange={(e) =>
                            setData({ ...data, splashIcon: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Splash Morning Image Url"
                        help={errors.splashMorning}
                        validateStatus={
                          errors.splashMorning ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashMorning"
                          value={data.splashMorning}
                          onChange={(e) =>
                            setData({ ...data, splashMorning: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Splash Afternoon Image Url"
                        help={errors.splashAfternoon}
                        validateStatus={
                          errors.splashAfternoon ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashAfternoon"
                          value={data.splashAfternoon}
                          onChange={(e) =>
                            setData({
                              ...data,
                              splashAfternoon: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Splash Evening Image Url"
                        help={errors.splashEvening}
                        validateStatus={
                          errors.splashEvening ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashEvening"
                          value={data.splashEvening}
                          onChange={(e) =>
                            setData({ ...data, splashEvening: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Splash Night Image Url"
                        help={errors.splashNight}
                        validateStatus={
                          errors.splashNight ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="splashNight"
                          value={data.splashNight}
                          onChange={(e) =>
                            setData({ ...data, splashNight: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab={languagesCms.MODULE_SPECIAL} key="4">
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Shake Win
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Shake Win Background Color"
                        help={errors.shakeWinBackgroundColor}
                        validateStatus={
                          errors.shakeWinBackgroundColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="shakeWinBackgroundColor"
                          value={data.shakeWinBackgroundColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              shakeWinBackgroundColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Shake Win title Color"
                        help={errors.shakeWintitleColor}
                        validateStatus={
                          errors.shakeWintitleColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="shakeWintitleColor"
                          value={data.shakeWintitleColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              shakeWintitleColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Competitions
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Competition Background Color"
                        help={errors.competitionBackgroundColor}
                        validateStatus={
                          errors.competitionBackgroundColor
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          name="competitionBackgroundColor"
                          value={data.competitionBackgroundColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              competitionBackgroundColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Open Positions
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="CV Ekleme Buton"
                        help={errors.isOpenPositionCVButton}
                        validateStatus={
                          errors.isOpenPositionCVButton ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.isOpenPositionCVButton ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, isOpenPositionCVButton: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    SurveysWithMedia
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="SurveysWithMedia Bilgilendirme ve Onay Metni"
                        help={errors.surveysWithMediaInformations}
                        validateStatus={
                          errors.surveysWithMediaInformations
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Switch
                          checked={
                            data.surveysWithMediaInformations ? true : false
                          }
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({
                              ...data,
                              surveysWithMediaInformations: v,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="is SurveysWithMedia Warning Message"
                        help={errors.isSurveysWithMediaWarningMessage}
                        validateStatus={
                          errors.isSurveysWithMediaWarningMessage
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Switch
                          checked={
                            data.isSurveysWithMediaWarningMessage ? true : false
                          }
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({
                              ...data,
                              isSurveysWithMediaWarningMessage: v,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Hikaye
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Stories Text Color"
                        help={errors.storiesTextColor}
                        validateStatus={
                          errors.storiesTextColor ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="storiesTextColor"
                          value={data.storiesTextColor}
                          onChange={(e) =>
                            setData({
                              ...data,
                              storiesTextColor: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="is Story Frame"
                        help={errors.isStoryFrame}
                        validateStatus={
                          errors.isStoryFrame ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.isStoryFrame ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, isStoryFrame: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                      <Form.Item
                        label="Story Frame Url"
                        help={errors.storyFrameUrl}
                        validateStatus={
                          errors.storyFrameUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="storyFrameUrl"
                          value={data.storyFrameUrl}
                          onChange={(e) =>
                            setData({ ...data, storyFrameUrl: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Keys" key="5">
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Onesignal
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Onesignal App Id"
                        help={errors.onesignalApiId}
                        validateStatus={
                          errors.onesignalApiId ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="onesignalApiId"
                          value={data.onesignalApiId}
                          onChange={(e) =>
                            setData({ ...data, onesignalApiId: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Onesignal Rest Api Key"
                        help={errors.onesignalRestApikey}
                        validateStatus={
                          errors.onesignalRestApikey ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="onesignalRestApikey"
                          value={data.onesignalRestApikey}
                          onChange={(e) =>
                            setData({
                              ...data,
                              onesignalRestApikey: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Yandex Metrica
                  </Title>
                  <br />

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Yandex Metrica Key"
                        help={errors.yandexMetricaKey}
                        validateStatus={
                          errors.yandexMetricaKey ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="yandexMetricaKey"
                          value={data.yandexMetricaKey}
                          onChange={(e) =>
                            setData({
                              ...data,
                              yandexMetricaKey: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Title level={5} style={{ textAlign: 'center' }}>
                    Parse Keys
                  </Title>
                  <br />
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="parseServerActive"
                        help={errors.parseServerActive}
                        validateStatus={
                          errors.parseServerActive ? 'error' : 'success'
                        }
                      >
                        <Switch
                          checked={data.parseServerActive ? true : false}
                          checkedChildren={languagesCms.ACTIVE}
                          unCheckedChildren={languagesCms.PASSIVE}
                          onChange={(v) =>
                            setData({ ...data, parseServerActive: v })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Parse App Id"
                        help={errors.parseAppId}
                        validateStatus={errors.parseAppId ? 'error' : 'success'}
                      >
                        <Input
                          name="parseAppId"
                          value={data.parseAppId}
                          onChange={(e) =>
                            setData({ ...data, parseAppId: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Parse Master Key"
                        help={errors.parseMasterKey}
                        validateStatus={
                          errors.parseMasterKey ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="parseMasterKey"
                          value={data.parseMasterKey}
                          onChange={(e) =>
                            setData({ ...data, parseMasterKey: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row direction="row">
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Parse Server Url"
                        help={errors.parseServerURL}
                        validateStatus={
                          errors.parseServerURL ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="parseServerURL"
                          value={data.parseServerURL}
                          onChange={(e) =>
                            setData({ ...data, parseServerURL: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label="Parse Live Query Url"
                        help={errors.parseLiveQueryUrl}
                        validateStatus={
                          errors.parseLiveQueryUrl ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="parseLiveQueryUrl"
                          value={data.parseLiveQueryUrl}
                          onChange={(e) =>
                            setData({
                              ...data,
                              parseLiveQueryUrl: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Customization;
