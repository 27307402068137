import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Switch, Card, message,Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { GroupPicker, SendNotificationInput, QuestionsInput, LangPicker} from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';

import { useCallback } from "react";

const SurveyDetail = (props) => {

  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let [langCms] = useGlobal("langCms"); 
  let [langCode] = useGlobal("langCode"); 
  let newRecord = {
    lang: langCode ? langCode :  langCms[0].code ,
    name: '',
    groups:[]
  }

  let [data, setData] = useState(id ? {} : newRecord);
  let [answers, setAnswers] = useState();
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false)

  let [modules] = useGlobal("modules");
  let [languagesCms] = useGlobal("languagesCms"); 

  let path = props.location.pathname.split('/')[1];
  let module
  if(modules !== null){
     [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if(modules && id){
      setLoading(true)
        api.get("/rest/surveys/" + id).then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false)
        });

        
      api.get("/rest/survey/countAnswers/" + id).then(({ data: { result, result_message } }) => {
        setAnswers(result);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.lang == null)
      errors.lang = languagesCms.ENFORCED

    if (data.name == null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED

    if (data.questions == null)
      errors.questions = languagesCms.ENFORCED


    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true)
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {  
      if (id) {

        api.put("/rest/surveys/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/survey')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/surveys", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/survey')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    } 
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/survey">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}loading={loading}>
            <Form layout="horizontal" size={"large"} onFinish={save}>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.LANG}>
                    <LangPicker langCms={langCms} langCode={langCode} languagesCms={languagesCms} record={data} setRecord={setData} name="lang" />
                  </Form.Item>
                </Col>
              </Row>
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.GROUP} help={errors.groups} validateStatus={errors.active ? 'error' : 'success'}>
                    <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.TITLE} required help={errors.name} validateStatus={errors.name ? 'error' : 'success'}>
                    <Input name="name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                  </Form.Item>
                </Col>

              </Row>

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.NOTIFICATION} help={errors.sendNotification} validateStatus={errors.sendNotification ? 'error' : 'success'}>
                    <SendNotificationInput record={data} setRecord={setData} name="sendNotification" languagesCms={languagesCms}/>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={languagesCms.STATUS} help={errors.active} validateStatus={errors.active ? 'error' : 'success'}>
                    <Switch checked={data.active ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, active: v })} />
                  </Form.Item>
                </Col>
              </Row>


              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item label={languagesCms.QUESTIONS} required help={errors.questions} validateStatus={errors.questions ? 'error' : 'success'}>
                  {answers>0&&
                    <Alert type="info" message={languagesCms.SURVEY_MESSAGE} banner />
                  }
                    <QuestionsInput languagesCms={languagesCms} name='questions' record={data} setRecord={setData}  disabled={answers>0 ? true:false}  />
                  </Form.Item>
                </Col>
              </Row>

              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Card>
      </div>
    </div>
  );
};



export default SurveyDetail;
