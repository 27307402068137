import React, { useEffect, useState,useGlobal } from "reactn";

import { Row,Col,Form, Input, Button, Card, message ,Switch} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";


import api from '../../service/index'
import { Link } from 'react-router-dom';


const PostwallSettings = (props) => {
  let history = useHistory()
  let id = "postwallSettings"

  let [data, setData] = useState({});
  let [errors, ] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 
  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
 
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Postwall Settings</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
            <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
                <Form layout="horizontal" size={"large"} onFinish={save}>
                    <Row direction="row">
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                                <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row direction="row">
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label="is Company Show" help={errors.isCompanyShow} validateStatus={errors.isCompanyShow ? 'error' : 'success'}>
                                <Switch checked={data.isCompanyShow ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, isCompanyShow: v })} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label="is Position Show" help={errors.isPositionShow} validateStatus={errors.isPositionShow ? 'error' : 'success'}>
                                <Switch checked={data.isPositionShow ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, isPositionShow: v })} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Form.Item label="is Department Show" help={errors.isDepartmentShow} validateStatus={errors.isDepartmentShow ? 'error' : 'success'}>
                            <Switch checked={data.isDepartmentShow ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, isDepartmentShow: v })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row direction="row">
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item label="Postwall Share" help={errors.postwallShare} validateStatus={errors.postwallShare ? 'error' : 'success'}>
                                <Switch checked={data.postwallShare ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, postwallShare: v })} />
                            </Form.Item>
                            </Col>
                    </Row>
                    <Row direction="row">
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        }
      </div>
    </div>
  );
};



export default PostwallSettings;
