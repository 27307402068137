import React, { useEffect, useState,useGlobal } from "reactn";
import { Row,Col,Form, Input, Button, Card, message,Switch,InputNumber} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";

import api from '../../service/index'
import { Link } from 'react-router-dom';

const AppControls = (props) => {
  let history = useHistory()
  let id = "appControls"

  let [data, setData] = useState({});
  let [errors] = useState([]);
  let [loading, setLoading] = useState(true);
  let [languagesCms] = useGlobal("languagesCms"); 

  //onetime run
  useEffect(() => {
    get();
  }, []);

  let get = async () => {
    if(id) {
      await api.get("/rest/settings/" + id).then(({ data: { result, result_message } }) => {
        setData(result);   
        setLoading(false)
      });
    } else {
      setLoading(false)
    }
  }
  
  let save = async () => {
      if(id) {
        api.put("/rest/settings/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/settings')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/settings", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/settings')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }       
  };
  
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>App Controls</h1>
        </div>
        <div className="list-buttons">
          <Link to="/settings">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {!loading &&
          <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}>
            <Form layout="horizontal" size={"large"} onFinish={save}>


            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label="id"  help={errors._id} validateStatus={errors._id ? 'error' : 'success'}>
                  <Input name="_id" disabled={true} value={data._id} onChange={e => setData({ ...data, _id: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Post Check Admin" help={errors.postCheckAdmin} validateStatus={errors.postCheckAdmin ? 'error' : 'success'}>
                  <Switch checked={data.postCheckAdmin ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, postCheckAdmin: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Comment Check Admin" help={errors.commentCheckAdmin} validateStatus={errors.commentCheckAdmin ? 'error' : 'success'}>
                  <Switch checked={data.commentCheckAdmin ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, commentCheckAdmin: v })} />
                </Form.Item>
              </Col>
             
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Story Check Admin" help={errors.storyCheckAdmin} validateStatus={errors.storyCheckAdmin ? 'error' : 'success'}>
                  <Switch checked={data.storyCheckAdmin ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, storyCheckAdmin: v })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span:8 }}>
                <Form.Item label="user Can Post (Gönderi Paylaşabilme)" help={errors.userCanPost} validateStatus={errors.userCanPost ? 'error' : 'success'}>
                  <Switch checked={data.userCanPost ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, userCanPost: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span:8 }}>
                <Form.Item label="user Can Story(Hikaye Paylaşabilme)" help={errors.userCanStory} validateStatus={errors.userCanStory ? 'error' : 'success'}>
                  <Switch checked={data.userCanStory ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, userCanStory: v })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Story Brodcast Time" help={"Gün sayısı seçiniz."}>
                  <InputNumber name="limit" value={data.storyBrodcastTime} onChange={v => setData({ ...data, storyBrodcastTime: v })} />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Topics Check Admin" help={errors.topicsCheckAdmin} validateStatus={errors.topicsCheckAdmin ? 'error' : 'success'}>
                  <Switch checked={data.topicsCheckAdmin ? true : false} checkedChildren={languagesCms.ACTIVE} unCheckedChildren={languagesCms.PASSIVE} onChange={v => setData({ ...data, topicsCheckAdmin: v })} />
                </Form.Item>
              </Col>
              </Row>
            <Row direction="row">
              <Col span={24}>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
              </Form.Item>
              </Col>
            </Row>
           
            </Form>
          </Card>
        }
      </div>
    </div>
  );
};



export default AppControls;
