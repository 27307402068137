import React, { useState, useEffect, useRef,useGlobal } from 'react';
import { Input, Card, Form, Row, Col, Button, message, Switch, Modal, InputNumber, Select, Popconfirm } from 'antd';
import ImageMapper from 'react-image-mapper';
import api from './../service';
import qs from 'qs';
import Parse from 'parse';
import { DeleteOutlined } from "@ant-design/icons";

let Plan = Parse.Object.extend("Plan");
let PlanCoordinates = Parse.Object.extend("PlanCoordinates");

export default (props) => {
    let mapper = useRef()
    let { record, name, planId, areas, setAreas } = props;
    let [coords, setCoords] = useState(null);
    let [parseServerURL, setParseServerURL] = useState(null);
    let [languagesCms] = useGlobal("languagesCms"); 
    let [parseAppId, setParseAppId] = useState(null);
    let [map, setMap] = useState({
        name: "my-map",
        areas: areas
    })

    Parse.initialize(parseAppId)
    Parse.serverURL = parseServerURL

    //parse bilgilerinin çekilmesi
    useEffect(() => {
        api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
            if (data.result.rows) {
                data.result.rows.forEach(element => {
                    if (element._id === "customization") {
                        setParseServerURL(element.parseServerURL)
                        setParseAppId(element.parseAppId)
                    }
                });
            }
        })
    }, [])

    //areas her güncellendiğinde map bilgisinin de güncellenmesi
    useEffect(() => {
        setMap({
            name: "my-map",
            areas: areas
        })
    }, [areas])

    //yeni koordinat ekleme işlemi yapılacağında gerekli bilgilerin doldurulması ve pop up açma
    let clickedOutside = (evt) => {

        let x = (evt.nativeEvent.layerX * record.planImageUrl.width) / 1300
        let y = (evt.nativeEvent.layerY * record.planImageUrl.height) / 1300
        let coords = {
            x: x,
            y: y,
            sequence: 0,
            table: 0,
            chair: 0,
            name: "",
            planId: planId,
            isAvailable: true,
            type: "public"
        }
        setCoords(coords)
    };

    // koordinat düzenleme işlemi yapılacağında gerekli bilgilerin doldurulması ve pop up açma
    let clickedArea = (area) => {
        let query = new Parse.Query(PlanCoordinates);
        query.get(area.id).then(function (result) {
            setCoords({ ...result.attributes, id: result.id })
        }).catch(function (error) {
            setCoords({})
        });
    };

    //koordinat ekleme ve düzenleme işlemi
    let clickedOk = (coordinate) => {
        //koordinat düzenleme işlemi
        if (coordinate.id) {
            //koordinat güncelleme işlemi
            if (coordinate.name) {
                let query = new Parse.Query(PlanCoordinates);
                query.equalTo("objectId", coordinate.id);
                query.first().then((object) => {
                    object.save(coordinate).then(() => {
                        let data = object.attributes
                        //düzenlene koordinatın areasının düzenlenmesi  ve ekrandaki koordinat verilerinin güncellenmesi
                        let newData = areas.filter(area => area.id !== coordinate.id);
                        let [newArea] = areas.filter(area => area.id === coordinate.id);
                        if (data.isAvailable)
                            newArea.preFillColor = "green"
                        else
                            newArea.preFillColor = "red"
                        setAreas([...newData, newArea])
                        message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
                        setCoords(null)
                    }, (error) => {
                        message.error("Kayıt düzünlenemedi", 2);
                        setCoords(null)
                    });
                }, (error) => {
                    message.error("Kayıt bilgilerine ulaşılamadı.", 2);
                });
            }
            else
                message.error("'İsim' alanını doldurunuz.!", 2)

        }
        //koordinat ekleme işlemi
        else {
            //yeni koordinat ekleme işlemi
            if (coordinate.name) {
                let query = new Parse.Query(Plan);
                query.get(coordinate.planId).then((plan) => {
                    let myCoordinate = new PlanCoordinates();
                    coordinate.planId = plan;
                    myCoordinate.save(coordinate).then((newCoordinate) => {
                        let data = newCoordinate.attributes
                        let x = (data.x * 1300) / record.planImageUrl.width
                        let y = (data.y * 1300) / record.planImageUrl.height

                        //ekelenen koordinatın area şekline dönüştürülmesi ve ekrandaki koordinat verilerinin güncellenmesi
                        let newArea = { id: newCoordinate.id, name: data.name, shape: "circle", coords: [x, y, 10], preFillColor: "red", fillColor: "#0000ff" }
                        if (data.isAvailable) {
                            newArea = { id: newCoordinate.id, name: data.name, shape: "circle", coords: [x, y, 10], preFillColor: "green", fillColor: "#0000ff" }
                        }
                        setAreas([...areas, newArea])
                        mapper.current.props.map.areas = [...areas, newArea]
                        message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
                        setCoords(null)
                    }, (error) => {
                        message.error(error, 2);
                    });
                }, (error) => {
                    message.error("Kayıt bilgilerine ulaşılamadı.", 2);
                });
            }
            else
                message.error("'İsim' alanını doldurunuz.!", 2)

        }

    };

    //koordinat silme işlemi
    let deleteCoordinate = async (coordinate) => {
        let query = new Parse.Query(PlanCoordinates);
        query.equalTo("objectId", coordinate);
        let object = await query.first();
        object.destroy().then(() => {
            //silinen koordinatın ekrandan kaldırılması işlemi
            let newData = areas.filter(area => area.id !== coordinate);
            setAreas(newData)
            mapper.current.props.map.areas = newData   

            // // Bu alana ait rezervasyonlar bulunup siliniyor.
            // let queryReservation = new Parse.Query(Reservation);
            // queryReservation.equalTo("itemId", coordinate);
            // queryReservation.find().then((reservationObjects) => {
            //     reservationObjects.forEach(element => {
            //         element.destroy().then(() => {
            //             message.success("Rezervasyon silindi.", 2);
            //         }, (error) => {
            //             message.error("Rezervasyon silinemedi", 2);
            //         });
            //     });
            // }, (error) => {
            //     message.error("", 2);
            // });
                    
            message.success("Kayıt silindi", 2);
        }, (error) => {
            message.error(languagesCms.ERROR_DELETE, 2);
        });
        setCoords(null)
    };
    return <>
        {coords &&
            <div>
                {/*yeni koordinat ekelndiğinde ve koordinatlar üzerine tıklanınca açılacak pop up. Koordinat bilgileri içerir*/}
                <Modal
                    title={""}
                    visible={true}
                    onOk={() => clickedOk(coords)}
                    onCancel={() => setCoords(null)}>
                    <Card title={coords.id ? "Düzenle" : "Ekle"} loading={coords ? false : true} >
                        <Form>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="x"  >
                                        <Input name="x" disabled={true} value={coords.x} onChange={e => setCoords({ ...coords, x: e.target.value })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="y"  >
                                        <Input name="y" disabled={true} value={coords.y} onChange={e => setCoords({ ...coords, y: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                    <Form.Item label="isim" required >
                                        <Input name="name" value={coords.name} onChange={e => setCoords({ ...coords, name: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                    <Form.Item label={languagesCms.ORDER}>
                                        <InputNumber name="sequence" value={coords.sequence} defaultValue={0} onChange={v => setCoords({ ...coords, sequence: v })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                    <Form.Item label="Masa">
                                        <InputNumber name="table" value={coords.table} defaultValue={0} onChange={v => setCoords({ ...coords, table: v })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                                    <Form.Item label="Sandalye">
                                        <InputNumber name="chair" value={coords.chair} defaultValue={0} onChange={v => setCoords({ ...coords, chair: v })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row direction="row">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label={languagesCms.STATUS}>
                                        <Switch checked={coords.isAvailable ? true : false} checkedChildren="Kullanılabilir" unCheckedChildren="Kullanılamaz" onChange={v => setCoords({ ...coords, isAvailable: v })} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Form.Item label="Tip" >
                                        <Select defaultValue={coords.type} style={{ width: 250 }} onChange={v => { setCoords({ ...coords, type: v }) }}>
                                            <Select.Option value="public">Havuz Masa</Select.Option>
                                            <Select.Option value="admin">Admin Only</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {coords.id &&
                                <Popconfirm
                                    onConfirm={() => deleteCoordinate(coords.id)} title={languagesCms.CHECK_DELETE}
                                    okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                                    <Button type="danger" icon={<DeleteOutlined />}>
                                        Sil
                                    </Button>
                                </Popconfirm>
                            }
                        </Form>
                    </Card>
                </Modal>
            </div>
        }
        <div className="grid">
            <div className="presenter">
                <div style={{ position: "relative" }}>
                    {areas && record[name].url &&
                        <ImageMapper ref={mapper}
                            src={record[name].url || ""}
                            map={map}
                            width={1300}
                            height={1300}
                            onImageClick={clickedOutside}
                            onClick={area => clickedArea(area)}
                        />
                    }
                </div>
            </div>
        </div>
    </>
};

