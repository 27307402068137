import React, { useEffect, useState,useGlobal } from "reactn";
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import {GroupOutlined, HddOutlined, UnorderedListOutlined, SettingOutlined, UngroupOutlined,
  TranslationOutlined, RadiusSettingOutlined,ExclamationCircleOutlined} from '@ant-design/icons';
import api from '../../service/index'
// C:\www\ICILETISIM\iciletisim-cms-new\src\service\index.js
import qs from 'qs';

 const Module = (props) => {
     
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
   }

  const [dragAndDrop, ] = useState(initialDnDState);
  let [modules, setModules] = useState([]);

  let _params = {sort: "order", page: 1, pageSize: 100 };
  let [languagesCms] = useGlobal("languagesCms"); 
  let getResponse = async () => {

    await api.get(`/rest/settings?${qs.stringify(_params)}`).then(({data}) => {
      setModules(data.result.rows);
    });
  
}

    useEffect( () => {
      getResponse()
    }, []);
  
  React.useEffect( ()=>{
   
  }, [dragAndDrop])
  
  React.useEffect( ()=>{
   
  }, [modules])

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  
     return(
       <div>
      <div className="list-head">
      <div className="list-title">
         <h1>{languagesCms.SETTINGS}</h1> 
      </div>
    </div>

    <div className="table-wrap">
    <ul className="dragDrop">
        <li>
          <div >
            <GroupOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag">modules</span>
          </div>
          <div className="rightButtons">
            <Link to={"/modules"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <HddOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag">bottomBar</span>
          </div>
          <div className="rightButtons">
           <Link to={"/bottomBar"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <UnorderedListOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag">profilSettingsMenu</span>
          </div>
          <div className="rightButtons">
           <Link to={"/profilSettingsMenu"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <TranslationOutlined   style={{ fontSize: 20 }} />
            <span className="nameDrag">languages</span>
          </div>
          <div className="rightButtons">
           <Link to={"/languages"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <UngroupOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag">coordinateTypes</span>
          </div>
          <div className="rightButtons">
           <Link to={"/coordinateTypes"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <RadiusSettingOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag">ShakeWin Messages</span>
          </div>
          <div className="rightButtons">
           <Link to={"/shakeWinMessages"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        <li>
          <div >
            <ExclamationCircleOutlined  style={{ fontSize: 20 }} />
            <span className="nameDrag"> Result Messages</span>
          </div>
          <div className="rightButtons">
           <Link to={"/resultMessages"}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
          </div>
        </li>
        
     
     {modules.map( (item, index) => {
      return(
       <li>
        <div >
          <SettingOutlined style={{ fontSize: 20 }} />
          <span className="nameDrag">{item._id}</span>
        </div>
    
         <div className="rightButtons">
           <Link to={"/settings/"+item._id}><Button type="primary" size="medium" onClick={scrollTop} > {languagesCms.EDIT}</Button></Link>
         </div>
         
       </li>
      )
     })}
      
    </ul>
         </div>
         </div>
         )
 };
 
 export default Module;
