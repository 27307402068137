import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';
import { useEffect } from 'reactn';

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export default (props) => {
	let { record, setRecord, name, disabled, moduleChange } = props;
	let [items, setItems] = useState([]);
	let [value, setValue] = useState(record[name] ? { defaultValue: record[name] } : { value: record[name] });
	let _params = { sort: "-createdAt", page: 1, pageSize: 100, lang: "TR", appShow: "true" };
	let [loading, setLoading] = useState(false)
	let [moduleShowParameters, setModuleShowParameters] = useState([])
	let date = new Date();
	let languagesCms = props.languagesCms;
	let replaceModuleName = [
		{ name: "secondhand", realName: "secondhand", showParameters: ["name"], isGetData: true },
		{ name: "survey", realName: "surveys", showParameters: ["name"], isGetData: true },
		{ name: "education", realName: "educations", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "events", realName: "events", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "news", realName: "news", showParameters: ["name"], isGetData: true, params: { query: { $or: [{ endDate: date }, { notEnd: "true" }] } } },
		{ name: "workfamily", realName: "campaings", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "postwall", realName: "posts", showParameters: ["comment", "attendee_name"], isGetData: true },
		{ name: "health", realName: "bloodNeeds", showParameters: ["bloodName", "contactPerson"], isGetData: false },
		{ name: "topics", realName: "topics", showParameters: ["topic"], isGetData: true },
		{ name: "user", realName: "attendess", showParameters: ["name"], isGetData: false },
		{ name: "openPositions", realName: "openPositions", showParameters: ["name"], isGetData: true, params: { query: { joinEndDate: date } } },
		{ name: "activity", realName: "activity", showParameters: ["name"], isGetData: true },
		{ name: "competitions", realName: "competitions", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "photoAlbum", realName: "photoAlbumCategories", showParameters: ["langs"], isGetData: true },
		{ name: "surveysWithMedia", realName: "surveysWithMedia", showParameters: ["name"], isGetData: true },
		{ name: "ourNews", realName: "ourNews", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "contacts", realName: "contacts", showParameters: ["name", "lastname"], isGetData: true },
		{ name: "personalityInventory", realName: "personalityInventory", showParameters: ["name"], isGetData: true },
		{ name: "speakers", realName: "speakers", showParameters: ["name"], isGetData: true },
		{ name: "speakersOrder", realName: "speakersOrder", showParameters: ["name"], isGetData: true },
		{ name: "chat", realName: "chat", showParameters: ["name"], isGetData: false },
		{ name: "plan", realName: "plan", showParameters: ["name"], isGetData: false },
		{ name: "podcasts", realName: "podcasts", showParameters: ["title"], isGetData: true },
		{ name: "sessions", realName: "sessions", showParameters: ["name"], isGetData: true, params: { query: { endDate: date } } },
		{ name: "shakeWin", realName: "shakeWin", showParameters: ["name"], isGetData: false },
		{ name: "route", realName: "serviceRoutes", showParameters: ["name"], isGetData: true },
		{ name: "wordgames", realName: "wordgames", showParameters: ["name"], isGetData: false },
		{ name: "videoAlbum", realName: "videoAlbumCategories", showParameters: ["langs"], isGetData: true },
		{ name: "infos", realName: "informations", showParameters: ["header"], isGetData: true, params: { query: { $and:[{type: {$ne:"Multipager"}},{module: "infos"} ]}}},
		{ name: "infos2", realName: "informations", showParameters: ["header"], isGetData: true, params: { query: { $and:[{type: {$ne:"Multipager"}},{module: "infos2"} ]}} },
		{ name: "infos3", realName: "informations", showParameters: ["header"], isGetData: true, params: { query: { $and:[{type: {$ne:"Multipager"}},{module: "infos3"} ]}} },
		{ name: "infos4", realName: "informations", showParameters: ["header"], isGetData: true, params: { query: { $and:[{type: {$ne:"Multipager"}},{module: "infos4"} ]}} },
		{ name: "infos5", realName: "informations", showParameters: ["header"], isGetData: true, params:{ query: { $and:[{type: {$ne:"Multipager"}},{module: "infos5"} ]}} },
	]

	useMemo(() => {
		setValue(record[name] && !moduleChange ? { defaultValue: record[name] } : { value: record[name] })
	}, [record.moduleId, record[name]])

	useEffect(() => {
		if (moduleChange)
			setRecord({ ...record, [name]: null })
		let moduleDetail = replaceModuleName.find(f => f.name === record.moduleId)
		if (moduleDetail && moduleDetail.isGetData) {
			setModuleShowParameters(moduleDetail.showParameters)
			setLoading(true)
			if (moduleDetail.params)
				_params = { ..._params, ...moduleDetail.params }
			api.get(`/rest/${moduleDetail.realName}?${qs.stringify(_params)}`).then(({ data }) => {
                if(data.result){
                if(moduleDetail.realName==='informations'){
                    setItems( data.result.rows.filter(z=>z.type!=='Multipager'));
                }
                else{
				setItems(data.result.rows);                      
                }
                }
				setLoading(false)
			})
		}
		else setItems([])
	}, [record.moduleId])

	let findParameters = (b) => {
		if(moduleShowParameters[0]==="langs"){
			if(b[moduleShowParameters[0]]){
			let o = b[moduleShowParameters[0]].find(l=>l.lang==="TR")
			if(o){
				return o.title					
			}
			}

		}else {
			return b[moduleShowParameters[0]]
		}
	}

	return <Select showSearch loading={loading} disabled={disabled} {...value} placeholder={languagesCms.SELECT_SHARE} optionFilterProp="children"
		filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
		onChange={v => setRecord({ ...record, [name]: v })}>
		{items && items.map(b =>
			<Select.Option key={b._id} value={b._id} disabled={b.active ? !b.active : ""} >{findParameters(b)}</Select.Option>)}
	</Select>
};