import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined,
    LeftOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const Comment = (props) => {
    let params = useParams()
    let query = []
    let comment = params.id !== "add" ? params.id : false;
    query["postId"] = comment;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let [loading, setLoading] = useState(false)
    let [isSmall] = useGlobal('isSmall')

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
        }

        let _params = {sort: shortString, ...page, query};

        let restData = await api.get(`/rest/comments?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if(data && data.result){  
            setTotalCount(data.result.total);
            setLoading(false)
            return data.result.rows.map((item, key) => {
                if (item.active)
                    item.active = <CheckOutlined/>;
                else
                    item.active = <CloseOutlined/>;
                if (!item.hasOwnProperty('groups')) {
                    item.groups = []
                }
                item.key = key;
                return item;
            })
        }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/comments/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/comments/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }


    let columns = [
        {
            title: languagesCms.COMMENT,
            dataIndex: 'comment',
            key: 'comment',
            sorter: (a, b) => a.comment - b.comment,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.POST_OWNER,
            dataIndex: 'attendee_id',
            key: 'attendee_id',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend'],
            render: (user) => ( user && [user.name, user.lastname].filter(x => x).join(' ') ) || "Kullanıcı Bulunamadı" 
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                    <Link to={"/postwall/" + comment + "/comments/" + record._id}>
                        <Button icon={<EditOutlined/>}>{isSmall ? '' : languagesCms.EDIT}</Button>
                    </Link>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to="/postwall">
                        <Button type="light" icon={<LeftOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default Comment;
