import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm, message} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {ImportExcel} from './../components';
import ExportJsonExcel from 'js-export-excel';

import {
    EditOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    DownloadOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const Event = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [excelData, setExcelData] = useState([]);
    let [isSmall, ] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 
    let [langCms] = useGlobal("langCms");
    let [langCode] = useGlobal("langCode");
    let [modules] = useGlobal("modules");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = '-createdAt';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }

        let restData = await api.get(`/rest/events?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;

                    if (item.sendNotification)
                        item.sendNotification = <CheckOutlined/>;
                    else
                        item.sendNotification = <CloseOutlined/>;
                    if (!item.hasOwnProperty('groups')) {
                        item.groups = []
                    }
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/events/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/events/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let getReport = async (record) => {
        let lang=langCode ? langCode : langCms[0].code;
        let result = await api.get(`/rest/reports/event?id=${record._id}&lang=${lang}`)
        const file = new Blob(["\ufeff", result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${record.name.replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    let downloadExcel = async () => {
        var option = {};
        let dataTable = [];
        let excelData = await api.get(`/rest/events?${qs.stringify({page: 1, pageSize: 10000})}`, {}).then(({data}) => {
            return data.result.rows;
        });

        if (excelData) {
            for (let i in excelData) {
                if (excelData[i].joins) {
                    for (let j in excelData[i].joins) {
                        let attendee = await api.get(`/rest/attendees/${excelData[i].joins[j].id}?${qs.stringify({
                            page: 1,
                            pageSize: 10000
                        })}`, {}).then(({data}) => {
                            return data.result;
                        });

                        let obj = {

                            'attendee': attendee ? attendee.name + " " + attendee.lastname : "Kullanıcı Bulunamadı",
                            'name': excelData[i].name,
                            'speakers_names': excelData[i].speakers_names,
                            'limit': excelData[i].limit,
                            'startDate': moment(excelData[i].startDate).format("DD-MM-YYYY"),
                            'endDate': moment(excelData[i].endDate).format("DD-MM-YYYY"),
                            'releaseDate': moment(excelData[i].releaseDate).format("DD-MM-YYYY"),
                            'joinEndDate': moment(excelData[i].joinEndDate).format("DD-MM-YYYY"),
                        }
                        dataTable.push(obj);
                    }
                }
            }
        }

        let sheetAreas = ['attendee', 'name', 'speakers_names', 'limit', 'startDate', 'endDate', 'releaseDate', 'joinEndDate'];


        option.fileName = module ? module.name : "";
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',

                sheetFilter: sheetAreas,
                sheetHeader: [languagesCms.USERS, languagesCms.EVENT_NAME, languagesCms.SPEAKER_NAME, languagesCms.QUOTA, languagesCms.START_DATE, languagesCms.END_DATE, languagesCms.RELEASE_DATE, languagesCms.PARTICIPATION_DATE],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }


    let uploadExcel = async () => {
        //let eventId;
        let eventData;
        let eventGroups = [];
        let events = [];

        let updateCount = 0;
        let insertCount = 0;

        if (excelData) {
            for (let i in excelData) {
                //eventId = excelData[i].id;
                eventGroups = [];
                if (excelData[i].group_1) {
                    eventGroups.push(excelData[i].group_1.toString().trim())
                }
                if (excelData[i].group_2) {
                    eventGroups.push(excelData[i].group_2.toString().trim())
                }
                if (excelData[i].group_3) {
                    eventGroups.push(excelData[i].group_3.toString().trim())
                }
                if (excelData[i].group_4) {
                    eventGroups.push(excelData[i].group_4.toString().trim())
                }
                if (excelData[i].group_5) {
                    eventGroups.push(excelData[i].group_5.toString().trim())
                }
                if (excelData[i].group_6) {
                    eventGroups.push(excelData[i].group_6.toString().trim())
                }
                if (excelData[i].group_7) {
                    eventGroups.push(excelData[i].group_7.toString().trim())
                }
                if (excelData[i].group_8) {
                    eventGroups.push(excelData[i].group_8.toString().trim())
                }
                if (excelData[i].group_9) {
                    eventGroups.push(excelData[i].group_9.toString().trim())
                }
                if (excelData[i].group_10) {
                    eventGroups.push(excelData[i].group_10.toString().trim())
                }
                if (excelData[i].group_11) {
                    eventGroups.push(excelData[i].group_11.toString().trim())
                }
                if (excelData[i].group_12) {
                    eventGroups.push(excelData[i].group_12.toString().trim())
                }
                if (excelData[i].group_13) {
                    eventGroups.push(excelData[i].group_13.toString().trim())
                }
                if (excelData[i].group_14) {
                    eventGroups.push(excelData[i].group_14.toString().trim())
                }
                if (excelData[i].group_15) {
                    eventGroups.push(excelData[i].group_15.toString().trim())
                }
                if (excelData[i].group_16) {
                    eventGroups.push(excelData[i].group_16.toString().trim())
                }
                if (excelData[i].group_17) {
                    eventGroups.push(excelData[i].group_17.toString().trim())
                }
                if (excelData[i].group_18) {
                    eventGroups.push(excelData[i].group_18.toString().trim())
                }
                if (excelData[i].group_19) {
                    eventGroups.push(excelData[i].group_19.toString().trim())
                }
                if (excelData[i].group_20) {
                    eventGroups.push(excelData[i].group_20.toString().trim())
                }

                eventData = {
                    "name": excelData[i].name,
                    "lang": excelData[i].lang || 'TR',
                    "description": excelData[i].description || '',
                    "limit": excelData[i].limit || null,
                    "active": excelData[i].active || true,
                    "showTime": excelData[i].showTime || true,
                    "releaseDate": excelData[i].releaseDate ? moment(excelData[i].releaseDate).format("DD-MM-YYYY HH:mm") : '',
                    "joinEndDate": excelData[i].joinEndDate ? moment(excelData[i].joinEndDate).format("DD-MM-YYYY HH:mm") : '',
                    "startDate": excelData[i].startDate ? moment(excelData[i].startDate).format("DD-MM-YYYY HH:mm") : '',
                    "endDate": excelData[i].endDate ? moment(excelData[i].endDate).format("DD-MM-YYYY HH:mm") : '',
                    "sendNotification": false,
                    "groups": eventGroups,
                }
                events.push(eventData)

                // if (eventId) {
                //   // update
                ///  eventData["sendNotification"] = false;
                //   await api.put("/rest/events/" + eventId, eventData).then(({ data: { result, result_message } }) => {
                //   })
                //   updateCount++;
                // } else {
                //   // insert
                //   eventData["sendNotification"] = false;
                //   await api.post("/rest/events", eventData).then(({ data: { result, result_message } }) => {
                //   })
                //   insertCount++;
                // }

            }
            if (updateCount || insertCount) {
                message.error("Excel Yüklendi. " + updateCount + " Güncellendi, " + insertCount + " Kayıt Eklendi.", 2);
            }

            if (events.length > 0) {
                // update
                await api.post("/rest/events/insertMany", {events: events}).then(({data: {result, result_message}}) => {
                    message.error("Excel Yüklendi. ", 2);

                })
            }

        }
    }

    useEffect(() => {
        uploadExcel();
    }, [excelData]);


    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        },
        {
      title: languagesCms.GROUP,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (group) => group.length > 0 && group.map(e => <div style={{marginBottom: 5}}>{e.name}</div>)
        },
        {
            title:languagesCms.LANG,
            dataIndex: 'lang',
            key: 'lang',
            sorter: (a, b) => a.lang - b.lang,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.NOTIFICATION,
            dataIndex: 'sendNotification',
            key: 'sendNotification',
            sorter: (a, b) => a.sendNotification - b.sendNotification,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.START_DATE,
            dataIndex: 'startDate',
            key: 'startDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.startDate - b.startDate,
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
        },
        {
            title:languagesCms.END_DATE,
            dataIndex: 'endDate',
            key: 'endDate',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.endDate - b.endDate,
            render: (text) => moment(text).format("YYYY-MM-DD  HH:mm"),
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Button type="primary" icon={<FileExcelOutlined/>} onClick={() => getReport(record)}>
                        {isSmall ? '' : languagesCms.REPORT}
                    </Button>
                    <Link to={"/events/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">

                    <Button size="large" onClick={downloadExcel} style={{marginRight: "5px"}}
                            icon={<DownloadOutlined/>}>{isSmall ? '' : 'Export Excel'}</Button>
                    {!isSmall && (
                        <ImportExcel setExcelData={setExcelData}/>
                    )}
                    {!isSmall && (

                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>)}

                    <Link to="/events/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_EVENT} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};

export default Event;
